<template>
  <div class="property animation" style="animation-delay: 1s">
    <!-- intro -->
    <v-container fluid>
      <v-row>
        <v-col class="pa-0 intro position-relative">
          <v-img
            :src="
              'https://content.marisolmalibu.com' +
              zero1Singleton.img_intro.path
            "
            width="100%"
            height="100%"
            class="intro__asset"
            eager
          ></v-img>
          <!-- <video
            style="object-fit: cover"
            class="intro__asset"
            loop
            playsinline
            width="100%"
            height="100%"
            :autoplay="autoplay"
          >
            <source src="@/assets/img/videoplayback.mp4" />
          </video> -->
          <v-row no-gutters justify="center" class="flex-column fill-height">
            <v-col cols="auto" class="animation" style="animation-delay: 2s">
              <div class="logo-header" v-if="$vuetify.breakpoint.smAndUp"></div>
              <router-link to="/" v-else>
                <v-img
                  alt="Marisol Logo"
                  class="shrink logo-header mx-auto"
                  contain
                  src="@/assets/icons/MariSolMalibu_logo.svg"
                  transition="scale-transition"
                />
              </router-link>
              <!-- btn para el menú mobile -->
              <div class="text-center mt-6 d-sm-none">
                <v-btn
                  @click="$emit('open')"
                  :ripple="false"
                  text
                  class="letter-spacing-0 not-text-transform"
                >
                  <span class="continuos-texts white--text">Menu</span>
                </v-btn>
              </div>
              <!-- /btn para el menú mobile -->
            </v-col>
            <v-col
              cols="auto"
              class="position-relative mt-auto in-property-margin"
            >
              <v-row no-gutters justify="center" align="center">
                <v-col cols="auto" class="text-center line-height-title-intro">
                  <div class="max-width-title-block">
                    <span
                      class="white--text titles-medium d-block sabon-regular animation"
                      style="animation-delay: 3s"
                      v-html="zero1Singleton.title_intro"
                    ></span>
                  </div>

                  <span
                    class="white--text continuos-texts founders-grotesk-bold animation uppercase"
                    style="animation-delay: 3s"
                    >{{ zero1Singleton.property_number_intro }}</span
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="auto"
              class="mt-auto position-relative animation"
              style="animation-delay: 2s"
            >
              <v-row no-gutters justify="center" align="end">
                <v-col cols="auto" class="text-center">
                  <span
                    class="white--text continuos-texts founders-grotesk animation d-block mb-2"
                    style="animation-delay: 3s"
                    >11865 Ellice</span
                  >
                  <v-img
                    src="@/assets/icons/MALIBU_logo.svg"
                    class="intro__logo-2 mx-auto"
                  ></v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- /intro -->

    <!-- segundo bloque -->
    <v-container fluid class="block-second pb-0">
      <v-row no-gutters class="block__second animation-each">
        <v-col>
          <v-row no-gutters class="fill-height" justify="center" align="center">
            <v-col cols="auto" class="text-center">
              <div class="max-width-title-block">
                <span
                  class="sabon-regular block__second__title-second-paragraph"
                  v-html="zero1Singleton.title_malibu_made"
                ></span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- /segundo bloque -->

    <v-container fluid class="block__third animation-each">
      <v-row justify="center">
        <v-col cols="12" class="px-0" id="carouselBig">
          <template v-if="render">
            <div id="fullSizeCarouselDesk" style="position: relative;" v-if="$vuetify.breakpoint.smAndUp">
            <carousel
              :items="1"
              :autoplay="true"
              :autoplayHoverPause="true"
              :nav="true"
              :dots="false"
              :animateIn="'fadeIn'"
              :animateOut="'fadeOut'"
              :slideTransition="'linear'"
              :loop="true"
            >
              <template
                v-for="(item, img) in zero1Singleton.carousel_full_size_desk"
              >
                <template v-if="item.path != null">
                  <img
                    :key="img"
                    :src="
                      'https://content.marisolmalibu.com' +
                      item.path
                    "
                    alt=""
                  />
                </template>
              </template>
              <!-- <template slot="next">
                <div class="div-for-arrow cursor-pointer d-none d-sm-block" @mouseover="caruselFullSizeAutoplay = false" @mouseout="caruselFullSizeAutoplay = true">
                  <v-btn icon class="arrow--right">
                    <div class="pa-3" style="background-color: rgba(255,255,255,.4);">  
                      <v-img
                        src="@/assets/icons/Flecha-izquierda.svg"
                        sizes="36"
                        width="36"
                      ></v-img>
                    </div>
                  </v-btn>
                </div>
              </template>
              <template slot="prev">
                <div class="div-for-arrow-2">
                  <v-btn icon class="arrow--left">
                    <div class="pa-3" style="background-color: rgba(255,255,255,.4);">  
                      <v-img
                        src="@/assets/icons/Flecha-derecha.svg"
                        sizes="36"
                        width="36"
                      ></v-img>
                    </div>
                  </v-btn>

                </div>
              </template> -->
            </carousel>
            </div>


            <carousel
              :items="1"
              :autoplay="true"
              :nav="false"
              :dots="false"
              :animateIn="'fadeIn'"
              :animateOut="'fadeOut'"
              :slideTransition="'linear'"
              :loop="true"
              v-else
            >
              <template
                v-for="(item, img) in zero1Singleton.carousel_full_size"
              >
                <template v-if="item.value != null">
                  <img
                    :key="img"
                    :src="
                      'https://content.marisolmalibu.com' +
                      item.value.path
                    "
                    style="object-fit: contain"
                    alt=""
                  />
                </template>
              </template>
              <template slot="next">
                <div class="div-for-arrow cursor-pointer d-none d-sm-block">
                  <v-img
                    src="@/assets/icons/Flecha-izquierda.svg"
                    class="arrow--right"
                  ></v-img>
                </div>
              </template>
              <template slot="prev">
                <div class="div-for-arrow-2">
                  <v-img
                    src="@/assets/icons/Flecha-derecha.svg"
                    class="arrow--left"
                  ></v-img>

                </div>
              </template>
            </carousel>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="animation-each" v-if="render">
      <v-row>
        <v-col class="pa-0 position-relative" id="largeCarousel">
          <!-- carousel tablet v to up -->
          <div v-if="$vuetify.breakpoint.smAndUp">
            <carousel :items="1" :nav="false" class="carousel-lifestyle">
              <v-card elevation="0">
                <v-row no-gutters align="center" class="first-image-div">
                  <v-col cols="auto">
                    <!-- <v-img
                      :src="'https://content.marisolmalibu.com'+ zero1Singleton.lifestyle_carousel_image_1.path" 
                      srca="@/assets/img/04-large-carousel/LargeCarousel_1-web.jpg"
                      class="first-image"
                      contain
                      eager
                    ></v-img> -->
                    <img
                      :src="'https://content.marisolmalibu.com'+ zero1Singleton.lifestyle_carousel_image_1.path" 
                      srca="@/assets/img/04-large-carousel/LargeCarousel_1-web.jpg"
                      class="first-image"
                      contain
                      eager
                    >
                  </v-col>
                  <v-col cols="auto" class="align-self-center move-1">
                    <!-- <v-img
                      src="@/assets/img/04-large-carousel/DesignedWithIntent.png"
                      class="first-image-text"
                      contain
                      eager
                    ></v-img> -->
                    <img
                      src="@/assets/img/04-large-carousel/DesignedWithIntent.png"
                      class="first-image-text"
                      contain
                      eager
                    >
                  </v-col>
                  <v-col class="text-right pr-12" align-self="start">
                    <!-- <v-img
                      :src="'https://content.marisolmalibu.com'+ zero1Singleton.lifestyle_carousel_image_1_1.path" 
                      srca="@/assets/img/04-large-carousel/LargeCarousel_1-web.jpg"
                      class="first-image ml-auto"
                      contain
                      eager
                    ></v-img> -->
                    <img
                      :src="'https://content.marisolmalibu.com'+ zero1Singleton.lifestyle_carousel_image_2.path" 
                      
                      srca="@/assets/img/04-large-carousel/LargeCarousel_1-web.jpg"
                      class="first-image ml-auto"
                      contain
                      eager
                    >
                  </v-col>
                </v-row>
              </v-card>

              <v-card elevation="0">
                <v-row class="fill-height" no-gutters>
                  <v-col cols="auto" class="align-self-end">
                    <!-- <v-img
                      src="@/assets/img/04-large-carousel/LetTheOutsideIn.png"
                      class="second-image-text ml-auto"
                      contain
                      eager
                    ></v-img>
                    <v-img
                      :src="'https://content.marisolmalibu.com'+ zero1Singleton.lifestyle_carousel_image_2.path" 
                      srca="@/assets/img/04-large-carousel/LargeCarousel_2-web.jpg"
                      class="second-image"
                      eager
                    ></v-img> -->
                    <img
                      src="@/assets/img/04-large-carousel/LetTheOutsideIn.png"
                      class="second-image-text ml-auto"
                      contain
                      eager
                    >
                    <img
                      :src="'https://content.marisolmalibu.com'+ zero1Singleton.lifestyle_carousel_image_1_1.path" 
                      srca="@/assets/img/04-large-carousel/LargeCarousel_2-web.jpg"
                      class="second-image"
                      eager
                    >
                  </v-col>
                  <v-col cols="auto" class="column-second-image">
                    <!-- <v-img
                      :src="'https://content.marisolmalibu.com'+ zero1Singleton.lifestyle_carousel_image_3.path" 
                      srca="@/assets/img/04-large-carousel/LargeCarousel_3-web.jpg"
                      class="second-image"
                      eager
                    ></v-img> -->
                    <img
                      :src="'https://content.marisolmalibu.com'+ zero1Singleton.lifestyle_carousel_image_3.path" 
                      srca="@/assets/img/04-large-carousel/LargeCarousel_3-web.jpg"
                      class="second-image"
                      eager
                    >
                  </v-col>
                </v-row>
              </v-card>

              <v-card elevation="0">
                <v-row align="center" class="fill-height">
                  <v-col cols="auto">
                    <!-- <v-img
                      :src="'https://content.marisolmalibu.com'+ zero1Singleton.lifestyle_carousel_image_4.path" 
                      srca="@/assets/img/04-large-carousel/zero1-cover.jpg"
                      class="fourth-image"
                    ></v-img>
                    <v-img
                      src="@/assets/img/04-large-carousel/BreatheDeep.png"
                      class="second-image-text ml-auto"
                      contain
                    ></v-img> -->
                    <img
                      :src="'https://content.marisolmalibu.com'+ zero1Singleton.lifestyle_carousel_image_4.path" 
                      srca="@/assets/img/04-large-carousel/zero1-cover.jpg"
                      class="fourth-image"
                    >
                    <img
                      src="@/assets/img/04-large-carousel/BreatheDeep.png"
                      class="second-image-text ml-auto"
                      contain
                    >
                  </v-col>
                </v-row>
              </v-card>

              <template slot="next">
                <div class="div-for-arrow cursor-pointer d-none d-sm-block">
                  <v-img
                    src="@/assets/icons/Flecha-izquierda.svg"
                    class="arrow--right"
                  ></v-img>
                </div>
              </template>
              <template slot="prev">
                <div class="div-for-arrow-2">
                  <v-img
                    src="@/assets/icons/Flecha-derecha.svg"
                    class="arrow--left"
                  ></v-img>

                </div>
              </template>
            </carousel>
          </div>
          <!-- /carousel tablet v to up -->

          <!-- carousel mobile-->
          <div v-if="$vuetify.breakpoint.xs">
            <carousel :items="1" :loop="true" :nav="false">
              <v-card elevation="0">
                <v-row no-gutters class="first-image-div">
                  <v-col cols="12" sm="auto" order="1" order-sm="0">
                    <img
                      :src="'https://content.marisolmalibu.com'+ zero1Singleton.lifestyle_carousel_image_1.path" 
                      srca="@/assets/img/04-large-carousel/LargeCarousel_1-web.jpg"
                      class="first-image"
                    />
                  </v-col>
                  <v-col cols="12" sm="auto" class="align-self-end">
                    <img
                      src="@/assets/img/04-large-carousel/DesignedWithIntent.png"
                      class="first-image-text"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <v-card elevation="0">
                <v-row class="fill-height first-image-div" no-gutters>
                  <v-col cols="12" sm="auto" class="align-self-end">
                    <img
                      src="@/assets/img/04-large-carousel/LetTheOutsideIn.png"
                      class="first-image-text ml-auto"
                    />
                  </v-col>
                  <v-col cols="12" sm="auto">
                    <img
                      :src="'https://content.marisolmalibu.com'+ zero1Singleton.lifestyle_carousel_image_2.path" 
                      srca="@/assets/img/04-large-carousel/LargeCarousel_2-web.jpg"
                      class="second-image"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <v-card elevation="0">
                <v-row class="fill-height first-image-div" no-gutters>
                  <v-col cols="12" sm="auto" class="align-self-end opacity-0">
                    <img
                      src="@/assets/img/04-large-carousel/LetTheOutsideIn.png"
                      class="first-image-text ml-auto"
                    />
                  </v-col>
                  <v-col cols="12" sm="auto" class="column-second-image">
                    <img
                      :src="'https://content.marisolmalibu.com'+ zero1Singleton.lifestyle_carousel_image_3.path" 
                      srca="@/assets/img/04-large-carousel/LargeCarousel_3-web.jpg"
                      class="second-image"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <v-card elevation="0">
                <v-row align="center" class="fill-height">
                  <v-col cols="auto">
                    <img
                      :src="'https://content.marisolmalibu.com'+ zero1Singleton.lifestyle_carousel_image_4.path" 
                      srca="@/assets/img/04-large-carousel/zero1-cover.jpg"
                      class="first-image"
                    />
                    <img
                      src="@/assets/img/04-large-carousel/BreatheDeep.png"
                      class="first-image-text ml-auto"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <template slot="next">
                <div class="div-for-arrow cursor-pointer">
                  <v-img
                    src="@/assets/icons/Flecha-izquierda.svg"
                    class="arrow--right"
                  ></v-img>
                </div>
              </template>
            </carousel>
          </div>
          <!-- /carousel mobile -->
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="pb-0 pb-sm-3">

      <!-- bloque responsibly built -->
      <v-row class="block__responsibly-built in-zero-one animation-each" justify="center">
        <v-col cols="12" class="px-0">
          <v-row no-gutters class="fill-height" align="center">
            <v-col cols="auto">
              <div class="text-center animation-each space-title-in-zero-one">
                <span
                  class="titles-medium rewrite-in-hd sabon-regular line-height-title-2"
                  v-html="homeSingleton.title_responsibly"
                  >
                </span>
              </div>
              <div class="position-relative">
                <!-- <span
                  class="title-house-drawing continuos-texts founders-grotesk-bold uppercase"
                  >Zero 1</span>

                <span
                  class="title-house-drawing continuos-texts founders-grotesk" style="transform: translateY(100%) translateX(-50%);"
                  >11865 Ellice</span> -->

                <!-- SVG desk -->
                <template v-if="$vuetify.breakpoint.smAndUp">
                  <svgMap></svgMap>
                </template>
                <!-- /SVG desk -->

                <!-- SVG mobile -->
                <template v-if="$vuetify.breakpoint.xs">
                  <svgMapBuiltMobile></svgMapBuiltMobile>
                </template>
                <!-- /SVG mobile -->
              </div>
              <!-- <div class="text-center">
                <router-link to="/zero1" class="not-text-decoration btn-black">
                  <span class="white--text continuos-texts"
                    >Discover Paradise</span
                  >
                </router-link>
              </div> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /bloque responsibly built -->
    </v-container>

    <v-container fluid class="mt-0 mt-sm-12 pb-0">
      <!-- Bloque A -->

      <v-row class="row-custom-2 animation-each-noo" id="numbersLeft">
        <v-col
          cols="12"
          sm="6"
          class="row-custom-2__column-1"
          :class="{ 'padding-bottom-set': showGraphics }"
        >
          <!-- numbers -->
          <template v-if="!(showGraphics || showGraphics2)">
            <v-row
              no-gutters
              class="fill-height interiors"
              align="end"
              v-if="showGraphics == false"
            >
              <v-col cols="12">
                <v-row no-gutters>
                  <!-- item 1 -->
                  <v-col
                    cols="6"
                    class="row-custom-2__column-1--line-height-custom"
                  >
                    <span class="d-block subtitles-big founders-grotesk-light">
                      <template v-if="numero < 248">
                        {{ numero / 100 }}
                      </template>
                      <template v-else> 2.48 </template>
                    </span>
                    <span class="continuos-texts"
                      >acres</span
                    >
                  </v-col>
                  <!-- /item 1 -->

                  <!-- item 2 -->
                  <v-col
                    cols="6"
                    class="row-custom-2__column-1--line-height-custom"
                  >
                    <span class="d-block subtitles-big founders-grotesk-light">
                      <template v-if="numero1 < 14429">
                        {{ commasNumber(numero1) }}
                      </template>
                      <template v-else> 14,429 </template>
                    </span>
                    <span class="continuos-texts">square feet </span>
                  </v-col>
                  <!-- /item 2 -->

                  <!-- item 3 -->
                  <v-col
                    cols="6"
                    class="margin-custom-only row-custom-2__column-1--line-height-custom"
                  >
                    <span class="d-block subtitles-big founders-grotesk-light">
                      <template v-if="numero2 < 317">
                        {{ numero2 }}
                      </template>
                      <template v-else> 317 </template>
                    </span>
                    <span class="continuos-texts">mature trees </span>
                  </v-col>
                  <!-- /item 3 -->

                  <!-- item 4 -->
                  <v-col
                    cols="6"
                    class="margin-custom-only row-custom-2__column-1--line-height-custom"
                  >
                    <span class="d-block subtitles-big founders-grotesk-light">
                      <template v-if="numero3 < 12">
                        {{ parseInt(numero3) }}
                      </template>
                      <template v-else> 12 </template>
                    </span>
                    <span class="continuos-texts"
                      >months of gardening season
                    </span>
                  </v-col>
                  <!-- /item 4 -->

                  <!-- item 5 -->
                  <v-col
                    sm="6"
                    class="row-custom-2__column-1--line-height-custom"
                  >
                    <span class="d-block subtitles-big founders-grotesk-light">
                      -
                      <template v-if="numero5 < 140">
                        {{ parseInt(numero5) }}
                      </template>
                      <template v-else> 140 </template>
                    </span>
                    <span class="continuos-texts d-block">tons of embodied carbon</span>

                    <span
                      @click="
                        (showGraphics = true),
                          (final = false),
                          executeAnimationGraphics()
                      "
                      class="cursor-pointer"
                    >
                      <span class="continuos-texts underline-custom"
                        >Learn more</span
                      >
                      <span class="continuos-texts d-inline-block ml-1 ml-sm-4"
                        >></span
                      >
                    </span>
                  </v-col>
                  <!-- /item 5 -->

                  <!-- item 6 -->
                  <v-col
                    sm="6"
                    class="row-custom-2__column-1--line-height-custom"
                  >
                    <span class="d-block subtitles-big founders-grotesk-light"
                      >0</span
                    >
                    <span class="continuos-texts d-block"
                      >tons of operating carbon</span
                    >

                    <span
                      @click="
                        (showGraphics2 = true),
                          (final = false),
                          executeAnimationGraphics()
                      "
                      class="cursor-pointer"
                    >
                      <span class="continuos-texts underline-custom"
                        >Learn more</span
                      >
                      <span class="continuos-texts d-inline-block ml-1 ml-sm-4"
                        >></span
                      >
                    </span>
                  </v-col>
                  <!-- /item 6 -->

                  <v-col cols="12" class="margin-custom-only mb-0">
                    <span class="d-block subtitles-big founders-grotesk-light">
                      {{ zero1Singleton.property_price }}
                    </span>

                    <router-link class="cursor-pointer black--text not-text-decoration"  >
                    </router-link>
                    <v-btn text class="px-0" href="https://www.mansionglobal.com/articles/zero-carbon-modern-ranch-in-malibu-california-sold-for-23-million-01651613262" target="_blank">

                      <span class="continuos-texts underline-custom" style="text-transform: none; letter-spacing: 0px;"
                        >{{zero1Singleton.zilllow_button_text}}</span
                      > 

                      <v-icon color="black" size="18" class=" ml-1">mdi-open-in-new</v-icon>
                      <!-- <span class="continuos-texts d-inline-block ml-1 ml-sm-4"
                        >></span> -->
                    </v-btn>
                    
                  </v-col>

                  <!-- <v-col cols="12">
                    <v-btn
                      class="letter-spacing-0 not-text-transform ml-0 btn-25"
                      color="black"
                      elevation="0"
                      tile
                      to="/contact"
                      aahref="https://www.zillow.com/homedetails/11865-Ellice-St-Malibu-CA-90265/121442315_zpid/"
                      
                      :ripple="false"
                      ><span class="white--text continuos-texts"
                        >Book a showing</span
                      >
                      </v-btn>
                  </v-col> -->
                </v-row>
              </v-col>
            </v-row>
          </template>
          <!-- /numbers -->

          <!-- graphics 1-->
          <v-row no-gutters class="fill-height" v-if="showGraphics">
            <v-col cols="12">
              <v-row no-gutters>
                <template v-if="showFirstChart">
                  <v-col
                    cols="12"
                    class="row-custom-2__column-1--line-height-custom"
                  >
                    <v-row no-gutters align="center">
                      <v-col>
                        <span class="d-block subtitles-big">Reduction</span>
                      </v-col>
                      <v-col cols="auto" class="text-right">
                        <v-btn icon @click="showGraphics = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        
                        
                      </v-col>
                    </v-row>
                    <span
                      id="textoooo"
                      class="heigth-text"
                      style="position: relative"
                    >
                      <div
                        class="tooltop-custom-container"
                        v-bind:class="{ notOver: !overInfo }"
                      >
                        <transition name="scale-transition">
                          <div
                            v-if="overInfo"
                            class="text-tooltip-main black--text pa-3  pa-md-3 pa-lg-6"
                          >
                            <p>
                              <b>Baseline home:</b> The embodied carbon of a home not built with low carbon materials. 
                            </p>

                            <p class="mb-0 mb-md-3">
                              <b>Zero 1:</b> Shows the effect of swapping in low carbon materials for conventional materials. For example, the use of recycled cement caused a reduction.
                            </p>
                          </div>
                        </transition>
                      </div>
                      <span class="continuos-texts d-block">
                        <!-- To build a zero-carbon home, first reduce and replace
                        carbon-intensive materials with low-carbon alternates,
                        then use sustainably sourced, biobased materiales
                        wherever possible to sequester more carbon than is
                        emitted. -->
                        <!-- To build a zero-carbon home, that first step is to
                        maximize energy efficiency, and the second step is to
                        use renewables for the remaining energy load. -->
                        To reach net zero embodied carbon, the first step is to choose low carbon materials to reduce embodied carbon, and the second step is to use biogenic materials that sequestered CO2 throughout their lifetime.
                      </span>
                      <v-row no-gutters>
                        <v-col>
                          <span class="d-block mt-2">
                            <span
                              style="width: fit-content"
                              class="continuos-texts underline-custom cursor-pointer"
                              @click="showFirstChart = false"
                              >View sequestration</span
                            >
                            <span
                              class="continuos-texts d-inline-block ml-4 cursor-pointer"
                              @click="showFirstChart = false"
                              >></span
                            >
                          </span>
                          
                          <!-- <br />
                          600 -->
                        </v-col>
                      </v-row>
                    </span>
                    <!-- <span class="d-block">
                      <span
                        style="width: fit-content"
                        class="continuos-texts underline-custom cursor-pointer"
                        @click="showFirstChart = false"
                        >View sequestration</span
                      >
                      <span
                        class="continuos-texts d-inline-block ml-4 cursor-pointer"
                        @click="showFirstChart = false"
                        >></span
                      >
                    </span> -->
                    
                  </v-col>

                  <v-col cols="12" class="pt-12">
                    <div class="graphics">
                      <v-row
                        no-gutters
                        align="end"
                        justify="center"
                        class="mt-3 graphics__graphic position-relative"
                      >
                        <div style="position: absolute; left: 0px; top: 0px; z-index: 2;">
                          Embodied Carbon (Tons CO<sub>2</sub>e/m<sup>2</sup>)
                          
                        </div>
                        <div style="position: absolute; right: 0px; top: 24px; z-index:2">
                          <v-icon
                          :color="colorInfo"
                          class="cursor-pointer d-sm-none"
                          @click="overInfo = !overInfo"
                          >mdi-information</v-icon>

                          <v-icon
                          :color="colorInfo"
                          class="cursor-pointer d-none d-sm-inline-flex"
                          @mouseover="overInfo = true"
                          @mouseout="overInfo = false"
                          @click="overinfo = !overinfo"
                          >mdi-information</v-icon>

                        </div>
                        <div style="position: absolute; left: 0px; top: 27px; z-index: 100">
                          850
                        </div>
                        <div class="background-lines">
                          <div class="square"></div>
                          <div class="square border-outlined">
                            <span class="text-absolute-1">ILFI imit</span>
                          </div>
                          <div class="square"></div>
                          <div class="square"></div>
                          <div class="square"></div>
                          <div class="square position-relative">
                            <span class="text-absolute-1">250</span>
                          </div>
                          <div class="square border-black"></div>
                        </div>

                        <v-col cols="auto" class="px-2 col-bar px-md-3">
                          <template v-if="$vuetify.breakpoint.xsOnly">
                            <v-tooltip
                              top
                              v-model="overInfo"
                              color="#E8E6DF"
                              nudge-bottom="70px"
                              nudge-left="80px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="reduction-bar-black mx-auto"
                                  v-bind:class="{
                                    'fill black-bar':
                                      state == 2 && showFirstChart,
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                ></div>
                              </template>
                              <span
                                class="black--text pa-3"
                                style="border: 1px solid black"
                                >832 tons</span
                              >
                            </v-tooltip>
                          </template>
                          <template v-else>
                            <v-tooltip
                              right
                              v-model="overInfo"
                              color="#E8E6DF"
                              nudge-right="-5"
                              nudge-top="100%"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="reduction-bar-black mx-auto"
                                  v-bind:class="{
                                    'fill black-bar':
                                      state == 2 && showFirstChart,
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                ></div>
                              </template>
                              <span
                                class="black--text pa-3"
                                style="border: 1px solid black"
                                >832 tons</span
                              >
                            </v-tooltip>
                          </template>
                          
                          <!-- <div class="reduction-bar-black mx-auto" v-bind:class="{'fill':state == 2}">

                        </div> -->
                        </v-col>
                        <v-col
                          cols="auto"
                          class="px-2 col-bar px-md-3 graphic-2 position-relative"
                        >
                          <span class="text-absolute-2 d-block">25% reduction</span>

                          <div class="line-reduction-center">
                            <div class="line-cross-1"></div>
                            <div class="line-cross-2"></div>
                          </div>

                          <template v-if="$vuetify.breakpoint.xsOnly">
                            <v-tooltip
                              top
                              v-model="overInfo"
                              color="#E8E6DF"
                              nudge-bottom="70px"
                              nudge-right="80px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="reduction-bar-blue mx-auto"
                                  v-bind:class="{
                                    'fill blue-bar-reduction':
                                      state == 2 && showFirstChart,
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                ></div>
                              </template>
                              <span
                                class="black--text pa-3"
                                style="border: 1px solid black"
                                >618 tons</span
                              >
                            </v-tooltip>
                          </template>
                          <template v-else>
                            <v-tooltip
                              right
                              v-model="overInfo"
                              color="#E8E6DF"
                              nudge-right="-5"
                              nudge-top="10"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="reduction-bar-blue mx-auto"
                                  v-bind:class="{
                                    'fill blue-bar-reduction':
                                      state == 2 && showFirstChart,
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                ></div>
                              </template>
                              <span
                                class="black--text pa-3"
                                style="border: 1px solid black"
                                >618 tons</span
                              >
                            </v-tooltip>
                          </template>

                        </v-col>
                      </v-row>
                      <v-row no-gutters justify="center">
                        <v-col
                          cols="auto"
                          class="px-2 col-bar px-md-3 text-center"
                        >
                          <div class="bars-text">
                            Baseline home
                          </div>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="px-2 text-center col-bar px-md-3"
                        >
                          <div class="bars-text">
                            Zero 1
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </template>
                <template v-else>
                  <v-col
                    cols="12"
                    class="row-custom-2__column-1--line-height-custom"
                  >
                    <v-row no-gutters align="center">
                      <v-col>
                        <span class="d-block subtitles-big">Sequestration</span>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn icon @click="showGraphics = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <span
                      id="textoooo"
                      class="heigth-text"
                      style="position: relative"
                    >
                      <div
                        class="tooltop-custom-container"
                        v-bind:class="{ notOver: !overInfo }"
                      >
                        <transition name="scale-transition">
                          <div
                            v-if="overInfo"
                            class="text-tooltip-main black--text pa-3 pa-lg-6"
                          >
                            <p>
                              Since there is more carbon stored in the organic
                              materials than was emitted during construction, it
                              is a Carbon Negative Home.
                            </p>
                            <p class="mb-0">Organic materials used:</p>

                            <ul class="pl-4">
                              <li><span>structural lumber</span></li>
                              <li>
                                <span
                                  >finish wood (flooring, ceilings,
                                  siding)</span
                                >
                              </li>
                              <li><span>cellulose insulation</span></li>
                            </ul>
                          </div>
                        </transition>
                      </div>
                      <span class="continuos-texts d-block">
                        <!-- To build a zero-carbon home, first reduce and replace
                        carbon-intensive materials with low-carbon alternates,
                        then use sustainably sourced, biobased materiales
                        wherever possible to sequester more carbon than is
                        emitted. -->
                        <!-- To build a zero-carbon home, that first step is to
                        maximize energy efficiency, and the second step is to
                        use renewables for the remaining energy load. -->
                        The International Living Future Institute, the certifying organization for Zero Carbon, allows applicants to take credit for their sequestered CO2. If enough biogenic material was used in the building, as was the case with Zero 1, then the applicant won’t have to buy carbon offsets to get to net zero.
                      </span>
                      
                      <span class="continuos-texts d-block">
                        <span
                          class="continuos-texts d-inline-block mr-4 cursor-pointer"
                          @click="showFirstChart = true"
                        >
                          &lt;
                        </span>
                        <span
                          style="width: fit-content"
                          class="continuos-texts underline-custom cursor-pointer"
                          @click="showFirstChart = true"
                          >Back to reduction</span
                        >
                        
                      </span>
                    </span>
                    
                  </v-col>

                  <v-col cols="12" class="pt-12">
                    <div class="graphics">
                      <v-row
                        no-gutters
                        align="end"
                        justify="center"
                        class="mt-3 graphics__graphic sequestration"
                      >
                        <div class="background-lines">
                          <div style="position: absolute; right: 0px; top: 24px; z-index:2">
                            <v-icon
                            :color="colorInfo"
                            class="cursor-pointer d-sm-none"
                            @click="overInfo = !overInfo"
                            >mdi-information</v-icon>

                            <v-icon
                            :color="colorInfo"
                            class="cursor-pointer d-none d-sm-inline-flex"
                            @mouseover="overInfo = true"
                            @mouseout="overInfo = false"
                            @click="overinfo = !overinfo"
                            >mdi-information</v-icon>

                        </div>
                          <div style="position: absolute; left: 0px; top: 0px; z-index: 2;">
                            <span class="d-block">
                              <!-- <span
                                class="continuos-texts d-inline-block mr-2 cursor-pointer"
                                @click="showFirstChart = true"
                              >
                                &lt;
                              </span> -->
                              Embodied Carbon (Tons CO<sub>2</sub>e/m<sup>2</sup>)
                            </span>
                          </div>
                          <div class="square position-relative">
                            <span class="text-absolute-1">850</span>
                          </div>
                          <div class="square line-middle seq-text-to-right-xs">
                            <span class="text-absolute-1">ILFI imit</span>
                          </div>
                          <div class="square"></div>
                          <div class="square border-black"></div>
                          <div class="square"></div>
                          <div class="square"></div>
                          <div class="square position-relative">
                            <span class="text-absolute-1">-800</span>
                          </div>
                        </div>
                        <v-col cols="auto" class="px-3 col-bar">
                          <template v-if="!$vuetify.breakpoint.xsOnly">
                            <v-tooltip
                              right
                              nudge-top="60"
                              nudge-right="-5"
                              v-model="overInfo"
                              color="#E8E6DF"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="offset-bar-black mx-auto"
                                  v-bind:class="{
                                    'fill sequestration-bar-black':
                                      state == 2 && !showFirstChart,
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                ></div>
                              </template>
                              <span
                                class="black--text pa-3 tons596-label"
                                style="border: 1px solid black"
                                >832 tons</span
                              >
                            </v-tooltip>
                          </template>
                          <template v-else>
                            <v-tooltip
                              right
                              nudge-top="80"
                              nudge-right="-5"
                              v-model="overInfo"
                              color="#E8E6DF"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="offset-bar-black mx-auto"
                                  v-bind:class="{
                                    'fill sequestration-bar-black':
                                      state == 2 && !showFirstChart,
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                ></div>
                              </template>
                              <span
                                class="black--text pa-3 tons596-label"
                                style="border: 1px solid black"
                                >832 tons</span
                              >
                            </v-tooltip>
                          </template>
                          <!-- <div class="reduction-bar-black mx-auto" v-bind:class="{'fill':state == 2}">

                        </div> -->
                        </v-col>
                        <v-col
                          cols="auto"
                          class="px-3 col-bar position-relative"
                        >

                        <template v-if="$vuetify.breakpoint.xsOnly">
                          <v-tooltip
                            left
                            v-model="overInfo"
                            color="#E8E6DF"
                            nudge-bottom="100"
                            nudge-right="5"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-bind:class="{
                                  fill: state == 2 && !showFirstChart,
                                }"
                                v-bind="attrs"
                                v-on="on"
                                class="offset-bar-blue-round mx-auto"
                              >
                                <div
                                  class="offset-bar-blue mx-auto"
                                  style="position: absolute; bottom: 0"
                                  v-bind:class="{
                                    'fill-2': state == 2 && !showFirstChart,
                                  }"
                                ></div>
                              </div>
                            </template>
                            <span
                              class="black--text pa-3"
                              style="border: 1px solid black"
                              >-758 tons</span
                            >
                          </v-tooltip>
                        </template>
                        <template v-else>
                          <v-tooltip
                            right
                            v-model="overInfo"
                            color="#E8E6DF"
                            nudge-right="-5"
                            nudge-bottom="100"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-bind:class="{
                                  fill: state == 2 && !showFirstChart,
                                }"
                                v-bind="attrs"
                                v-on="on"
                                class="offset-bar-blue-round mx-auto"
                              >
                                <div
                                  class="offset-bar-blue mx-auto"
                                  style="position: absolute; bottom: 0"
                                  v-bind:class="{
                                    'fill-2': state == 2 && !showFirstChart,
                                  }"
                                ></div>
                              </div>
                            </template>
                            <span
                              class="black--text pa-3"
                              style="border: 1px solid black"
                              >-758 tons</span
                            >
                          </v-tooltip>
                        </template>


                        <template v-if="$vuetify.breakpoint.xsOnly">
                          <v-tooltip
                            right
                            v-model="overInfo"
                            color="#E8E6DF"
                            nudge-right="-5"
                            nudge-top="55"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                class="other-div-hidden"
                                v-bind="attrs"
                                v-on="on"
                              ></div>
                            </template>
                            <span
                              class="black--text pa-3"
                              style="border: 1px solid black"
                              >618 tons</span
                            >
                          </v-tooltip>
                        </template>
                        <template v-else>
                          <v-tooltip
                            right
                            v-model="overInfo"
                            color="#E8E6DF"
                            nudge-right="-5"
                            nudge-top="35"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                class="other-div-hidden"
                                v-bind="attrs"
                                v-on="on"
                              ></div>
                            </template>
                            <span
                              class="black--text pa-3"
                              style="border: 1px solid black"
                              >618 tons</span
                            >
                          </v-tooltip>
                        </template>

                        </v-col>
                        <v-col
                          cols="auto"
                          class="px-3 col-bar position-relative"
                        >

                          <template v-if="!$vuetify.breakpoint.xsOnly">
                            <v-tooltip
                              right
                              v-model="overInfo"
                              color="#E8E6DF"
                              nudge-right="-5"
                              nudge-bottom="00"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="offset-bar-blue mx-auto"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-bind:class="{
                                    'fill-3': state == 2 && !showFirstChart,
                                  }"
                                ></div>
                              </template>
                              <span
                                class="black--text pa-3"
                                style="border: 1px solid black"
                                >Net of -140 tons</span
                              >
                            </v-tooltip>
                          </template>
                          <template v-else>
                            <v-tooltip
                              bottom
                              v-model="overInfo"
                              color="#E8E6DF"
                              nudge-right="0"
                              nudge-bottom="-5"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="offset-bar-blue mx-auto"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-bind:class="{
                                    'fill-3': state == 2 && !showFirstChart,
                                  }"
                                ></div>
                              </template>
                              <span
                                class="black--text pa-3"
                                style="border: 1px solid black"
                                >Net of -140 tons</span
                              >
                            </v-tooltip>
                          </template>
                        </v-col>
                      </v-row>
                      <v-row no-gutters justify="center">
                        <v-col cols="auto" class="px-3 text-center col-bar">
                          <div class="bars-text">
                            Baseline home
                          </div>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="px-3 text-center col-bar"
                          order="1"
                        >
                          <div class="bars-text">
                            Zero 1
                          </div>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="px-3 text-center col-bar"
                          order="1"
                        >
                          <div class="bars-text">
                            Zero 1, Net
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </template>

                <!-- close -->
                <v-col
                  cols="12"
                  class="row-custom-2__column-1--line-height-custom text-center btn-close-column"
                >
                  <v-row justify="center" justify-sm="space-between" align="center" class="pb-6">
                    <v-col cols="12" sm="auto">
                      <!-- <a
                        href="/Marisol-Zero-1_Carbon-Report_11-29-21.pdf"
                        target="_blank"
                        class="not-text-decoration btn-black rewrite-width-mobile mt-3 mt-sm-0"
                      >
                        <span class="white--text continuos-texts"
                          >View Full Report</span
                        >
                      </a> -->
                    </v-col>
                    <v-col cols="auto">
                      <span
                        @click="showGraphics = false"
                        class="cursor-pointer"
                      >
                        <span class="continuos-texts">Close</span>
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- /close -->
              </v-row>
            </v-col>
          </v-row>
          <!-- /graphics 1-->

          <!-- graphics 2-->
          <v-row no-gutters class="fill-height" v-if="showGraphics2">
            <v-col cols="12">
              <v-row no-gutters>
                <template v-if="showFirstChart">
                  <v-col
                    cols="12"
                    class="row-custom-2__column-1--line-height-custom"
                  >
                    <!-- <span class="d-block">
                      <span
                        style="width: fit-content"
                        class="continuos-texts underline-custom cursor-pointer"
                        @click="showFirstChart = false"
                        >View offset</span
                      >
                      <span
                        class="continuos-texts d-inline-block ml-4 cursor-pointer"
                        @click="showFirstChart = false"
                        >></span
                      >
                    </span> -->
                    <v-row no-gutters align="center">
                      <v-col>
                        <span class="d-block subtitles-big">Reduction</span>
                      </v-col>
                      <v-col cols="auto" class="text-right">
                        <!-- <v-icon
                          :color="colorInfo"
                          class="cursor-pointer"
                          @mouseover="overInfo = true"
                          @mouseout="overInfo = false"
                          >mdi-information</v-icon
                        > -->

                        <v-btn icon @click="showGraphics2 = false; showFirstChart = true;">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <span
                      id="textoooo"
                      class="heigth-text"
                      style="position: relative"
                    >
                      <div
                        class="tooltop-custom-container"
                        v-bind:class="{ notOver: !overInfo }"
                      >
                        <transition name="scale-transition">
                          <div
                            v-if="overInfo"
                            class="text-tooltip-main black--text pa-3 pa-lg-6"
                          >
                            <p>
                              Baseline: Uses max EUI allowed by the Zero Carbon Certification.
                            </p>

                            <p>
                              Zero 1, Energy Efficiency: Shows Lot 17's energy usage if it was just connected to the standard grid.
                            </p>

                            <!-- <p>
                              Zero 1: shows the effect of using 100% renewable
                              energy
                            </p> -->
                          </div>
                        </transition>
                      </div>
                      <span class="continuos-texts d-block">
                        <!-- To build a zero-carbon home, that first step is to
                        maximize energy efficiency, and the second step is to
                        use renewables for the remaining energy load. -->
                        To reach net zero operating carbon, the first step is to maximize energy efficiency, and the second step is to use renewables for the remaining energy load.
                      </span>
                      <v-row no-gutters>
                        <v-col>
                          <span class="d-block mt-2">
                            <span
                              style="width: fit-content"
                              class="continuos-texts underline-custom cursor-pointer"
                              @click="showFirstChart = false"
                              >View renewables</span
                            >
                            <span
                              class="continuos-texts d-inline-block ml-4 cursor-pointer"
                              @click="showFirstChart = false"
                              >></span
                            >
                        </span>
                          
                        </v-col>
                      </v-row>
                    </span>
                  </v-col>

                  <v-col cols="12" class="pt-12">
                    <div class="graphics" style="position: relative;">

                      <div style="position: absolute; left: 0px; top: 0px; z-index: 2;">
                        <!-- <span class="d-block">
                          <span
                            style="width: fit-content"
                            class="continuos-texts underline-custom cursor-pointer"
                            @click="showFirstChart = false"
                            >View offset</span
                          >
                          <span
                            class="continuos-texts d-inline-block ml-4 cursor-pointer"
                            @click="showFirstChart = false"
                            >></span
                          >
                        </span> -->
                        Embodied Carbon (Tons CO<sub>2</sub>e/m<sup>2</sup>)
                      </div>

                      <div style="position: absolute; right: 0px; top: 65px; z-index:2">
                          <v-icon
                          :color="colorInfo"
                          class="cursor-pointer d-sm-none"
                          @click="overInfo = !overInfo"
                          >mdi-information</v-icon>

                          <v-icon
                          :color="colorInfo"
                          class="cursor-pointer d-none d-sm-inline-flex"
                          @mouseover="overInfo = true"
                          @mouseout="overInfo = false"
                          @click="overinfo = !overinfo"
                          >mdi-information</v-icon>

                      </div>

                      <span style="position: absolute; top: 30px; left: 0;">
                        1,500
                      </span>
                      <v-row
                        style="border-bottom: 1px solid black"
                        no-gutters
                        align="end"
                        justify="center"
                        class="mt-3 graphics__graphic"
                      >
                        <div class="background-lines-2">
                          <div class="square"></div>
                          <div class="square"></div>
                          <div class="square"></div>
                          <div class="square border-black"></div>
                        </div>

                        <v-col cols="auto" class="px-2 col-bar px-md-3">
                          <v-tooltip
                            right
                            v-model="overInfo"
                            color="#E8E6DF"
                            nudge-right="0"
                            nudge-top="100%"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                class="reduction-bar-black mx-auto"
                                v-bind:class="{
                                  fill: state == 2 && showFirstChart,
                                }"
                                v-bind="attrs"
                                v-on="on"
                              ></div>
                            </template>
                            <span
                              class="black--text pa-3"
                              style="border: 1px solid black"
                              >1,450 tons</span
                            >
                          </v-tooltip>
                          <!-- <div class="reduction-bar-black mx-auto" v-bind:class="{'fill':state == 2}">

                        </div> -->
                        </v-col>
                        <v-col cols="auto" class="px-2 col-bar px-md-3">
                          <v-tooltip
                            right
                            v-model="overInfo"
                            color="#E8E6DF"
                            nudge-right="0"
                            nudge-top="10"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                class="reduction-bar-blue mx-auto"
                                v-bind:class="{
                                  fill: state == 2 && showFirstChart,
                                }"
                                v-bind="attrs"
                                v-on="on"
                              ></div>
                            </template>
                            <span
                              class="black--text pa-3"
                              style="border: 1px solid black"
                              >352 tons</span
                            >
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col
                          cols="auto"
                          class="px-2 col-bar px-md-3 text-center"
                        >
                          <div class="bars-text">
                            Baseline home
                          </div>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="px-2 text-center col-bar px-md-3"
                        >

                            Zero 1, <br />
                            Energy Efficiency
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </template>
                <template v-else>
                  <v-col
                    cols="12"
                    class="row-custom-2__column-1--line-height-custom"
                  >
                    <!-- <span class="d-block">
                      <span
                        class="continuos-texts d-inline-block mr-4 cursor-pointer"
                        @click="showFirstChart = true"
                      >
                        &lt;
                      </span>
                      <span
                        style="width: fit-content"
                        class="continuos-texts underline-custom cursor-pointer"
                        @click="showFirstChart = true"
                        >Back</span
                      >
                    </span> -->
                    <v-row no-gutters align="center">
                      <v-col>
                        <span class="d-block subtitles-big">Renewables</span>
                      </v-col>
                      <v-col class="text-right">
                        <!-- <v-icon
                          :color="colorInfo"
                          class="cursor-pointer"
                          @mouseover="overInfo = true"
                          @mouseout="overInfo = false"
                          >mdi-information</v-icon
                        > -->

                        <v-btn icon @click="showGraphics2 = false; showFirstChart = true;">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <span
                      id="textoooo"
                      class="heigth-text"
                      style="position: relative"
                    >
                      <div
                        class="tooltop-custom-container"
                        v-bind:class="{ notOver: !overInfo }"
                      >
                        <transition name="scale-transition">
                          <div
                            v-if="overInfo"
                            class="text-tooltip-main black--text pa-3 pa-lg-6"
                          >
                            <p>
                              <b>Baseline:</b> Uses max EUI allowed by the Zero Carbon Certification.
                            </p>

                            <p>
                              <b>Zero 1, Energy Efficiency:</b> Shows Lot 17's energy usage if it was just connected to the standard grid.
                            </p>

                            <p>
                              <b>Zero 1, Offset:</b> Shows the effect of using 100% renewable energy.
                            </p>
                          </div>
                        </transition>
                      </div>
                      <span class="continuos-texts d-block">
                        <!-- To build a zero-carbon home, that first step is to
                        maximize energy efficiency, and the second step is to
                        use renewables for the remaining energy load. -->
                        The International Living Future Institute, the certifying organization for Zero Carbon, allows applicants to utilize 100% renewable energy from the Clean Power Alliance utility which serves Ventura County and other municipalities in Southern California.
                      </span>
                      <span class="d-block">
                        <span
                          class="continuos-texts d-inline-block mr-4 cursor-pointer"
                          @click="showFirstChart = true"
                        >
                          &lt;
                        </span>
                        <span
                          style="width: fit-content"
                          class="continuos-texts underline-custom cursor-pointer"
                          @click="showFirstChart = true"
                          >Back to reduction</span
                        >
                      </span>
                    </span>
                  </v-col>

                  <v-col cols="12" class="pt-12">
                    <div class="graphics">
                      <v-row
                        style="border-bottom: 1px solid black"
                        no-gutters
                        align="end"
                        justify="end"
                        justify-md="center"
                        class="mt-3 graphics__graphic"
                      >
                        <div class="background-lines different">
                          <div class="square position-relative">
                            <span class="text-absolute-1">1,500</span>

                            <div style="position: absolute; left: 0px; top: 0px; z-index: 2;">
                              <span class="d-block">
                                <!-- <span
                                  class="continuos-texts d-inline-block mr-4 cursor-pointer"
                                  @click="showFirstChart = true"
                                >
                                  &lt;
                                </span> -->
                                <!-- <span
                                  style="width: fit-content"
                                  class="continuos-texts underline-custom cursor-pointer"
                                  @click="showFirstChart = true"
                                  >Back</span
                                > -->
                                Tons CO2e
                              </span>
                            </div>
                            <div style="position: absolute; right: 0px; bottom: 3px; z-index:2">
                              <v-icon
                              :color="colorInfo"
                              class="cursor-pointer d-sm-none"
                              @click="overInfo = !overInfo"
                              >mdi-information</v-icon>

                              <v-icon
                              :color="colorInfo"
                              class="cursor-pointer d-none d-sm-inline-flex"
                              @mouseover="overInfo = true"
                              @mouseout="overInfo = false"
                              @click="overinfo = !overinfo"
                              >mdi-information</v-icon>
                            </div>
                          </div>
                          <div class="square"></div>
                          <div class="square"></div>
                          <div class="square"></div>
                          <div class="square position-relative">
                            <span class="text-absolute-1">200</span>
                          </div>
                          <div class="square border-black"></div>
                        </div>

                        <v-col cols="auto" class="px-3 col-bar" order="1">

                          <template v-if="$vuetify.breakpoint.xsOnly">
                            <v-tooltip top nudge-right="15" nudge-top="-5" v-model="overInfo" color="#E8E6DF">
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="offset-bar-black mx-auto"
                                  v-bind:class="{
                                    fill: state == 2 && !showFirstChart,
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                ></div>
                              </template>
                              <span
                                class="black--text pa-3"
                                style="border: 1px solid black"
                                >0 tons</span
                              >
                            </v-tooltip>
                          </template>
                          <template v-else>
                            <v-tooltip top v-model="overInfo" color="#E8E6DF">
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="offset-bar-black mx-auto"
                                  v-bind:class="{
                                    fill: state == 2 && !showFirstChart,
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                ></div>
                              </template>
                              <span
                                class="black--text pa-3"
                                style="border: 1px solid black"
                                >0 tons</span
                              >
                            </v-tooltip>
                          </template>
                          <!-- <div class="reduction-bar-black mx-auto" v-bind:class="{'fill':state == 2}">

                        </div> -->
                        </v-col>

                        <v-col cols="auto" class="px-3 col-bar" order="0">
                          <template v-if="$vuetify.breakpoint.xsOnly">
                            <v-tooltip
                              right
                              v-model="overInfo"
                              color="#E8E6DF"
                              nudge-right="-5"
                              nudge-top="100"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="offset-bar-black baseline-home mx-auto"
                                  v-bind:class="{
                                    fill: state == 2 && !showFirstChart,
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                ></div>
                              </template>
                              <span
                                class="black--text pa-3"
                                style="border: 1px solid black"
                                >1,450 tons</span
                              >
                            </v-tooltip>
                          </template>
                          <template v-else>
                            <v-tooltip
                              right
                              v-model="overInfo"
                              color="#E8E6DF"
                              nudge-right="-5"
                              nudge-top="10"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="offset-bar-black baseline-home mx-auto"
                                  v-bind:class="{
                                    fill: state == 2 && !showFirstChart,
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                ></div>
                              </template>
                              <span
                                class="black--text pa-3"
                                style="border: 1px solid black"
                                >1450 tons</span
                              >
                            </v-tooltip>
                          </template>
                        </v-col>
                        
                        <v-col cols="auto" class="px-3 col-bar" order="0">
                          <template v-if="$vuetify.breakpoint.xsOnly">
                            <v-tooltip
                              right
                              v-model="overInfo"
                              color="#E8E6DF"
                              nudge-right="-5"
                              nudge-top="100"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="offset-bar-blue mx-auto"
                                  v-bind:class="{
                                    fill: state == 2 && !showFirstChart,
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                ></div>
                              </template>
                              <span
                                class="black--text pa-3"
                                style="border: 1px solid black"
                                >352 tons</span
                              >
                            </v-tooltip>
                          </template>
                          <template v-else>
                            <v-tooltip
                              right
                              v-model="overInfo"
                              color="#E8E6DF"
                              nudge-right="-5"
                              nudge-top="10"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="offset-bar-blue mx-auto"
                                  v-bind:class="{
                                    fill: state == 2 && !showFirstChart,
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                ></div>
                              </template>
                              <span
                                class="black--text pa-3"
                                style="border: 1px solid black"
                                >352 tons</span
                              >
                            </v-tooltip>
                          </template>
                        </v-col>
                      </v-row>
                      <v-row no-gutters justify="end" justify-md="center">
                        <v-col cols="auto" class="px-3 text-center col-bar">
                          <div class="bars-text">
                          Baseline <br />
                          Home
                          </div>
                        </v-col>
                        <v-col cols="auto" class="px-3 text-center col-bar">
                          <div class="bars-text">
                          Zero 1 <br />
                          Energy Efficiency
                          </div>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="px-3 text-center col-bar"
                          order="1"
                        >
                        <div class="bars-text">
                          Zero 1, <br />
                          Offset
                        </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </template>

                <!-- close -->
                <v-col
                  cols="12"
                  class="row-custom-2__column-1--line-height-custom text-center btn-close-column"
                >
                  <v-row justify="center" justify-sm="space-between" align="center" class="pb-6">
                    <v-col cols="12" sm="auto">
                      <!-- <a
                        href="/Marisol-Zero-1_Carbon-Report_11-29-21.pdf"
                        target="_blank"
                        class="not-text-decoration btn-black rewrite-width-mobile mt-6 mt-sm-0"
                      >
                        <span class="white--text continuos-texts"
                          >View Full Report</span
                        >
                      </a> -->
                    </v-col>
                    <v-col cols="auto">
                      <span
                        @click="showGraphics2 = false; showFirstChart = true;"
                        class="cursor-pointer"
                      >
                        <span class="continuos-texts">Close</span>
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- /close -->
              </v-row>
            </v-col>
          </v-row>
          <!-- /graphics 2-->
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="row-custom-2__column-2 position-relative"
        >
          <template v-if="render">
            <carousel :items="1" :nav="false" :loop="true">
              <template
                v-for="(image, img3) in zero1Singleton.carousel_graphics"
              >
                <template v-if="image.value != null">
                  <img
                    :src="
                      'https://content.marisolmalibu.com' +
                      image.value.path
                    "
                    :key="img3"
                  />
                </template>
              </template>

              <template slot="prev">
                <v-img
                  src="@/assets/icons/Flecha-derecha.svg"
                  class="prev" style="z-index: 1000"
                ></v-img>
              </template>
              <template slot="next">
                <v-img
                  src="@/assets/icons/Flecha-izquierda.svg"
                  class="next" style="z-index: 1000"
                ></v-img>
              </template>
            </carousel>
          </template>
        </v-col>
      </v-row>

      <v-row class="animation-each py-12 my-0 gradient-materials" style="">
        <v-col class="padding-custom-general-2">
          <v-row>
            <v-col cols="12" class="px-0 pt-0">
              <v-row
                no-gutters
                justify="center"
                align="center"
                class="fill-height position-relative"
              >
                <div class="d-flex dots-div in-property align-self-end">
                  <span
                    :class="{ active: dotsOwl == 5 || dotsOwl == 2 }"
                    @click="
                      dotsOwl = 5;
                      owlCarN3();
                    "
                    class="dots-custom cursor-pointer"
                  ></span>
                  <span
                    :class="{ active: dotsOwl == 3 }"
                    @click="
                      dotsOwl = 3;
                      owlCarN2();
                    "
                    class="dots-custom cursor-pointer"
                  ></span>
                  <span
                    :class="{ active: dotsOwl == 4 || dotsOwl == 1 }"
                    @click="
                      dotsOwl = 4;
                      owlCarN();
                    "
                    class="dots-custom cursor-pointer"
                  ></span>
                </div>

                <v-col cols="12" sm="5" order="1" order-sm="0" class="pt-3">
                  <v-row no-gutters justify="center" class="animation-each">
                    <v-col
                      cols="auto"
                      class="block__third__ml-custom text-center text-sm-left position-relative"
                      v-bind:class="{ 'rewrite-margin-right': dotsOwl == 3 }"
                    >
                      <div v-if="dotsOwl == 5 || dotsOwl == 2">
                        <div>
                          <span
                            class="subtitles-big line-height-title founders-grotesk-light"
                            >Shou <br />
                            Sugi Ban
                          </span>
                        </div>

                        <div>
                          <p
                            class="continuos-texts block__third__margin-p line-height-description"
                          >
                            Traditional Japanese <br />
                            waterproofing technique.
                            <br class="d-none d-md-block" />
                          </p>
                        </div>
                      </div>
                      <div v-if="dotsOwl == 3">
                        <div>
                          <span
                            class="subtitles-big line-height-title founders-grotesk-light"
                            >Native <br />
                            Timber
                          </span>
                        </div>

                        <div>
                          <p
                            class="continuos-texts block__third__margin-p line-height-description"
                          >
                            30% renewable <br />
                            Californian lumber.
                            <br class="d-none d-md-block" />
                          </p>
                        </div>
                      </div>
                      <div v-if="dotsOwl == 4">
                        <div>
                          <span
                            class="subtitles-big line-height-title founders-grotesk-light"
                            >American <br />
                            Steel
                          </span>
                        </div>

                        <div>
                          <p
                            class="continuos-texts block__third__margin-p line-height-description"
                          >
                            Minimal emissions and<br />
                            transportation.
                            <br class="d-none d-md-block" />
                          </p>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="7" class="block__third__second-column">
                  <div class="slider position-relative">
                    <template v-if="render">
                      <carousel
                        :items="1"
                        :nav="false"
                        :margin="5"
                        :loop="true"
                        :dots="false"
                        @changed="changedOwlCarousel"
                        :responsive="{
                          600: {
                            items: 1.2,
                          },
                        }"
                        id="firstImage"
                        style="transition: opacity 0.5s ease"
                        ref="owlCar"
                      >
                        <template
                          v-for="(
                            image, img2
                          ) in zero1Singleton.carousel_shougi_ban"
                        >
                          <template v-if="image.value.image != null">
                            <img
                              :key="img2"
                              :src="
                                'https://content.marisolmalibu.com' +
                                image.value.image.path
                              "
                              alt=""
                            />
                          </template>
                        </template>

                        <template slot="next">
                          <div
                            id="nextOwl"
                            class="div-for-arrow cursor-pointer d-none d-sm-block"
                          >
                            <v-img
                              src="@/assets/icons/Flecha-izquierda.svg"
                              class="arrow--right"
                            ></v-img>
                          </div>
                        </template>
                        <template slot="prev">
                          <div id="prevOwl" class="d-none">
                            <v-img
                              src="@/assets/icons/Flecha-izquierda.svg"
                              class="arrow--right"
                            ></v-img>
                          </div>
                        </template>
                      </carousel>
                    </template>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- sun and splendor bloque -->
    <v-container fluid class="block-second half-gradient pb-0">
      <v-row class="block__second animation-each">
        <v-col class="pa-0">
          <v-row no-gutters class="fill-height" justify="center" align="center">
            <v-col cols="auto" class="text-center">
              <div class="max-width-title-block">
                <span
                  class="sabon-regular block__second__title-second-paragraph"
                  v-html="zero1Singleton.title_sun_and_splendor"
                >
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- /sun and splendor bloque -->

    <v-container fluid class="py-0" style="background: rgb(232, 230, 223)">
      <v-row>
        <v-col class="pa-0">
          <v-container
            fluid
            class="py-0 padding-in-sun-and-splendor-1"
            id="fifthCarousel"
          >
            <v-row
              justify="space-between"
              align="center"
              no-gutters
              class="animation-each"
            >
              <v-col
                cols="12"
                sm="auto"
                class="position-relative text-center text-sm-left"
              >
                <div>
                  <span
                    class="subtitles-big line-height-title founders-grotesk-light pre-line"
                  >
                    {{ zero1Singleton.texts_one_with_malibu.title }}
                  </span>
                </div>
                <div>
                  <p
                    class="continuos-texts block__third__margin-p line-height-description pre-line mx-auto mx-sm-0"
                    style="max-width: 280px;"
                  >
                    {{ zero1Singleton.texts_one_with_malibu.description }}
                  </p>
                </div>
                <div class="logo-sign-1" v-if="$vuetify.breakpoint.smAndUp">
               
                  <template v-if="carouselOneWithMalibu == 0">
                    <img
                      src="@/assets/img/05-sun-and-splendor/lemon-2.png"
                      class="ml-auto"
                    />
                  </template>
                  <template v-if="carouselOneWithMalibu == 1">
                    <img
                      src="@/assets/img/05-sun-and-splendor/butterflies.png"
                      class="ml-auto"
                    />
                  </template>
                  <template v-if="carouselOneWithMalibu == 2">
                    <img
                      src="@/assets/img/05-sun-and-splendor/palm.png"
                      class="ml-auto"
                    />
                  </template>
                  <template v-if="carouselOneWithMalibu == 3">
                    <img
                      src="@/assets/img/05-sun-and-splendor/owl.png"
                      class="ml-auto"
                    />
                  </template>
                </div>
              </v-col>
              <v-col cols="12" sm="auto" class="position-relative">
                <v-carousel
                  hide-delimiters
                  v-bind:show-arrows="false"
                  v-bind:height="heightCarouselOneWithMalibu"
                  v-model="carouselOneWithMalibu"
                >
                  <v-carousel-item
                    v-for="(
                      image, img4
                    ) in zero1Singleton.carousel_one_with_malibu"
                    v-bind:key="img4"
                    v-bind:src="
                      'https://content.marisolmalibu.com' +
                      image.value.image_big.path
                    "
                    eager
                  ></v-carousel-item>
                </v-carousel>

                <v-row no-gutters justify="space-between">
                  <v-col cols="12" sm="6">
                    <div
                      class="d-flex dots-div in-one-with-malibu align-self-end"
                    >
                      <span
                        v-for="(
                          dot, dt
                        ) in zero1Singleton.carousel_one_with_malibu"
                        v-bind:key="dt"
                        v-bind:class="{ active: dt == carouselOneWithMalibu }"
                        @click="carouselOneWithMalibu = dt"
                        class="dots-custom cursor-pointer"
                      ></span>
                      
                    </div>
                  </v-col>
                  <v-col cols="6" v-if="$vuetify.breakpoint.smAndUp">
                    <v-row no-gutters justify="end" class="mt-5">
                      <v-col cols="auto">
                        <v-img
                          src="@/assets/icons/Flecha-derecha.svg"
                          class="prev with-v-carousel"
                          @click="prevCustom"
                        ></v-img>
                      </v-col>

                      <v-col cols="auto">
                        <v-img
                          src="@/assets/icons/Flecha-izquierda.svg"
                          class="next with-v-carousel"
                          @click="carouselOneWithMalibu++"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                

                <div class="logo-sign-1 py-8" v-if="$vuetify.breakpoint.xs">
                

                  <template v-if="carouselOneWithMalibu == 0">
                    <img
                      src="@/assets/img/05-sun-and-splendor/lemon-2.png"
                      class="mobile-lemon"
                    />
                  </template>
                  <template v-if="carouselOneWithMalibu == 1">
                    <img
                      src="@/assets/img/05-sun-and-splendor/butterflies.png"
                    />
                  </template>
                  <template v-if="carouselOneWithMalibu == 2">
                    <img src="@/assets/img/05-sun-and-splendor/palm.png" />
                  </template>
                  <template v-if="carouselOneWithMalibu == 3">
                    <img src="@/assets/img/05-sun-and-splendor/owl.png" />
                  </template>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container
            fluid
            class="py-0 padding-in-sun-and-splendor-2 row-2-sun"
          >
            <v-row
              justify="space-between"
              align="center"
              class="margin-top-custom-row-2 animation-each"
              no-gutters
            >
              <v-col cols="12" sm="auto" order="1" order-sm="0">
                <v-img
                  :src="
                    'https://content.marisolmalibu.com' +
                    zero1Singleton.img_home_away.path
                  "
                  class="sun-and-splendor-img rewrite-width right"
                  eager
                ></v-img>
              </v-col>

              <v-col
                cols="12"
                sm="auto"
                class="text-center text-sm-left home-away-text"
              >
                <div>
                  <span
                    class="subtitles-big line-height-title founders-grotesk-light pre-line"
                  >
                    {{ zero1Singleton.texts_home_away.title }}
                  </span>
                </div>
                <div>
                  <p
                    class="continuos-texts block__third__margin-p line-height-description pre-line"
                  >
                    {{ zero1Singleton.texts_home_away.description }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container
            fluid
            class="py-0 padding-in-sun-and-splendor-1 row-2-sun"
          >
            <v-row
              align="center"
              class="margin-top-custom-row-2 animation-each"
              no-gutters
              justify="space-between"
            >
              <v-col
                cols="12"
                sm="auto"
                class="position-relative mover-element-crystal-1"
              >
                <v-row no-gutters justify="center">
                  <v-col cols="auto">
                    <div class="crystal-title-text">
                      <span
                        class="subtitles-big line-height-title founders-grotesk-light pre-line text-center text-sm-left"
                        >{{ zero1Singleton.texts_crystal_clear.title }}</span
                      >
                    </div>
                    <div class="text-center text-sm-left crystal-title-text">
                      <p
                        class="continuos-texts block__third__margin-p line-height-description pre-line"
                      >
                        {{ zero1Singleton.texts_crystal_clear.description }}
                      </p>
                    </div>
                    <div
                      class="map-2 animation-each"
                      v-if="$vuetify.breakpoint.smAndUp"
                    >
                      <!-- <v-img
                        src="@/assets/img/05-sun-and-splendor/Map_Placeholder-3.png"
                        contain
                      ></v-img> -->
                      <!-- <svgMap> </svgMap> -->
                      <svgMap2></svgMap2>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="auto" lg="auto" class="mover-element-crystal-2">
                <v-img
                  class="crystal-clear-img"
                  contain
                  :src="
                    'https://content.marisolmalibu.com' +
                    zero1Singleton.img_crystal_clear.path
                  "
                  :position="$vuetify.breakpoint.xs ? 'left' : 'right'"
                ></v-img>
                <div class="d-none d-md-block pt-12" style="margin-top: 50px">
                  <!-- <v-img src="/ILFI_logo-large.png" :width="newCertificationsWidth2" class="d-block mx-lg-auto ml-auto mr-12 mt-12" contain></v-img> -->
                  <v-img src="/GNA-Logo-Full.png" :width="newCertificationsWidth" class="d-block mx-lg-auto ml-auto mr-12 mt-12" style="filter: saturate(0%);" contain></v-img>
                  <v-img src="/zero-carbon-certified.png" :width="newCertificationsWidth" class="d-block mx-lg-auto ml-auto mr-12 mt-12" style="filter: saturate(0%); mix-blend-mode: multiply;" contain></v-img>
                </div>
              </v-col>
              <v-col class="mt-12" cols="12" v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
                <div class="d-lg-none my-12">
                  <!-- <v-img src="/ILFI_logo-large.png" width="250" class="d-block mx-auto mt-12" contain></v-img> -->
                  <v-img src="/GNA-Logo-Full.png" width="220" class="d-block mx-auto mt-12" style="filter: saturate(0%);" contain></v-img>
                  <v-img src="/zero-carbon-certified.png" width="220" class="d-block mx-auto mt-12" style="filter: saturate(0%); mix-blend-mode: multiply;" contain></v-img>
                </div>
                <div
                  class="animation-each"
                  v-if="$vuetify.breakpoint.xs"
                >
                  <!-- <v-img
                    src="@/assets/img/05-sun-and-splendor/Map_Placeholder-3.png"
                    contain
                  ></v-img> -->
                  <!-- <svgMap> </svgMap> -->
                  <svgMap3></svgMap3>
                </div>
                <!-- <v-img
                  src="@/assets/img/05-sun-and-splendor/Map_Placeholder-3.png"
                ></v-img> -->
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row class="row-parallax property">
        <v-col
          cols="12"
          class="px-0"
          :class="$vuetify.breakpoint.xs ? 'pa-0' : ''"
          style="overflow-x: visible"
        >
          <div class="parallax-container">
            <div
              class="parallax"
              :style="
                'background-image: url(https://content.marisolmalibu.com' +
                zero1Singleton.img_parallax.path +
                ');'
              "
            ></div>
            <div class="see-yourself-div text-center">
              <span
                class="white--text titles-xxl d-block sabon-regular"
                v-html="zero1Singleton.text_parallax"
              ></span>
              <!-- <v-btn
                class="btn-2 letter-spacing-0 not-text-transform"
                tile
                color="white"
                elevation="0"
                :ripple="false"
                to="/contact"
              >
                <span class="continuos-texts">Book a showing</span></v-btn
              > -->
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar
      v-model="snackbarZillow"
      top
      app
      id="snackbarZillow"
      color="black"
    >
      <div class="text-center">
        <span class="white--text continuos-texts">You are being redirected to Zillow.</span>
      </div>
    </v-snackbar>

  </div>
</template>

<script>
// @ is an alias to /src
import carousel from "vue-owl-carousel";
// import svgMap from "../components/MapProperty";
import svgMap2 from "../components/MapPropertyNew";
import svgMap3 from "../components/MapPropertyNewMovil";
import svgMap from "../components/MapBuilt";
import svgMapBuiltMobile from "../components/MapBuiltMobileWithScrollOverflow";

export default {
  metaInfoaa() {
    return{     
      title: 'Sustainable Home | Marisol Malibu',
      // titleTemplate: 'Marisol Malibu',
      htmlAttrs: {
        lang: 'en'
      },
      meta: [
        {vmid: 'og:description', property: 'og:description', name:'description', content: 'To build a zero-carbon home, first we reduce and replace carbon-intensive materials with low-carbon alternates, then we use sustainably sourced, biobased materials.'}
      ]
    }
  },
  metaInfo: {
    title: 'Sustainable Home | Marisol Malibu',
    // titleTemplate: 'Marisol Malibu',
    htmlAttrs: {
      lang: 'en'
    },
    meta: [
      {vmid: 'og:description', name:'description', content: 'To build a zero-carbon home, first we reduce and replace carbon-intensive materials with low-carbon alternates, then we use sustainably sourced, biobased materials.'}
    ]
  },
  name: "Property",
  components: {
    carousel,
    // svgMap,
    svgMap2,
    svgMap3,
    svgMap,
    svgMapBuiltMobile,
  },
  data() {
    return {
      render: false,
      caruselFullSizeAutoplay: true,
      homeSingleton: {
        intro_video: {
          path: ''
        },
        texts_sustainably:{
          title: ''
        },
        img_sustainably:{
          path: ''
        },
        img_enduring:{
          path: ''
        },
        texts_enduring: {
          path: ''
        },
        img_living: {
          path: ''
        },
        texts_living: {
          title: ''
        },
        texts_peak: {
          title: ''
        },
        img_peak: {
          path: ''
        }

      },
      zero1Singleton: {
        zilllow_button_text: '',
        lifestyle_carousel_image_1:{
          path: ''
        },
        lifestyle_carousel_image_1_1:{
          path: ''
        },
        lifestyle_carousel_image_2:{
          path: ''
        },
        lifestyle_carousel_image_3:{
          path: ''
        },
        lifestyle_carousel_image_4:{
          path: ''
        }
      },
      dotsOwl: 2,
      dotsOwl2: 2,
      open_menu: false,
      cover: false,
      showGraphics: false,
      showGraphics2: false,
      state: 0,
      value1: 0,
      value2: 0,
      interval: 0,
      // en see how we compare...
      intervalSlider1: 0,
      intervalSlider2: 0,
      //graphics
      numbersGraphics1: 0,
      numbersGraphics2: 0,
      numero: 0,
      numero1: 0,
      numero2: 0,
      numero3: 0,
      numero5: 0,
      permitirNumero: false,
      final: false,
      overInfo: false,
      showFirstChart: true,
      carouselOneWithMalibu: 0,
      snackbarZillow: false
    };
  },
  computed: {
    newCertificationsWidth2: function () {
      if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md){
        return 150;
      }else{
        return 250;
      }
    },
    newCertificationsWidth: function () {
      if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md){
        return 130;
      }else{
        return 220;
      }
    },
    colorInfo: function () {
      if (this.overInfo) {
        return "#0070E0";
      }

      return "black";
    },
    heightCarouselOneWithMalibu: function () {
      var height = 270;

      if (this.$vuetify.breakpoint.width <= 600) height = 270;
      else if (this.$vuetify.breakpoint.width <= 960) height = 248;
      else if (this.$vuetify.breakpoint.width <= 1263) height = 331;
      else if (this.$vuetify.breakpoint.width <= 1903) height = 416;
      else if (this.$vuetify.breakpoint.width <= 2300) height = 625;
      else if (this.$vuetify.breakpoint.width <= 3630) height = 832;
      else if (this.$vuetify.breakpoint.width >= 3631) height = 1253;

      return height;
    },
  },
  watch: {
    $route() {
      this.open_menu = false;
    },
    showGraphics: function () {
      this.showFirstChart = true;
      this.executeAnimationGraphics();
      this.overInfo = false;
    },
    showFirstChart: function () {
      this.state = 0;
      this.overInfo = false;
      setTimeout(() => {
        this.executeAnimationGraphics();
      }, 500);
    },
    showGraphics2: function () {
      this.overInfo = false;
    },
  },
  created() {
    fetch("https://content.marisolmalibu.com/api/singletons/get/home")
      .then((data) => data.json())
      .then((data) => (this.homeSingleton = data))

    fetch("https://content.marisolmalibu.com/api/singletons/get/zero1")
      .then((data) => data.json())
      .then((data) => (this.zero1Singleton = data))
      .then((this.render = true))
      .catch((error) => console.log(error.message));
  },
  mounted() {

    this.$gtag.pageview({ page_path: '/zero1' ,page_name: 'Zero 1'})
    var aux = this;
    setInterval(() => {
      aux.cover = !aux.cover;
    }, 4000);

    window.addEventListener("scroll", this.onScrollCount, { passive: true });

    this.intervalSlider1 = setInterval(() => {
      if (this.permitirNumero) {
        this.numero += 15;
        this.numero1 += 444;
        this.numero2 += 11;
        this.numero3 += 1;
        this.numero5 += 6;
      }
    }, 90);

    this.intervalSlider2 = setInterval(() => {
      if (this.showGraphics) {
        this.numbersGraphics1 += 28253;
        this.numbersGraphics2 += 20235;
      } else {
        this.numbersGraphics1 = 0;
        this.numbersGraphics2 = 0;
      }
    }, 90);

    this.$emit("update", 1);

    if(this.$route.query.graph == '1'){
      setTimeout(() => {
        this.$vuetify.goTo('#numbersLeft')
      }, 2000);
    }

    if(this.$route.query.graph == 'a'){
      setTimeout(() => {
        this.$vuetify.goTo('#numbersLeft')
      }, 2000);
      setTimeout(() => {
        this.showGraphics = true
        this.final = false
        this.executeAnimationGraphics()
      }, 2500);

    }

    if(this.$route.query.graph == 'b'){
      setTimeout(() => {
        this.$vuetify.goTo('#numbersLeft')
      }, 2000);
      setTimeout(() => {
        this.showGraphics2 = true
        this.final = false
        this.executeAnimationGraphics()
      }, 2500);
    }

    console.log(this.$route.query)
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScrollCount);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    redirectToZillow: function () {
      this.snackbarZillow = true;
      setTimeout(() => {
        window.open('https://www.zillow.com/homedetails/11865-Ellice-St-Malibu-CA-90265/121442315_zpid/', '_blank');
      }, 2000);
    },
    owlCarN: function () {
      // Create our event (with options)
      var evt = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      });

      var elem = document.querySelector("#nextOwl");

      elem.dispatchEvent(evt);
      // elem.dispatchEvent(evt);
      // if(this.dotsOwl == 1){

      //   var elem = document.querySelector('#nextOwl');

      //   elem.dispatchEvent(evt);
      //   elem.dispatchEvent(evt);
      // }

      // if(this.dotsOwl == 2){

      //   var elem2 = document.querySelector('#nextOwl');
      //   elem2.dispatchEvent(evt);
      // }
    },
    owlCarN2: function () {
      var evt = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      });

      var elem = document.querySelector("#nextOwl");

      elem.dispatchEvent(evt);
    },
    owlCarN3: function () {
      var evt = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      });

      var elem = document.querySelector("#nextOwl");

      elem.dispatchEvent(evt);
    },
    commasNumber: function (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    executeAnimationGraphics: function () {
      this.state = 0;

      this.value1 = 0;
      setTimeout(() => {
        // this.value1 = 50;
        this.state = 2;
      }, 100);

      // this.value2 = 0;
      // setTimeout(() => {
      //   this.value2 = 40;
      //   this.state = 0;
      // }, 1000);

      // setTimeout(() => {
      //   if (this.value2 === 40) {
      //     this.value2 = 13;
      //     this.state = 2;
      //   }
      // }, 3000);

      // setTimeout(() => {
      //   if (this.value2 === 13) {
      //     this.final = true;
      //   }
      // }, 4000);
    },
    onScrollCount: function () {
      var numbersLeft = document.getElementById("numbersLeft").offsetTop;
      var offset = window.pageYOffset;

      if (offset + this.$vuetify.breakpoint.height < numbersLeft) {
        this.numero = 0;
        this.numero1 = 0;
        this.numero2 = 0;
        this.numero3 = 0;
        this.numero5 = 0;
        this.numbers1 = 0;
        this.permitirNumero = false;
      } else {
        this.permitirNumero = true;
      }

      var parallax = document.querySelector(".parallax");
      var parallaxContainer = document.querySelector(".parallax-container");
      var rowParallax = document.querySelector(".row-parallax").offsetTop;
      var scrollTop = document.documentElement.scrollTop;
      var rowPosition = rowParallax - scrollTop;

      if (rowPosition > 0) {
        if (rowPosition < this.$vuetify.breakpoint.height) {
          var porcentaje =
            (this.$vuetify.breakpoint.height - rowPosition) /
            this.$vuetify.breakpoint.height;
          porcentaje = porcentaje * 0.5;
          porcentaje = 1.5 - porcentaje;
          parallax.style.transform = "scale(" + porcentaje + ")";

          var porcentaje2 =
            (this.$vuetify.breakpoint.height - rowPosition) /
            this.$vuetify.breakpoint.height;

          if (this.$vuetify.breakpoint.width < 599) {
            porcentaje2 = 15 * porcentaje2;
          } else {
            porcentaje2 = 50 * porcentaje2;
          }

          if (rowPosition < this.$vuetify.breakpoint.height) {
            parallaxContainer.style.marginLeft = porcentaje2 + "px";
            parallaxContainer.style.marginRight = porcentaje2 + "px";
          } else {
            parallaxContainer.style.marginLeft = "0px";
            parallaxContainer.style.marginRight = "0px";
          }
        }
      }
    },
    changedOwlCarousel: function (e) {
      // console.log(e.item.index)
      this.dotsOwl = e.item.index;
    },
    changedOwlCarousel2: function (e) {
      // console.log(e.item.index);
      this.dotsOwl2 = e.item.index;
    },
    prevCustom: function() {
      this.carouselOneWithMalibu--

      if (this.carouselOneWithMalibu < 0) {
        this.carouselOneWithMalibu = this.zero1Singleton.carousel_one_with_malibu.length - 1
      }
    }
  },
};
</script>


<style lang="scss">
  @media screen and (min-width: 960px) and (max-width: 1263px){
    .crystal-title-text{
      transform: translateY(-150px);
    }
  }

  @media screen and (min-width: 1264px){
    .crystal-title-text{
      transform: translateY(-200px);
    }
  }

  .row-parallax{
    &.property{
      margin-top: 100px !important;
    }
  }
</style>