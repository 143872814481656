<template>
  <!-- Generator: Adobe Illustrator 23.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    version="1.1"
    id="capa1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1310 1126.8"
    style="enable-background: new 0 0 1310 1126.8"
    xml:space="preserve"
  >
    <image
      style="overflow: visible"
      width="1506"
      height="814"
      xlink:href="@/assets/img/Property/House_Drawing_Placeholder.png"
      transform="matrix(0.8682 0 0 0.8682 1.2541 252.1842)"
    ></image>
	<g id="punto" class="withClass" v-bind:class="{ opac: show }">
      <g id="_x39_9_punto">
        <circle class="st2----A" cx="1073.9" cy="416.8" r="64.5" fill="transparent"/>
      </g>
    </g>
    <g id="_x33_00_feet_arrow" class="withClass" v-bind:class="{ opac: !show }">
      <g>
        <g>
          <path
            class="qt3"
            d="M1078.8,810.7c0,0-2.1-0.3-2.2,1.4c-0.1,1.7,4.4,6.6,4.4,6.6s6.3,12.4,6.7,14s4.7,9.5,8.6,9.5
				c3.9,0,6.7-6.8,6.7-6.8s5.9-14.6,6.1-16.4c0.3-1.8,3.5-15.6,4.4-17.5c0.9-1.8,3-8,3.8-8.8s-1-3.1-2.1-1.6
				c-1,1.6-6.6,13.7-9.5,25.3s-6.2,18.8-7.3,20.1c-1.1,1.2-2,2.3-3.9,1s-5.6-10.1-5.8-10.4C1088.5,826.8,1082,810.8,1078.8,810.7z"
          />
          <path
            class="qt3"
            d="M1079.3,508l-2.4,3.1l-1.7,3l-1.6,2.8l-3.8,7l-1.9,3.7l-1.6,2.8c0,0-0.3,0.3,0,1.9c0.3,1.6,0.2,3.1,1.6,2.4
				s2.4-4,2.4-4l2.5-4.7c0,0,2.4-5.8,2.8-6.3c0.3-0.5,2.4-3.7,2.4-3.7l0.5-1.2l-0.3,13.5l1.2,19.6v19.6l0.9,8l0.8,12.7l1,20.8
				l1.4,25.3l0.9,28.5l1,10.2v41.4l0.7,13.5l0.7-1.9l0.9,13l1.9,55.6l1.9,14.7c0,0,0.7,19,0,21c-0.7,1.9,1,1.6,1.6,0.7
				c0.5-0.9,1-18.7,1.2-22.4c0.2-3.7-2.4-31.7-2.4-31.7l-1.7-33.8l-1-28.7l-0.7-40.4l-1.2-28.2l-2.3-46.3l-1.7-24.2l-1.9-44
				c0,0-0.5-16.6,0.2-16.6c0.7,0,2.4,4.5,2.4,4.5s2.1,8.9,3.1,11.4c1,2.4,4.4,8,4.4,9.8s0.2,4.9,1.4,4.2c1.2-0.7,1.2-3.8-0.5-9.8
				c-1.7-6-9.1-25.3-9.1-25.3s-2.2-4.7-2.9-3.5C1079.7,507.2,1079.3,508,1079.3,508z"
          />
        </g>
      </g>
    </g>
    <g id="_x33_00_feet_1_" class="withClass" v-bind:class="{ opac: !show }">
      <path
        class="qt3"
        d="M876.9,575.9c0.8-0.1,1.7-0.2,2.5-0.6c0.8-0.4,1.6-0.7,2.2-1.2c0.7-0.6,1.2-1.1,1.7-1.9
		c0.5-0.7,0.7-1.7,0.7-2.5c0-1.4-0.5-2.6-1.2-3.5c-0.8-0.8-2-1.3-3.4-1.3c-1.1,0-2,0.2-2.7,0.7c-0.7,0.6-1.6,1.2-2.2,2l-0.6-0.7
		c0.6-0.7,1.1-1.2,1.7-1.8s1.1-1,1.8-1.4c0.6-0.4,1.2-0.7,2-0.8c0.7-0.2,1.6-0.4,2.4-0.4c0.7,0,1.6,0.1,2.2,0.5s1.3,0.7,1.9,1.2
		c0.6,0.5,0.8,1.1,1.2,1.8c0.4,0.7,0.5,1.4,0.5,2.3c0,0.9-0.1,1.7-0.5,2.3c-0.4,0.7-0.7,1.4-1.3,2c-0.6,0.7-1.1,1.1-1.9,1.7
		c-0.7,0.5-1.4,0.8-2.2,1.1l0.1,0.1c0.6,0,1.2,0,2,0.1s1.7,0.5,2.4,0.8c0.7,0.5,1.4,1.1,2,2s0.7,2,0.7,3.4c0,1-0.2,2-0.7,3.1
		c-0.5,1.1-1.1,2.2-1.9,3.1c-0.8,1-2,1.8-3.3,2.4s-3,1-4.9,1c-0.8,0-1.8-0.1-2.5-0.4c-0.7-0.2-1.4-0.6-1.9-0.8
		c-0.6-0.4-0.8-0.7-1.2-1.1c-0.4-0.5-0.5-0.7-0.5-1.1c0-0.4,0.1-0.7,0.5-0.8c0.4-0.2,0.6-0.4,0.8-0.4c0.4,0,0.7,0.2,0.8,0.6
		s0.6,0.7,0.8,1.2c0.3,0.5,0.7,0.7,1.4,1.2c0.6,0.4,1.4,0.6,2.4,0.6s1.9-0.2,2.6-0.6c0.7-0.4,1.4-0.7,2-1.4c0.6-0.6,1-1.3,1.2-2
		c0.4-0.7,0.5-1.7,0.5-2.6c0-1.3-0.2-2.3-0.7-3.2c-0.5-0.8-1-1.6-1.7-2c-0.7-0.5-1.6-0.7-2.5-1c-1-0.1-2-0.2-3.2-0.1v-1.3
		L876.9,575.9L876.9,575.9z"
      />
      <path
        class="qt3"
        d="M911.9,575.9c0,2.9-0.4,5.1-1,7.2c-0.7,2-1.4,3.5-2.3,4.7c-0.8,1.2-2,2-3.1,2.5c-1.1,0.6-2,0.7-3.1,0.7
		c-1.4,0-2.7-0.5-3.8-1.2c-1.1-0.7-2-1.9-2.7-3.2c-0.7-1.3-1.2-2.7-1.7-4.4c-0.4-1.7-0.6-3.2-0.6-4.8c0-2.9,0.4-5.1,1-7.2
		c0.7-2,1.4-3.5,2.3-4.7c0.9-1.2,2-2,3.1-2.5c1.1-0.6,2-0.7,3.1-0.7c1.4,0,2.7,0.5,3.8,1.2c1.1,0.8,2,1.9,2.7,3.2s1.2,2.7,1.7,4.4
		C911.7,572.7,911.9,574.3,911.9,575.9z M908.2,575.8c0-1.1-0.1-2.4-0.2-3.8c-0.1-1.4-0.5-2.7-0.7-3.9c-0.5-1.2-1-2.2-1.7-3.1
		c-0.7-0.8-1.6-1.2-2.6-1.2c-1,0-1.8,0.4-2.4,0.8c-0.7,0.6-1.2,1.3-1.7,2.2c-0.5,0.9-0.7,1.9-1,3c-0.2,1.1-0.5,2-0.5,3.2
		c-0.1,1-0.1,2-0.1,2.9c0,0.8,0,1.6,0,2c0,1.2,0.1,2.4,0.2,3.8c0.1,1.4,0.5,2.7,0.7,3.9c0.4,1.2,1,2.2,1.7,3.1
		c0.7,0.8,1.6,1.2,2.6,1.2s1.8-0.4,2.4-0.8c0.7-0.6,1.2-1.3,1.7-2.2c0.5-0.8,0.7-1.9,1-3c0.2-1.1,0.5-2,0.5-3.2c0.1-1,0.1-2,0.1-2.9
		C908.2,576.9,908.2,576.2,908.2,575.8z"
      />
      <path
        class="qt3"
        d="M934,575.9c0,2.9-0.4,5.1-1,7.2c-0.7,2-1.4,3.5-2.3,4.7c-0.8,1.2-2,2-3.1,2.5c-1.1,0.5-2,0.7-3.1,0.7
		c-1.4,0-2.7-0.5-3.8-1.2c-1.1-0.7-2-1.9-2.7-3.2c-0.7-1.3-1.2-2.7-1.7-4.4c-0.4-1.7-0.6-3.2-0.6-4.8c0-2.9,0.4-5.1,1-7.2
		c0.7-2,1.4-3.5,2.3-4.7c0.9-1.2,2-2,3.1-2.5c1.1-0.6,2-0.7,3.1-0.7c1.4,0,2.7,0.5,3.8,1.2c1.1,0.8,2,1.9,2.7,3.2
		c0.7,1.3,1.2,2.7,1.7,4.4C933.9,572.7,934,574.3,934,575.9z M930.3,575.8c0-1.1-0.1-2.4-0.2-3.8c-0.1-1.4-0.5-2.7-0.7-3.9
		c-0.5-1.2-1-2.2-1.7-3.1c-0.7-0.8-1.6-1.2-2.6-1.2c-1,0-1.8,0.4-2.4,0.8c-0.7,0.6-1.2,1.3-1.7,2.2c-0.5,0.9-0.7,1.9-1,3
		c-0.2,1.1-0.5,2-0.5,3.2c-0.1,1-0.1,2-0.1,2.9c0,0.8,0,1.6,0,2c0,1.2,0.1,2.4,0.2,3.8c0.1,1.4,0.5,2.7,0.7,3.9
		c0.4,1.2,1,2.2,1.7,3.1c0.7,0.8,1.6,1.2,2.6,1.2c1,0,1.8-0.4,2.4-0.8c0.7-0.6,1.2-1.3,1.7-2.2c0.5-0.8,0.7-1.9,1-3
		c0.2-1.1,0.4-2,0.5-3.2c0.1-1.1,0.1-2,0.1-2.9C930.3,576.9,930.3,576.2,930.3,575.8z"
      />
      <path
        class="qt3"
        d="M959.9,572.9v2.2h-5v13.5c0,0.5,0.2,0.7,0.6,0.8c0.5,0.1,0.7,0.1,1.3,0.1h1.8v1c-0.8,0-1.7,0-2.5-0.1
		c-0.8,0-1.7,0-2.5,0c-0.8,0-1.7,0-2.4,0c-0.8,0-1.7,0-2.4,0.1v-1h1.6c0.7,0,1.2-0.1,1.4-0.5c0.2-0.4,0.4-0.6,0.4-0.8v-13.1h-2.7
		v-0.7c0.2-0.1,0.6-0.4,0.8-0.5c0.4-0.1,0.7-0.4,0.8-0.6c0.4-0.2,0.6-0.5,0.7-0.7c0.2-0.2,0.4-0.6,0.4-0.8c0-0.7,0-1.3,0.1-2
		c0.1-0.7,0.4-1.6,0.6-2.4c0.2-0.7,0.6-1.7,1-2.4c0.5-0.7,0.8-1.6,1.6-2c1.1-1.1,2-1.9,3.1-2.2c0.8-0.4,1.6-0.6,1.8-0.6
		c0.1,0,0.4,0,0.6,0c0.2,0,0.5,0.1,0.6,0.2c0.2,0.1,0.4,0.4,0.5,0.6c0.1,0.2,0.2,0.6,0.2,0.8c0,0.5-0.1,0.8-0.4,1.1
		c-0.2,0.4-0.6,0.5-1.1,0.5c-0.4,0-0.6,0-0.7-0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.4-0.1-0.6-0.2c-0.2,0-0.5-0.1-0.7-0.1
		c-0.7,0-1.1,0.1-1.4,0.5c-0.4,0.4-0.7,0.7-0.7,1.2c-0.2,0.5-0.4,1-0.4,1.4c0,0.6-0.1,1-0.1,1.4v5.7L959.9,572.9L959.9,572.9z"
      />
      <path
        class="qt3"
        d="M964.9,579.5c0,0.2-0.1,0.5-0.1,0.7s0,0.8,0.1,1.6c0,1,0.2,1.9,0.6,2.6c0.4,0.8,0.7,1.6,1.2,2.2
		c0.5,0.7,1.1,1.1,1.9,1.4c0.7,0.4,1.4,0.6,2.2,0.6c1.1,0,2-0.2,3-0.7c0.8-0.6,1.7-1.1,2.4-1.9l0.6,0.6c-0.5,0.7-1,1.2-1.4,1.8
		c-0.6,0.6-1.1,1-1.7,1.4c-0.7,0.5-1.2,0.7-2,1c-0.7,0.2-1.4,0.4-2.3,0.4c-1.7,0-2.9-0.4-3.8-1c-1-0.7-1.8-1.4-2.3-2.4
		c-0.6-0.8-1-1.9-1.1-2.9c-0.2-1-0.4-1.8-0.4-2.4c0-2,0.4-3.5,0.8-4.8s1.3-2.3,2-3.1c0.8-0.7,1.8-1.3,2.6-1.7
		c0.8-0.4,1.8-0.5,2.4-0.5c0.7,0,1.7,0.1,2.4,0.4c0.7,0.2,1.6,0.7,2.2,1.2c0.7,0.6,1.2,1.2,1.6,2c0.5,0.7,0.7,1.8,0.7,2.7
		c0,0.1,0,0.2,0,0.4s0,0.2,0,0.4l-0.4,0.2L964.9,579.5L964.9,579.5z M971.9,578.2c0.6,0,0.7-0.1,1-0.4c0.1-0.2,0.2-0.5,0.2-0.7
		c0-1-0.4-1.9-0.8-2.6c-0.6-0.7-1.4-1.1-2.5-1.1c-1.2,0-2.2,0.5-3.1,1.2c-0.8,0.7-1.3,2-1.6,3.4L971.9,578.2L971.9,578.2
		L971.9,578.2z"
      />
      <path
        class="qt3"
        d="M982.6,579.5c0,0.2-0.1,0.5-0.1,0.7s0,0.8,0.1,1.6c0,1,0.2,1.9,0.6,2.6c0.4,0.7,0.7,1.6,1.2,2.2
		c0.6,0.7,1.1,1.1,1.9,1.4c0.7,0.4,1.4,0.6,2.2,0.6c1.1,0,2-0.2,3-0.7c0.8-0.6,1.7-1.1,2.4-1.9l0.6,0.6c-0.5,0.7-1,1.2-1.4,1.8
		c-0.6,0.6-1.1,1-1.7,1.4c-0.7,0.5-1.2,0.7-2,1c-0.7,0.2-1.4,0.4-2.3,0.4c-1.7,0-2.9-0.4-3.8-1c-1-0.7-1.8-1.4-2.3-2.4
		c-0.6-0.8-1-1.9-1.1-2.9c-0.2-1-0.4-1.8-0.4-2.4c0-2,0.4-3.5,0.8-4.8s1.3-2.3,2-3.1c0.8-0.7,1.8-1.3,2.6-1.7
		c0.8-0.4,1.8-0.5,2.4-0.5c0.7,0,1.7,0.1,2.4,0.4c0.7,0.2,1.6,0.7,2.2,1.2c0.7,0.6,1.2,1.2,1.6,2c0.5,0.7,0.7,1.8,0.7,2.7
		c0,0.1,0,0.2,0,0.4s0,0.2,0,0.4l-0.4,0.2L982.6,579.5L982.6,579.5z M989.6,578.2c0.6,0,0.7-0.1,1-0.4c0.1-0.2,0.2-0.5,0.2-0.7
		c0-1-0.4-1.9-0.8-2.6c-0.6-0.7-1.4-1.1-2.5-1.1c-1.2,0-2.2,0.5-3.1,1.2c-0.7,0.8-1.3,2-1.6,3.4L989.6,578.2L989.6,578.2
		L989.6,578.2z"
      />
      <path
        class="qt3"
        d="M1002.3,585.6c0,0.7,0.1,1.2,0.2,1.7c0.1,0.5,0.4,0.7,0.7,1c0.2,0.2,0.6,0.5,0.7,0.6c0.4,0.1,0.6,0.1,0.8,0.1
		c0.6,0,1-0.1,1.4-0.4c0.4-0.3,0.8-0.5,1.3-0.8l0.6,0.6c-0.7,0.7-1.4,1.3-2.3,1.9c-0.8,0.6-1.9,0.7-3,0.7c-1.3,0-2.2-0.4-2.9-1
		c-0.7-0.7-1-1.6-1-2.7v-12.1h-2.6v-0.7c0.6-0.5,1-0.7,1.4-1.1c0.5-0.4,0.8-0.7,1.3-1.1c0.5-0.5,0.8-0.8,1.2-1.4
		c0.5-0.6,0.8-1.2,1.4-2h0.5v4.1h6v2.2h-6v10.5L1002.3,585.6L1002.3,585.6z"
      />
      <path
        class="qt3"
        d="M1026.6,585.6c0,0.7,0.1,1.2,0.2,1.7c0.1,0.5,0.4,0.7,0.7,1c0.3,0.3,0.6,0.5,0.7,0.6c0.4,0.1,0.6,0.1,0.8,0.1
		c0.6,0,1-0.1,1.4-0.4c0.5-0.2,0.8-0.5,1.3-0.8l0.6,0.6c-0.7,0.7-1.4,1.3-2.3,1.9c-0.8,0.6-1.9,0.7-3,0.7c-1.3,0-2.2-0.4-2.9-1
		c-0.7-0.7-1-1.6-1-2.7v-12.1h-2.6v-0.7c0.6-0.5,1-0.7,1.4-1.1c0.4-0.4,0.8-0.7,1.3-1.1c0.5-0.5,0.8-0.8,1.2-1.4
		c0.4-0.6,0.8-1.2,1.4-2h0.5v4.1h6v2.2h-5.9v10.4H1026.6z"
      />
      <path
        class="qt3"
        d="M1035.1,581.7c0-1.3,0.2-2.5,0.7-3.6c0.5-1.2,1.1-2.2,1.9-3.1c0.7-0.8,1.8-1.6,2.9-2c1.1-0.6,2.3-0.7,3.6-0.7
		c1.4,0,2.6,0.2,3.8,0.7c1.2,0.5,2.2,1.1,3.1,1.9c0.8,0.7,1.6,1.8,2,3c0.5,1.2,0.7,2.4,0.7,3.8c0,1.3-0.2,2.5-0.7,3.6
		c-0.5,1.2-1.1,2.2-2,3.1s-1.8,1.6-2.9,2c-1.1,0.6-2.3,0.7-3.6,0.7s-2.5-0.2-3.7-0.7s-2.2-1.1-3.1-1.9c-0.8-0.7-1.6-1.8-2-3
		C1035.2,584.4,1035.1,583.2,1035.1,581.7z M1038.6,581.4c0,0.8,0.1,1.8,0.4,2.7c0.3,0.9,0.6,2,1.1,2.9c0.5,0.8,1.1,1.7,1.9,2.2
		c0.7,0.6,1.7,0.8,2.6,0.8s1.9-0.2,2.5-0.7c0.7-0.6,1.3-1.2,1.8-2c0.5-0.7,0.7-1.7,1-2.6c0.3-0.9,0.4-1.9,0.4-2.6
		c0-0.8-0.1-1.8-0.4-2.7c-0.2-1-0.6-2-1.1-2.9c-0.6-0.8-1.1-1.7-1.9-2.2c-0.7-0.6-1.7-0.8-2.7-0.8s-1.9,0.2-2.5,0.7
		c-0.7,0.6-1.3,1.2-1.8,2c-0.5,0.7-0.7,1.7-1,2.6C1038.6,579.7,1038.6,580.5,1038.6,581.4z"
      />
      <path
        class="qt3"
        d="M876.3,629c0,0.7,0.1,1.2,0.2,1.7c0.1,0.5,0.4,0.7,0.7,1c0.2,0.2,0.6,0.5,0.7,0.6c0.4,0.1,0.6,0.1,0.8,0.1
		c0.6,0,1-0.1,1.4-0.4c0.5-0.2,0.8-0.5,1.3-0.8l0.6,0.6c-0.7,0.7-1.4,1.3-2.3,1.9c-0.8,0.6-1.9,0.7-3,0.7c-1.3,0-2.2-0.4-2.9-1
		s-1-1.6-1-2.7v-12.3h-2.6v-0.5c0.6-0.5,1-0.7,1.4-1.1c0.5-0.4,0.8-0.7,1.3-1.1c0.5-0.5,0.8-0.8,1.2-1.4c0.5-0.6,0.8-1.2,1.4-2h0.5
		v4.1h6v2.2H876V629H876.3z"
      />
      <path
        class="qt3"
        d="M886.7,609.4c0-0.7,0-1.1-0.1-1.6c-0.1-0.5-0.2-0.7-0.4-0.8s-0.5-0.4-0.7-0.5c-0.2-0.1-0.6-0.2-1-0.4v-0.7
		c0.7-0.2,1.6-0.6,2.3-0.8c0.7-0.4,1.3-0.6,1.8-0.8c0.2-0.1,0.5-0.2,0.6-0.4c0.1-0.2,0.2-0.1,0.4-0.1s0.4,0.2,0.4,0.6v15.2
		c0.1-0.1,0.4-0.4,0.7-0.7c0.3-0.3,0.7-0.7,1.3-1.2s1.2-0.7,1.9-1.1c0.7-0.4,1.4-0.6,2.3-0.6c0.9,0,1.7,0.1,2.3,0.5
		c0.7,0.4,1.2,0.7,1.7,1.2c0.5,0.6,0.7,1.2,1,1.9c0.2,0.7,0.4,1.6,0.4,2.4v9.9c0,0.7,0.2,1.1,0.6,1.2c0.4,0.1,0.8,0.1,1.4,0.1h0.7v1
		c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2.2,0s-1.4,0-2.2,0s-1.4,0-2.2,0.1v-1h0.7c0.5,0,0.8,0,1.1-0.1c0.2-0.1,0.5-0.2,0.6-0.6
		c0.1-0.4,0.2-0.7,0.2-1.3c0-0.6,0-1.3,0-2.3V624c0-0.7,0-1.4-0.1-2.2c-0.1-0.8-0.2-1.3-0.6-1.9c-0.2-0.6-0.7-1-1.2-1.3
		c-0.6-0.4-1.2-0.6-2-0.6c-0.5,0-0.8,0.1-1.3,0.2c-0.5,0.1-0.8,0.4-1.2,0.6c-0.4,0.2-0.7,0.5-1,0.7c-0.4,0.2-0.6,0.5-0.7,0.7v11.2
		c0,0.7,0.2,1.1,0.6,1.2c0.5,0.1,0.8,0.1,1.4,0.1h0.7v1c-0.7,0-1.4,0-2,0s-1.4,0-2,0c-0.7,0-1.4,0-2.2,0s-1.4,0-2.2,0.1v-1h0.7
		c0.6,0,1,0,1.4-0.1s0.6-0.5,0.6-1.2L886.7,609.4L886.7,609.4z"
      />
      <path
        class="qt3"
        d="M909.6,622.9c0,0.2-0.1,0.5-0.1,0.7c0,0.3,0,0.8,0.1,1.6c0,1,0.2,1.9,0.6,2.6c0.4,0.7,0.7,1.6,1.2,2.2
		c0.6,0.7,1.1,1.1,1.9,1.4c0.7,0.4,1.4,0.6,2.2,0.6c1.1,0,2-0.2,3-0.7c0.9-0.6,1.7-1.1,2.4-1.9l0.6,0.6c-0.5,0.7-1,1.2-1.4,1.8
		c-0.6,0.6-1.1,1-1.7,1.4c-0.7,0.5-1.2,0.7-2,1c-0.7,0.3-1.4,0.4-2.3,0.4c-1.7,0-2.9-0.4-3.8-1c-0.9-0.7-1.8-1.4-2.3-2.4
		c-0.6-0.8-1-1.9-1.1-2.9c-0.2-1-0.4-1.8-0.4-2.4c0-2,0.4-3.5,0.8-4.8s1.3-2.3,2-3.1c0.8-0.7,1.8-1.3,2.6-1.7
		c0.8-0.4,1.8-0.5,2.4-0.5c0.7,0,1.7,0.1,2.4,0.4c0.7,0.2,1.6,0.7,2.2,1.2c0.7,0.6,1.2,1.2,1.6,2c0.5,0.7,0.7,1.8,0.7,2.7
		c0,0.1,0,0.2,0,0.4s0,0.2,0,0.4l-0.4,0.2L909.6,622.9L909.6,622.9z M916.7,621.6c0.6,0,0.7-0.1,1-0.4c0.1-0.2,0.2-0.5,0.2-0.7
		c0-1-0.4-1.9-0.8-2.6c-0.6-0.7-1.4-1.1-2.5-1.1c-1.2,0-2.2,0.5-3.1,1.2c-0.7,0.8-1.3,2-1.6,3.4L916.7,621.6L916.7,621.6
		L916.7,621.6z"
      />
      <path
        class="qt3"
        d="M940.8,618.6c0.5-0.4,0.8-0.7,1.3-1s1-0.7,1.4-0.8c0.6-0.4,1-0.6,1.6-0.7c0.6-0.2,1.1-0.2,1.7-0.2
		c1.1,0,2.2,0.2,3.1,0.7c0.8,0.5,1.7,1.1,2.3,1.9c0.7,0.7,1.1,1.7,1.4,2.6c0.3,0.9,0.6,2,0.6,3.2c0,1.3-0.2,2.5-0.7,3.8
		c-0.5,1.2-1.1,2.4-2,3.3c-0.8,1-1.9,1.8-3,2.4s-2.4,0.8-3.9,0.8c-0.7,0-1.4,0-2-0.1c-0.6-0.1-1.1-0.2-1.6-0.4
		c-0.5-0.1-0.8-0.4-1.2-0.5c-0.4-0.1-0.7-0.4-0.8-0.5l-1.2,0.8l-0.8-0.5c0.2-1.2,0.4-2.4,0.5-3.5c0.1-1.2,0.1-2.3,0.1-3.5v-16.8
		c0-0.7,0-1.1-0.1-1.6c-0.1-0.5-0.2-0.7-0.4-0.8c-0.1-0.2-0.5-0.4-0.7-0.5c-0.2-0.1-0.6-0.2-1-0.4v-0.7c0.7-0.2,1.6-0.6,2.3-0.8
		c0.7-0.4,1.3-0.6,1.8-0.8c0.2-0.1,0.5-0.2,0.6-0.4c0.1-0.1,0.2-0.1,0.4-0.1s0.4,0.2,0.4,0.6V618.6L940.8,618.6L940.8,618.6z
		M940.8,629.3c0,0.4,0.1,0.7,0.2,1.3c0.2,0.5,0.5,0.8,0.7,1.3c0.4,0.5,0.7,0.7,1.3,1c0.6,0.4,1.1,0.5,1.8,0.5c1.1,0,2-0.2,2.7-0.7
		c0.7-0.5,1.4-1.1,1.9-1.9s0.8-1.6,1.1-2.5c0.2-0.8,0.4-1.9,0.4-2.6c0-1.1-0.2-2-0.6-3.1c-0.4-1-0.8-1.8-1.4-2.5
		c-0.6-0.7-1.3-1.3-2-1.8s-1.6-0.7-2.3-0.7c-0.7,0-1.4,0.2-2,0.6c-0.7,0.5-1.2,0.8-1.8,1.3L940.8,629.3L940.8,629.3z"
      />
      <path
        class="qt3"
        d="M960.4,622.9c0,0.2-0.1,0.5-0.1,0.7c0,0.3,0,0.8,0.1,1.6c0,1,0.2,1.9,0.6,2.6c0.4,0.8,0.7,1.6,1.2,2.2
		c0.6,0.7,1.1,1.1,1.9,1.4c0.7,0.4,1.4,0.6,2.2,0.6c1.1,0,2-0.2,3-0.7c0.9-0.6,1.7-1.1,2.4-1.9l0.6,0.6c-0.5,0.7-1,1.2-1.4,1.8
		c-0.6,0.6-1.1,1-1.7,1.4c-0.7,0.5-1.2,0.7-2,1c-0.7,0.2-1.4,0.4-2.3,0.4c-1.7,0-2.9-0.4-3.8-1c-1-0.7-1.8-1.4-2.3-2.4
		c-0.6-0.8-1-1.9-1.1-2.9c-0.2-1-0.4-1.8-0.4-2.4c0-2,0.4-3.5,0.8-4.8c0.5-1.3,1.3-2.3,2-3.1c0.8-0.7,1.8-1.3,2.6-1.7
		c0.8-0.4,1.8-0.5,2.4-0.5c0.7,0,1.7,0.1,2.4,0.4c0.7,0.2,1.6,0.7,2.2,1.2c0.7,0.6,1.2,1.2,1.6,2c0.5,0.7,0.7,1.8,0.7,2.7
		c0,0.1,0,0.2,0,0.4s0,0.2,0,0.4l-0.4,0.2L960.4,622.9L960.4,622.9z M967.5,621.6c0.6,0,0.7-0.1,1-0.4c0.1-0.2,0.2-0.5,0.2-0.7
		c0-1-0.4-1.9-0.8-2.6c-0.6-0.7-1.4-1.1-2.5-1.1c-1.2,0-2.2,0.5-3.1,1.2c-0.7,0.8-1.3,2-1.6,3.4L967.5,621.6L967.5,621.6
		L967.5,621.6z"
      />
      <path
        class="qt3"
        d="M992.1,632.3c-0.6,0.6-1.2,1-1.8,1.4c-0.7,0.5-1.3,0.7-2.2,0.7c-0.8,0-1.4-0.4-1.9-1c-0.5-0.7-0.7-1.3-0.7-2
		c-0.8,0.8-1.9,1.6-2.9,2c-1,0.6-2,0.8-3.3,0.8c-1.3,0-2.3-0.4-3.1-1.1c-0.7-0.7-1.2-1.7-1.2-2.7c0-0.7,0.2-1.4,0.5-2
		c0.4-0.6,0.7-1,1.6-1.4c0.7-0.5,1.8-1,3.1-1.4c1.3-0.4,3.1-1.1,5.3-1.9v-2.6c0-2.3-1.3-3.4-3.8-3.4c-0.8,0-1.6,0.2-2,0.7
		c-0.6,0.6-0.8,1.2-0.8,2c0,0.6-0.2,1-0.7,1.2c-0.6,0.4-1,0.5-1.4,0.5s-0.5-0.1-0.6-0.4c-0.1-0.3-0.1-0.4-0.1-0.6
		c0-0.6,0.1-1,0.4-1.4c0.2-0.5,0.6-0.7,0.8-1.1c0.3-0.4,0.8-0.7,1.4-1c0.6-0.4,1.2-0.7,1.9-1c0.4-0.2,0.7-0.4,1-0.6s0.6-0.4,0.7-0.5
		c0.2-0.1,0.6-0.2,0.8-0.4c0.4-0.1,0.7-0.1,1-0.1c0.5,0,1,0.1,1.6,0.2c0.6,0.1,1.1,0.4,1.7,0.7c0.6,0.4,0.8,0.7,1.2,1
		c0.4,0.5,0.6,0.8,0.6,1.4v11c0,0.8,0.1,1.4,0.4,1.8c0.2,0.4,0.7,0.6,1.1,0.6c0.2,0,0.6-0.1,0.7-0.2c0.2-0.1,0.5-0.4,0.7-0.6V632.3z
		M985.7,625c-0.6,0.2-1.2,0.5-2,0.7c-0.7,0.2-1.7,0.6-2.4,0.8c-0.7,0.4-1.4,0.7-2,1.3c-0.6,0.6-0.8,1.1-0.8,1.9
		c0,0.7,0.4,1.4,0.8,2c0.5,0.6,1.2,0.8,2,0.8c0.8,0,1.8-0.2,2.4-0.7c0.7-0.5,1.3-1,2-1.7V625z"
      />
      <path
        class="qt3"
        d="M1009.8,618.3c0,0.5-0.1,0.8-0.5,1.2c-0.2,0.4-0.7,0.6-1.1,0.6c-0.5,0-0.7-0.2-1-0.6c-0.2-0.4-0.6-0.7-0.8-1.1
		c-0.4-0.5-0.7-0.7-1.3-1.1c-0.6-0.4-1.2-0.6-2.2-0.6c-0.8,0-1.7,0.2-2.3,0.7s-1.2,1-1.7,1.7c-0.5,0.7-0.7,1.4-1,2.2
		c-0.2,0.7-0.4,1.6-0.4,2.3c0,1,0.1,2,0.5,3c0.4,0.8,0.7,1.8,1.4,2.4c0.6,0.7,1.3,1.3,2.2,1.7c0.8,0.5,1.9,0.7,3,0.7
		c1,0,1.9-0.2,2.6-0.6c0.7-0.4,1.4-0.7,2.2-1.4l0.7,0.7c-1,1.1-2,2-3.4,3c-1.3,0.7-2.7,1.2-4.4,1.2c-1,0-2-0.2-3-0.7
		c-1-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.7-1.8-2.6c-0.5-0.9-0.7-2-0.7-3.1c0-1.4,0.2-2.7,0.7-4.1c0.5-1.3,1.2-2.4,2-3.4
		c0.8-1,1.9-1.8,3.1-2.3c1.2-0.6,2.5-0.8,3.9-0.8c0.6,0,1.1,0,1.7,0.1c0.7,0.1,1.2,0.2,1.8,0.5c0.6,0.2,1,0.5,1.4,0.8
		S1009.8,618,1009.8,618.3z"
      />
      <path
        class="qt3"
        d="M1014.9,609.4c0-0.7,0-1.1-0.1-1.6c-0.1-0.4-0.2-0.7-0.4-0.8c-0.2-0.2-0.5-0.4-0.7-0.5c-0.2-0.1-0.6-0.2-1-0.4
		v-0.7c0.7-0.2,1.6-0.6,2.3-0.8c0.7-0.4,1.3-0.6,1.8-0.8c0.2-0.1,0.5-0.2,0.6-0.4c0.1-0.2,0.2-0.1,0.4-0.1c0.2,0,0.4,0.2,0.4,0.6
		v15.2c0.1-0.1,0.4-0.4,0.7-0.7c0.4-0.4,0.7-0.7,1.3-1.2c0.6-0.5,1.2-0.7,1.9-1.1c0.7-0.4,1.4-0.6,2.3-0.6c0.8,0,1.7,0.1,2.3,0.5
		c0.7,0.4,1.2,0.7,1.7,1.2s0.7,1.2,1,1.9c0.2,0.7,0.4,1.6,0.4,2.4v9.9c0,0.7,0.2,1.1,0.6,1.2c0.5,0.1,0.8,0.1,1.4,0.1h0.7v1
		c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2.2,0c-0.7,0-1.4,0-2.2,0c-0.7,0-1.4,0-2.2,0.1v-1h0.7c0.5,0,0.8,0,1.1-0.1
		c0.2-0.1,0.5-0.2,0.6-0.6c0.1-0.4,0.2-0.7,0.2-1.3c0-0.6,0-1.3,0-2.3V624c0-0.7,0-1.4-0.1-2.2s-0.2-1.3-0.6-1.9
		c-0.2-0.6-0.7-1-1.2-1.3c-0.6-0.3-1.2-0.6-2-0.6c-0.5,0-0.8,0.1-1.3,0.2c-0.5,0.1-0.8,0.4-1.2,0.6c-0.4,0.2-0.7,0.5-1,0.7
		c-0.4,0.2-0.6,0.5-0.7,0.7v11.2c0,0.7,0.2,1.1,0.6,1.2c0.4,0.1,0.8,0.1,1.4,0.1h0.7v1c-0.7,0-1.4,0-2,0s-1.4,0-2,0s-1.4,0-2.2,0
		c-0.7,0-1.4,0-2.2,0.1v-1h0.7c0.6,0,1,0,1.4-0.1s0.6-0.5,0.6-1.2L1014.9,609.4L1014.9,609.4z"
      />
    </g>
    <g id="_x31_4_tons" class="withClass" v-bind:class="{ opac: !show }">
      <g>
        <path
          class="qt3"
          d="M62.4,128.9c0,0.7,0.2,1.1,0.6,1.3c0.5,0.2,1.1,0.4,2,0.4h1.8v1c-1,0-2,0-3.1,0c-1.1,0-2,0-3.1,0
			c-1.1,0-2.2,0-3.3,0c-1.1,0-2.2,0-3.3,0.1v-1h2.2c1,0,1.7-0.1,2-0.4c0.5-0.2,0.6-0.7,0.6-1.3v-21.9c-0.8,0.6-1.9,1.1-2.9,1.6
			c-1,0.6-2,1-2.9,1.6l-0.5-0.8c1.7-0.8,3.3-1.8,4.8-2.7c1.6-1,3.1-2,4.7-3l0.5,0.1L62.4,128.9L62.4,128.9z"
        />
        <path
          class="qt3"
          d="M72,121.3l13.6-17.8h1.8v16.6h3.9v2.6h-3.9v8.7h-3.4v-8.7H72V121.3z M83.9,108.4L83.9,108.4l-9,12h9V108.4z"
        />
        <path
          class="qt3"
          d="M98.8,132.3c-0.7,0-1.3-0.2-1.9-0.7c-0.6-0.6-0.7-1.1-0.7-1.9c0-0.7,0.2-1.3,0.7-1.9s1.1-0.7,1.9-0.7
			c0.7,0,1.3,0.2,1.9,0.7c0.6,0.6,0.7,1.2,0.7,1.9s-0.4,1.3-0.7,1.9C100.1,132,99.4,132.3,98.8,132.3z"
        />
        <path
          class="qt3"
          d="M105.1,121.3l13.7-17.8h1.8v16.6h3.9v2.6h-3.9v8.7H117v-8.7h-11.9V121.3z M117.1,108.4L117.1,108.4l-9,12h9
			V108.4z"
        />
        <path
          class="qt3"
          d="M144.1,126.7c0,0.7,0.1,1.2,0.2,1.7c0.1,0.5,0.4,0.7,0.7,1c0.2,0.2,0.6,0.5,0.7,0.6c0.4,0.1,0.6,0.1,0.8,0.1
			c0.6,0,1-0.1,1.4-0.4c0.5-0.2,0.8-0.5,1.3-0.8l0.6,0.6c-0.7,0.7-1.4,1.3-2.3,1.9c-0.8,0.6-1.9,0.7-3,0.7c-1.3,0-2.2-0.4-2.9-1
			c-0.7-0.7-1-1.6-1-2.7v-12.1h-2.6v-0.7c0.6-0.5,1-0.7,1.4-1.1c0.5-0.4,0.8-0.7,1.3-1.1s0.8-0.8,1.2-1.4c0.5-0.6,0.8-1.2,1.4-2h0.5
			v4.1h6v2.2h-6v10.4H144.1z"
        />
        <path
          class="qt3"
          d="M152.4,123c0-1.3,0.2-2.5,0.7-3.6c0.5-1.2,1.1-2.2,1.9-3.1c0.7-0.8,1.8-1.6,2.9-2c1.1-0.5,2.3-0.7,3.6-0.7
			c1.4,0,2.6,0.2,3.8,0.7c1.2,0.5,2.2,1.1,3.1,1.9c0.8,0.7,1.6,1.8,2,3c0.5,1.2,0.7,2.4,0.7,3.8c0,1.3-0.2,2.5-0.7,3.6
			c-0.5,1.2-1.1,2.2-2,3.1c-0.8,0.8-1.8,1.6-2.9,2c-1.1,0.6-2.3,0.7-3.6,0.7s-2.5-0.2-3.7-0.7s-2.2-1.1-3.1-1.9
			c-0.8-0.7-1.6-1.8-2-3C152.6,125.6,152.4,124.3,152.4,123z M156,122.5c0,0.8,0.1,1.8,0.4,2.7c0.2,1,0.6,2,1.1,2.9
			c0.5,0.8,1.1,1.7,1.9,2.2s1.7,0.8,2.6,0.8s1.9-0.2,2.5-0.7c0.7-0.6,1.3-1.2,1.8-2c0.5-0.7,0.7-1.7,1-2.6c0.2-1,0.4-1.9,0.4-2.6
			s-0.1-1.8-0.4-2.7c-0.2-1-0.6-2-1.1-2.9c-0.5-0.8-1.1-1.7-1.9-2.2c-0.7-0.6-1.7-0.8-2.7-0.8s-1.9,0.2-2.5,0.7
			c-0.7,0.6-1.3,1.2-1.8,2s-0.7,1.7-1,2.6C156.2,120.7,156,121.7,156,122.5z"
        />
        <path
          class="qt3"
          d="M176.5,118.8c0-0.4,0-0.7-0.1-1c-0.1-0.2-0.2-0.5-0.4-0.7c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.7-0.4-1.2-0.6
			v-0.7c0.8-0.4,1.7-0.6,2.5-0.8c0.8-0.4,1.8-0.7,2.7-1.1c0.1,0,0.2,0.1,0.2,0.5v2.6c1.1-0.7,2.2-1.6,3.3-2c1.1-0.6,2.3-0.8,3.6-0.8
			c0.8,0,1.6,0.1,2.2,0.5c0.6,0.4,1.1,0.7,1.4,1.2c0.4,0.6,0.7,1.1,0.7,1.8c0.1,0.7,0.2,1.4,0.2,2.2v10.1c0,0.7,0.2,1.1,0.6,1.2
			c0.5,0.1,0.8,0.1,1.4,0.1h0.7v1c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2.2,0c-0.7,0-1.4,0-2.2,0.1v-1h0.7
			c0.6,0,1,0,1.4-0.1s0.6-0.5,0.6-1.2v-10.2c0-1.1-0.4-2-1-2.5c-0.7-0.6-1.6-0.8-2.6-0.8c-0.4,0-0.7,0.1-1.1,0.2
			c-0.5,0.1-0.7,0.2-1.2,0.5c-0.5,0.2-0.8,0.5-1.2,0.7c-0.5,0.2-0.7,0.5-1.1,0.7v11.5c0,0.7,0.2,1.1,0.6,1.2
			c0.5,0.1,0.8,0.1,1.4,0.1h0.7v1c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2.2,0c-0.7,0-1.4,0-2.2,0.1v-1h0.7
			c0.6,0,1,0,1.4-0.1s0.6-0.5,0.6-1.2L176.5,118.8C176.9,118.8,176.5,118.8,176.5,118.8z"
        />
        <path
          class="qt3"
          d="M197.4,126.1c0.1,0.7,0.4,1.3,0.6,1.9c0.2,0.6,0.7,1.1,1,1.6s0.8,0.7,1.4,1.1c0.6,0.2,1.2,0.5,2,0.5
			c0.6,0,1.1-0.1,1.6-0.2c0.5-0.1,0.7-0.4,1-0.7c0.2-0.2,0.5-0.6,0.6-0.8c0.1-0.4,0.2-0.7,0.2-1c0-0.7-0.2-1.4-0.7-1.9
			c-0.5-0.5-1-0.8-1.7-1.3c-0.7-0.4-1.3-0.7-2-1c-0.7-0.4-1.4-0.7-2-1.1c-0.7-0.5-1.2-1-1.7-1.7c-0.5-0.7-0.7-1.6-0.7-2.6
			c0-0.6,0.1-1.1,0.4-1.8c0.2-0.6,0.6-1.1,1.1-1.7c0.6-0.6,1.1-0.8,2-1.2c0.7-0.4,1.8-0.5,2.9-0.5c0.7,0,1.3,0.1,2.2,0.4
			c0.7,0.2,1.7,0.5,2.4,0.8l0.4,4.1h-0.8c-0.2-1.3-0.7-2.2-1.4-3c-0.7-0.7-1.7-1-3-1c-0.7,0-1.6,0.2-2.2,0.7c-0.7,0.5-1,1.1-1,2
			c0,0.7,0.2,1.4,0.7,1.9c0.5,0.5,1,0.8,1.8,1.2c0.7,0.4,1.4,0.7,2.2,1c0.7,0.4,1.6,0.7,2.2,1.1c0.7,0.5,1.2,1,1.8,1.7
			c0.5,0.7,0.7,1.6,0.7,2.5c0,0.7-0.1,1.3-0.5,2c-0.2,0.7-0.7,1.2-1.2,1.8s-1.3,1-2,1.2c-0.8,0.4-1.9,0.5-3.1,0.5
			c-0.7,0-1.7-0.1-2.5-0.4c-0.8-0.2-1.7-0.6-2.3-1l-0.5-4.7L197.4,126.1z"
        />
        <path
          class="qt3"
          d="M223.1,123c0-1.3,0.2-2.5,0.7-3.6c0.5-1.2,1.1-2.2,1.9-3.1c0.7-0.8,1.8-1.6,2.9-2c1.1-0.6,2.3-0.7,3.6-0.7
			c1.4,0,2.6,0.2,3.8,0.7s2.2,1.1,3.1,1.9c0.8,0.7,1.6,1.8,2,3c0.5,1.2,0.7,2.4,0.7,3.8c0,1.3-0.2,2.5-0.7,3.6
			c-0.5,1.2-1.1,2.2-2,3.1c-0.8,0.8-1.8,1.6-2.9,2c-1.1,0.6-2.3,0.7-3.6,0.7s-2.5-0.2-3.7-0.7c-1.2-0.5-2.2-1.1-3.1-1.9
			c-0.8-0.7-1.6-1.8-2-3C223.4,125.6,223.1,124.3,223.1,123z M226.8,122.5c0,0.8,0.1,1.8,0.4,2.7c0.2,1,0.6,2,1.1,2.9
			c0.5,0.8,1.1,1.7,1.9,2.2c0.7,0.6,1.7,0.8,2.6,0.8c1,0,1.9-0.2,2.5-0.7c0.7-0.6,1.3-1.2,1.8-2c0.5-0.7,0.7-1.7,1-2.6
			c0.2-1,0.4-1.9,0.4-2.6s-0.1-1.8-0.4-2.7c-0.3-0.9-0.6-2-1.1-2.9c-0.5-0.8-1.1-1.7-1.9-2.2s-1.7-0.8-2.7-0.8s-1.9,0.2-2.5,0.7
			c-0.7,0.6-1.3,1.2-1.8,2s-0.7,1.7-1,2.6C226.9,120.7,226.8,121.7,226.8,122.5z"
        />
        <path
          class="qt3"
          d="M256.4,114v2.2h-5v13.5c0,0.5,0.2,0.7,0.6,0.8c0.5,0.1,0.7,0.1,1.3,0.1h1.8v1c-0.8,0-1.7,0-2.5-0.1
			c-0.8,0-1.7,0-2.5,0s-1.7,0-2.4,0c-0.8,0-1.7,0-2.4,0.1v-1h1.6c0.7,0,1.2-0.1,1.4-0.5c0.2-0.4,0.4-0.6,0.4-0.8v-13.2h-2.9v-0.7
			c0.2-0.1,0.6-0.4,0.8-0.5c0.4-0.1,0.7-0.4,0.8-0.6c0.4-0.2,0.6-0.5,0.7-0.7c0.2-0.2,0.4-0.6,0.4-0.8c0-0.7,0-1.3,0.1-2
			c0.1-0.7,0.4-1.6,0.6-2.4c0.2-0.7,0.6-1.7,1-2.4s0.8-1.6,1.6-2c1.1-1.1,2-1.9,3.1-2.2c0.8-0.4,1.6-0.6,1.8-0.6c0.1,0,0.4,0,0.6,0
			s0.5,0.1,0.6,0.2c0.2,0.1,0.4,0.4,0.5,0.6s0.2,0.6,0.2,0.8c0,0.5-0.1,0.8-0.4,1.1c-0.2,0.4-0.6,0.5-1.1,0.5c-0.4,0-0.6,0-0.7-0.1
			c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.4-0.1-0.6-0.2c-0.2,0-0.5-0.1-0.7-0.1c-0.7,0-1.1,0.1-1.4,0.5s-0.7,0.7-0.7,1.2
			c-0.2,0.5-0.4,1-0.4,1.4c0,0.6-0.1,1-0.1,1.4v5.7L256.4,114L256.4,114z"
        />
        <path
          class="qt3"
          d="M284.2,116.2c0,0.5-0.1,0.8-0.5,1.2c-0.4,0.4-0.7,0.6-1.1,0.6c-0.5,0-0.7-0.2-1-0.6c-0.3-0.4-0.6-0.7-0.8-1.1
			c-0.4-0.5-0.7-0.7-1.3-1.1c-0.6-0.4-1.2-0.6-2.2-0.6c-0.8,0-1.7,0.2-2.2,0.7c-0.7,0.5-1.2,1-1.7,1.7c-0.5,0.7-0.7,1.4-1,2.2
			c-0.2,0.7-0.4,1.6-0.4,2.3c0,1,0.1,2,0.5,3c0.4,0.8,0.7,1.8,1.4,2.4c0.6,0.7,1.3,1.3,2.2,1.7c0.8,0.5,1.9,0.7,3,0.7
			c1,0,1.9-0.2,2.6-0.6s1.4-0.7,2.2-1.4l0.7,0.7c-1,1.1-2,2-3.4,3c-1.3,0.7-2.7,1.2-4.4,1.2c-1,0-2-0.2-3-0.7
			c-1-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.7-1.8-2.6c-0.5-1-0.7-2-0.7-3.1c0-1.4,0.2-2.7,0.7-4.1c0.5-1.3,1.2-2.4,2-3.4
			c0.8-1,1.9-1.8,3.1-2.3c1.2-0.6,2.5-0.8,3.9-0.8c0.6,0,1.1,0,1.7,0.1c0.7,0.1,1.2,0.2,1.8,0.5c0.6,0.3,1,0.5,1.4,0.8
			C284.1,115.2,284.2,115.6,284.2,116.2z"
        />
        <path
          class="qt3"
          d="M304.2,130.1c-0.6,0.6-1.2,1-1.8,1.4c-0.7,0.5-1.3,0.7-2.2,0.7s-1.4-0.4-1.9-1c-0.5-0.7-0.7-1.3-0.7-2
			c-0.8,0.8-1.9,1.6-2.9,2c-1,0.6-2,0.8-3.3,0.8c-1.3,0-2.3-0.4-3.1-1.1c-0.7-0.7-1.2-1.7-1.2-2.7c0-0.7,0.2-1.4,0.5-2
			c0.4-0.6,0.7-1,1.6-1.4c0.7-0.5,1.8-1,3.1-1.4c1.3-0.5,3.1-1.1,5.3-1.9v-2.6c0-2.3-1.3-3.4-3.8-3.4c-0.8,0-1.6,0.2-2,0.7
			c-0.6,0.5-0.8,1.2-0.8,2c0,0.6-0.2,1-0.7,1.2c-0.6,0.2-1,0.5-1.4,0.5c-0.4,0-0.5-0.1-0.6-0.4c-0.1-0.2-0.1-0.4-0.1-0.6
			c0-0.6,0.1-1,0.4-1.4c0.2-0.5,0.6-0.7,0.8-1.1c0.5-0.4,0.8-0.7,1.4-1s1.2-0.7,1.9-1c0.4-0.2,0.7-0.4,1-0.6
			c0.4-0.1,0.6-0.4,0.7-0.5c0.2-0.1,0.6-0.2,0.8-0.4c0.4-0.1,0.7-0.1,1-0.1c0.5,0,1,0.1,1.6,0.2c0.6,0.1,1.1,0.4,1.7,0.7
			c0.6,0.4,0.8,0.7,1.2,1c0.4,0.5,0.6,0.8,0.6,1.4v11c0,0.8,0.1,1.4,0.4,1.8c0.2,0.4,0.6,0.6,1.1,0.6c0.2,0,0.6-0.1,0.7-0.2
			c0.2-0.1,0.5-0.4,0.7-0.6V130.1z M297.7,122.9c-0.6,0.2-1.2,0.5-2,0.7c-0.7,0.2-1.7,0.6-2.4,0.8c-0.7,0.4-1.4,0.7-2,1.3
			c-0.6,0.6-0.8,1.1-0.8,1.9s0.4,1.4,0.8,2c0.6,0.6,1.2,0.8,2,0.8c0.8,0,1.8-0.2,2.4-0.7c0.7-0.5,1.3-1,2-1.7V122.9z"
        />
        <path
          class="qt3"
          d="M306.5,130.7h1.1c0.6,0,1,0,1.4-0.1c0.4-0.1,0.6-0.5,0.6-1.2v-11.3c0-0.4-0.1-0.6-0.2-0.7
			c-0.1-0.2-0.4-0.4-0.6-0.5c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.5-0.1-0.6-0.2v-0.6c1.4-0.7,2.6-1.1,3.4-1.7
			c0.7-0.5,1.3-0.7,1.4-0.7c0.2,0,0.4,0.2,0.4,0.6v3.6h0.1c0.2-0.5,0.6-0.8,0.8-1.3c0.4-0.5,0.7-0.8,1.1-1.3
			c0.5-0.5,0.8-0.7,1.4-1.1c0.6-0.4,1.1-0.5,1.7-0.5c0.6,0,1,0.2,1.6,0.6c0.6,0.4,0.7,0.8,0.7,1.7c0,0.6-0.2,1-0.6,1.4
			c-0.4,0.5-0.7,0.7-1.3,0.7c-0.4,0-0.6,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.5-0.1-0.7-0.1
			c-0.1,0-0.5,0.1-0.7,0.2c-0.4,0.1-0.7,0.4-1,0.6c-0.4,0.2-0.6,0.7-0.8,1c-0.2,0.5-0.4,0.8-0.4,1.6v9.1c0,0.7,0.2,1.1,0.6,1.2
			c0.4,0.1,0.7,0.1,1.4,0.1h2.2v1c-1.1,0-2,0-3-0.1c-0.8,0-1.8,0-2.6,0s-1.7,0-2.4,0c-0.7,0-1.6,0-2.3,0.1v-1L306.5,130.7
			L306.5,130.7z"
        />
        <path
          class="qt3"
          d="M328.2,116.3c0.5-0.4,0.8-0.7,1.3-1c0.5-0.4,1-0.7,1.4-0.8c0.5-0.2,1-0.6,1.6-0.7c0.6-0.2,1.1-0.2,1.7-0.2
			c1.1,0,2.2,0.2,3.1,0.7s1.7,1.1,2.3,1.9s1.1,1.7,1.4,2.6c0.4,1,0.6,2,0.6,3.2c0,1.3-0.2,2.5-0.7,3.8c-0.5,1.2-1.1,2.4-2,3.3
			c-0.8,1-1.9,1.8-3,2.4c-1.2,0.6-2.4,0.8-3.9,0.8c-0.7,0-1.4,0-2-0.1c-0.6-0.1-1.1-0.2-1.6-0.4c-0.5-0.1-0.8-0.4-1.2-0.5
			c-0.4-0.1-0.7-0.4-0.8-0.5l-1.2,0.8l-0.8-0.5c0.2-1.2,0.4-2.4,0.5-3.5c0.1-1.2,0.1-2.3,0.1-3.5v-16.9c0-0.7,0-1.1-0.1-1.6
			c-0.1-0.4-0.2-0.7-0.4-0.8s-0.5-0.4-0.7-0.5c-0.2-0.1-0.6-0.2-1-0.4v-0.7c0.7-0.2,1.6-0.6,2.3-0.8c0.7-0.4,1.3-0.6,1.8-0.8
			c0.2-0.1,0.5-0.2,0.6-0.4c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.4,0.2,0.4,0.6L328.2,116.3L328.2,116.3z M328.2,127.2
			c0,0.4,0.1,0.7,0.2,1.3c0.2,0.5,0.5,0.8,0.7,1.3c0.4,0.5,0.7,0.7,1.3,1c0.6,0.4,1.1,0.5,1.8,0.5c1.1,0,2-0.2,2.7-0.7
			c0.7-0.5,1.4-1.1,1.9-1.9c0.5-0.7,0.8-1.6,1.1-2.5c0.2-0.8,0.4-1.9,0.4-2.6c0-1.1-0.2-2-0.6-3.1c-0.4-1-0.8-1.8-1.4-2.5
			c-0.6-0.7-1.3-1.3-2-1.8c-0.7-0.5-1.6-0.7-2.3-0.7s-1.4,0.2-2,0.6c-0.7,0.5-1.2,0.8-1.8,1.3L328.2,127.2L328.2,127.2z"
        />
        <path
          class="qt3"
          d="M344.9,123c0-1.3,0.2-2.5,0.7-3.6c0.5-1.2,1.1-2.2,1.9-3.1c0.7-0.8,1.8-1.6,2.9-2c1.1-0.6,2.3-0.7,3.6-0.7
			c1.4,0,2.6,0.2,3.8,0.7s2.2,1.1,3.1,1.9s1.6,1.8,2,3c0.5,1.2,0.7,2.4,0.7,3.8c0,1.3-0.2,2.5-0.7,3.6c-0.5,1.2-1.1,2.2-2,3.1
			c-0.8,0.8-1.8,1.6-2.9,2c-1.1,0.6-2.3,0.7-3.6,0.7c-1.3,0-2.5-0.2-3.7-0.7c-1.2-0.5-2.2-1.1-3.1-1.9c-0.8-0.7-1.6-1.8-2-3
			C345.1,125.6,344.9,124.3,344.9,123z M348.4,122.5c0,0.8,0.1,1.8,0.4,2.7c0.2,1,0.6,2,1.1,2.9c0.5,0.8,1.1,1.7,1.9,2.2
			s1.7,0.8,2.6,0.8c1,0,1.9-0.2,2.5-0.7s1.3-1.2,1.8-2c0.5-0.7,0.7-1.7,1-2.6c0.2-1,0.4-1.9,0.4-2.6s-0.1-1.8-0.4-2.7
			c-0.2-1-0.6-2-1.1-2.9c-0.5-0.8-1.1-1.7-1.9-2.2s-1.7-0.8-2.7-0.8s-1.9,0.2-2.5,0.7s-1.3,1.2-1.8,2c-0.5,0.7-0.7,1.7-1,2.6
			C348.6,120.7,348.4,121.7,348.4,122.5z"
        />
        <path
          class="qt3"
          d="M369,118.8c0-0.4,0-0.7-0.1-1c-0.1-0.2-0.2-0.5-0.4-0.7c-0.2-0.2-0.5-0.4-0.7-0.6s-0.7-0.4-1.2-0.6v-0.7
			c0.8-0.4,1.7-0.6,2.5-0.8c0.8-0.3,1.8-0.7,2.7-1.1c0.1,0,0.2,0.1,0.2,0.5v2.6c1.1-0.7,2.2-1.6,3.3-2c1.1-0.6,2.3-0.8,3.6-0.8
			c0.8,0,1.6,0.1,2.2,0.5c0.6,0.4,1.1,0.7,1.4,1.2c0.4,0.6,0.7,1.1,0.7,1.8c0.1,0.7,0.2,1.4,0.2,2.2v10.1c0,0.7,0.2,1.1,0.6,1.2
			c0.5,0.1,0.8,0.1,1.4,0.1h0.7v1c-0.7,0-1.4,0-2,0s-1.4,0-2,0c-0.7,0-1.4,0-2.2,0s-1.4,0-2.2,0.1v-1h0.7c0.6,0,1,0,1.4-0.1
			s0.6-0.5,0.6-1.2v-10.2c0-1.1-0.4-2-1-2.5c-0.7-0.6-1.6-0.8-2.6-0.8c-0.4,0-0.7,0.1-1.1,0.2c-0.5,0.1-0.7,0.2-1.2,0.5
			c-0.5,0.2-0.8,0.5-1.2,0.7c-0.5,0.2-0.7,0.5-1.1,0.7v11.5c0,0.7,0.2,1.1,0.6,1.2c0.5,0.1,0.8,0.1,1.4,0.1h0.7v1c-0.7,0-1.4,0-2,0
			c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2.2,0c-0.7,0-1.4,0-2.2,0.1v-1h0.7c0.6,0,1,0,1.4-0.1c0.4-0.1,0.6-0.5,0.6-1.2L369,118.8
			C369.4,118.8,369,118.8,369,118.8z"
        />
        <path
          class="qt3"
          d="M51.3,169.5c0.1,0.7,0.4,1.3,0.6,1.9s0.7,1.1,1,1.6s0.8,0.7,1.4,1.1c0.7,0.2,1.3,0.4,2,0.4
			c0.6,0,1.1-0.1,1.6-0.2c0.5-0.1,0.7-0.4,1-0.7c0.2-0.2,0.5-0.6,0.6-0.8c0.1-0.4,0.2-0.7,0.2-1c0-0.7-0.2-1.4-0.7-1.9
			c-0.5-0.5-1-0.8-1.7-1.3c-0.7-0.4-1.3-0.7-2-1c-0.7-0.4-1.4-0.7-2-1.1s-1.2-1-1.7-1.7c-0.5-0.7-0.7-1.6-0.7-2.6
			c0-0.6,0.1-1.1,0.4-1.8c0.2-0.6,0.6-1.1,1.1-1.7c0.6-0.6,1.1-0.8,2-1.2c0.7-0.4,1.8-0.5,2.9-0.5c0.7,0,1.3,0.1,2.2,0.4
			c0.7,0.2,1.7,0.5,2.4,0.8l0.4,4.1h-0.8c-0.2-1.3-0.7-2.2-1.4-3c-0.7-0.7-1.7-1-3-1c-0.7,0-1.6,0.2-2.2,0.7s-1,1.1-1,2
			c0,0.7,0.2,1.4,0.7,1.9c0.5,0.5,1,0.8,1.8,1.2c0.7,0.4,1.4,0.7,2.2,1c0.7,0.4,1.6,0.7,2.2,1.1s1.2,1,1.8,1.7
			c0.5,0.7,0.7,1.6,0.7,2.5c0,0.7-0.1,1.3-0.5,2c-0.2,0.7-0.7,1.2-1.2,1.8c-0.6,0.6-1.3,1-2,1.2c-0.8,0.4-1.9,0.5-3.1,0.5
			c-0.7,0-1.7-0.1-2.5-0.4s-1.7-0.6-2.3-1l-0.5-4.7L51.3,169.5z"
        />
        <path
          class="qt3"
          d="M71,170.1c0,0.7,0.1,1.2,0.2,1.7c0.1,0.5,0.4,0.7,0.7,1c0.2,0.2,0.6,0.5,0.7,0.6c0.4,0.1,0.6,0.1,0.8,0.1
			c0.6,0,1-0.1,1.4-0.4c0.5-0.2,0.8-0.5,1.3-0.8l0.6,0.6c-0.7,0.7-1.4,1.3-2.3,1.9c-0.8,0.6-1.9,0.7-3,0.7c-1.3,0-2.2-0.4-2.9-1
			c-0.7-0.7-1-1.6-1-2.7v-12.1h-2.3V159c0.6-0.5,1-0.7,1.4-1.1c0.5-0.4,0.8-0.7,1.3-1.1s0.8-0.8,1.2-1.4c0.5-0.6,0.8-1.2,1.4-2H71
			v4.1h6v2.2h-6V170.1z"
        />
        <path
          class="qt3"
          d="M79.3,166.3c0-1.3,0.2-2.5,0.7-3.6c0.5-1.2,1.1-2.2,1.9-3.1c0.7-0.8,1.8-1.6,2.9-2c1.1-0.6,2.3-0.7,3.6-0.7
			c1.4,0,2.6,0.2,3.8,0.7c1.2,0.5,2.2,1.1,3.1,1.9c0.8,0.7,1.6,1.8,2,3c0.5,1.2,0.7,2.4,0.7,3.8c0,1.3-0.2,2.5-0.7,3.6
			c-0.5,1.2-1.1,2.2-2,3.1s-1.8,1.6-2.9,2c-1.1,0.6-2.3,0.7-3.6,0.7s-2.5-0.2-3.7-0.7s-2.2-1.1-3.1-1.9c-0.8-0.7-1.6-1.8-2-3
			C79.5,168.9,79.3,167.7,79.3,166.3z M83,165.9c0,0.8,0.1,1.8,0.4,2.7c0.2,1,0.6,2,1.1,2.9c0.5,0.8,1.1,1.7,1.9,2.2
			s1.7,0.8,2.6,0.8c1,0,1.9-0.2,2.5-0.7c0.7-0.6,1.3-1.2,1.8-2c0.5-0.7,0.7-1.7,1-2.6c0.3-0.9,0.4-1.9,0.4-2.6
			c0-0.7-0.1-1.8-0.4-2.7c-0.3-0.9-0.7-2-1.2-3c-0.5-0.8-1.1-1.7-1.9-2.2c-0.7-0.6-1.7-0.8-2.7-0.8c-1,0-1.9,0.2-2.5,0.7
			c-0.7,0.6-1.3,1.2-1.8,2c-0.5,0.7-0.7,1.7-1,2.6C83.1,164.1,83,165.1,83,165.9z"
        />
        <path
          class="qt3"
          d="M100.7,174h1.1c0.6,0,1,0,1.4-0.1c0.4-0.1,0.6-0.5,0.6-1.2v-11.2c0-0.4-0.1-0.6-0.2-0.7
			c-0.1-0.2-0.4-0.4-0.6-0.5c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.5-0.1-0.6-0.2v-0.6c1.4-0.7,2.6-1.1,3.4-1.7
			c0.7-0.5,1.3-0.7,1.4-0.7c0.2,0,0.4,0.2,0.4,0.6v3.6h0.1c0.2-0.5,0.6-0.8,0.8-1.3s0.7-0.8,1.1-1.3c0.5-0.5,0.8-0.7,1.4-1.1
			c0.6-0.4,1.1-0.5,1.7-0.5c0.6,0,1,0.2,1.6,0.6c0.6,0.4,0.7,0.8,0.7,1.7c0,0.6-0.2,1-0.6,1.4c-0.4,0.5-0.7,0.7-1.3,0.7
			c-0.4,0-0.6,0-0.7-0.1s-0.4-0.2-0.6-0.4c-0.2-0.1-0.4-0.2-0.6-0.4c-0.4-0.4-0.6-0.4-0.8-0.4c-0.1,0-0.5,0.1-0.7,0.2
			c-0.3,0.1-0.7,0.4-1,0.6c-0.4,0.2-0.6,0.7-0.8,1c-0.2,0.5-0.4,0.8-0.4,1.6v9.3c0,0.7,0.2,1.1,0.6,1.2c0.4,0.1,0.7,0.1,1.4,0.1h2.2
			v1c-1.1,0-2,0-3-0.1c-0.8,0-1.8,0-2.6,0c-0.8,0-1.7,0-2.4,0s-1.6,0-2.3,0.1L100.7,174L100.7,174z"
        />
        <path
          class="qt3"
          d="M119.8,164c0,0.2-0.1,0.5-0.1,0.7c0,0.3,0,0.8,0.1,1.6c0,1,0.2,1.9,0.6,2.6c0.4,0.8,0.7,1.6,1.2,2.2
			c0.6,0.7,1.1,1.1,1.9,1.4c0.7,0.4,1.4,0.6,2.2,0.6c1.1,0,2-0.2,3-0.7c0.8-0.6,1.7-1.1,2.4-1.9l0.6,0.6c-0.5,0.7-1,1.2-1.4,1.8
			c-0.5,0.6-1.1,1-1.7,1.4c-0.7,0.5-1.2,0.7-2,1c-0.7,0.2-1.4,0.4-2.3,0.4c-1.7,0-2.9-0.4-3.8-1c-1-0.7-1.8-1.4-2.3-2.4
			c-0.6-0.8-1-1.9-1.1-2.9c-0.2-1-0.4-1.8-0.4-2.4c0-2,0.4-3.5,0.8-4.8c0.6-1.3,1.3-2.3,2-3.1c0.8-0.7,1.8-1.3,2.6-1.7
			c0.8-0.4,1.8-0.5,2.4-0.5c0.7,0,1.7,0.1,2.4,0.4c0.7,0.3,1.6,0.7,2.2,1.2c0.7,0.6,1.2,1.2,1.6,2c0.5,0.7,0.7,1.8,0.7,2.7
			c0,0.1,0,0.2,0,0.4c0,0.2,0,0.2,0,0.4l-0.4,0.2H119.8L119.8,164z M126.9,162.8c0.6,0,0.7-0.1,1-0.4c0.1-0.2,0.2-0.5,0.2-0.7
			c0-1-0.4-1.9-0.8-2.6c-0.6-0.7-1.4-1.1-2.5-1.1c-1.2,0-2.2,0.5-3.1,1.2c-0.8,0.7-1.3,2-1.6,3.4L126.9,162.8L126.9,162.8
			L126.9,162.8z"
        />
        <path
          class="qt3"
          d="M151.4,171.6c0,0.5,0,0.7,0.1,1c0,0.2,0.1,0.5,0.4,0.6c0.1,0.1,0.4,0.2,0.6,0.2c0.2,0,0.6,0,1,0h0.7v0.7l0,0
			c-0.4,0-0.7,0.1-1.2,0.4c-0.6,0.1-1.1,0.4-1.7,0.6c-0.7,0.2-1.1,0.5-1.7,0.6c-0.5,0.1-0.7,0.2-0.8,0.2c-0.2,0-0.4-0.1-0.4-0.4
			c0-0.2-0.1-0.5-0.1-0.7v-1.9h-0.1c-0.7,0.7-1.7,1.4-2.6,2c-1.1,0.6-2.2,0.8-3.4,0.8c-1.2,0-2.2-0.2-3.2-0.7
			c-0.8-0.5-1.7-1.1-2.3-2c-0.7-0.8-1.1-1.8-1.4-2.7c-0.4-1-0.5-2-0.5-3.2c0-1.3,0.2-2.6,0.7-3.8c0.6-1.2,1.2-2.3,2-3.2
			c0.8-0.8,2-1.7,3.1-2.2c1.1-0.6,2.5-0.8,3.8-0.8c1.3,0,2.5,0.2,3.7,0.7v-7c0-0.7,0-1.1-0.1-1.6c-0.1-0.4-0.2-0.7-0.4-0.8
			c-0.2-0.2-0.5-0.4-0.7-0.5c-0.2-0.1-0.6-0.2-1-0.4v-0.7c0.7-0.2,1.6-0.6,2.3-0.8c0.7-0.4,1.3-0.6,1.8-0.8c0.2-0.1,0.5-0.2,0.6-0.4
			c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.4,0.2,0.4,0.6L151.4,171.6L151.4,171.6z M148.3,161.9c0-0.7-0.1-1.3-0.5-1.9
			c-0.4-0.6-0.7-0.8-1-1.1c-0.5-0.4-0.8-0.5-1.4-0.6s-1-0.1-1.4-0.1c-0.7,0-1.4,0.1-2,0.5c-0.7,0.4-1.3,0.7-1.9,1.3
			c-0.6,0.6-1,1.3-1.4,2.3c-0.4,1-0.6,2-0.6,3.4c0,1.1,0.1,2,0.5,3.1c0.4,1,0.7,1.8,1.3,2.4c0.6,0.7,1.2,1.2,2,1.7
			c0.7,0.5,1.7,0.6,2.5,0.6c0.4,0,0.7,0,1.1-0.1c0.5-0.1,0.8-0.2,1.3-0.5c0.5-0.2,0.7-0.5,1.1-0.7c0.4-0.4,0.5-0.7,0.5-1
			L148.3,161.9L148.3,161.9z"
        />
        <path
          class="qt3"
          d="M167.4,174h0.7c0.6,0,1,0,1.4-0.1s0.6-0.5,0.6-1.2v-10.8c0-0.5,0-0.7-0.1-1.1s-0.2-0.6-0.4-0.7
			c-0.1-0.2-0.4-0.4-0.7-0.6c-0.2-0.1-0.6-0.4-1-0.6v-0.7c0.5-0.1,1-0.4,1.4-0.5c0.5-0.1,0.8-0.4,1.3-0.5s0.7-0.2,1-0.4
			c0.2-0.1,0.5-0.2,0.5-0.2c0.2-0.1,0.5-0.2,0.7-0.2c0.2,0,0.4,0.2,0.4,0.6v15.3c0,0.7,0.2,1.1,0.6,1.2c0.5,0.1,0.8,0.1,1.4,0.1h0.7
			v1c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2.2,0c-0.7,0-1.4,0-2.2,0.1L167.4,174L167.4,174z M173.6,148.7
			c0,0.6-0.2,1-0.6,1.4c-0.5,0.5-0.8,0.6-1.4,0.6c-0.6,0-1-0.2-1.4-0.6s-0.6-0.8-0.6-1.4s0.2-1,0.6-1.3c0.4-0.3,0.8-0.6,1.3-0.6
			c0.6,0,1,0.2,1.4,0.6C173.5,147.8,173.6,148.2,173.6,148.7z"
        />
        <path
          class="qt3"
          d="M180.9,162.1c0-0.4,0-0.7-0.1-1c-0.1-0.2-0.2-0.5-0.4-0.7c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.7-0.4-1.2-0.6
			v-0.7c0.8-0.4,1.7-0.6,2.5-0.8c0.8-0.4,1.8-0.7,2.7-1.1c0.1,0,0.2,0.1,0.2,0.5v2.6c1.1-0.7,2.2-1.6,3.3-2c1.1-0.6,2.3-0.8,3.6-0.8
			c0.8,0,1.6,0.1,2.2,0.5c0.6,0.4,1.1,0.7,1.4,1.2c0.4,0.6,0.7,1.1,0.7,1.8s0.2,1.4,0.2,2.2v10.3c0,0.7,0.2,1.1,0.6,1.2
			c0.5,0.1,0.8,0.1,1.4,0.1h0.7v1c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2.2,0c-0.7,0-1.4,0-2.2,0.1v-1h0.7
			c0.6,0,1,0,1.4-0.1c0.4-0.1,0.6-0.5,0.6-1.2v-10.4c0-1.1-0.4-2-1-2.5c-0.7-0.6-1.6-0.8-2.6-0.8c-0.4,0-0.7,0.1-1.1,0.2
			c-0.5,0.1-0.7,0.2-1.2,0.5c-0.5,0.2-0.8,0.5-1.2,0.7c-0.5,0.2-0.7,0.5-1.1,0.7v11.7c0,0.7,0.2,1.1,0.6,1.2
			c0.5,0.1,0.8,0.1,1.4,0.1h0.7v1c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2.2,0c-0.7,0-1.4,0-2.2,0.1v-1h0.7
			c0.6,0,1,0,1.4-0.1s0.6-0.5,0.6-1.2L180.9,162.1C181.3,162.1,180.9,162.1,180.9,162.1z"
        />
        <path
          class="qt3"
          d="M216.9,170.1c0,0.7,0.1,1.2,0.2,1.7c0.1,0.5,0.4,0.7,0.7,1c0.2,0.2,0.6,0.5,0.7,0.6c0.4,0.1,0.6,0.1,0.8,0.1
			c0.6,0,1-0.1,1.4-0.4c0.5-0.2,0.8-0.5,1.3-0.8l0.6,0.6c-0.7,0.7-1.4,1.3-2.3,1.9c-0.8,0.6-1.9,0.7-3,0.7c-1.3,0-2.2-0.4-2.9-1
			c-0.7-0.7-1-1.6-1-2.7v-12.1h-2.6V159c0.6-0.5,1-0.7,1.4-1.1c0.4-0.4,0.8-0.7,1.3-1.1c0.5-0.5,0.8-0.8,1.2-1.4
			c0.5-0.6,0.8-1.2,1.4-2h0.5v4.1h6v2.2h-5.9v10.4H216.9z"
        />
        <path
          class="qt3"
          d="M224.6,174h1.1c0.6,0,1,0,1.4-0.1s0.6-0.5,0.6-1.2v-11.2c0-0.4-0.1-0.6-0.2-0.7c-0.1-0.2-0.4-0.4-0.6-0.5
			c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.5-0.1-0.6-0.2v-0.6c1.4-0.7,2.6-1.1,3.4-1.7c0.7-0.5,1.3-0.7,1.4-0.7c0.2,0,0.4,0.2,0.4,0.6
			v3.6h0.1c0.2-0.5,0.6-0.8,0.8-1.3c0.4-0.5,0.7-0.8,1.1-1.3c0.5-0.5,0.8-0.7,1.4-1.1c0.6-0.4,1.1-0.5,1.7-0.5s1,0.2,1.6,0.6
			c0.6,0.4,0.7,0.8,0.7,1.7c0,0.6-0.2,1-0.6,1.4c-0.4,0.4-0.7,0.7-1.3,0.7c-0.4,0-0.6,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.5-0.1-0.7-0.1c-0.1,0-0.5,0.1-0.7,0.2c-0.4,0.1-0.7,0.4-1,0.6c-0.4,0.2-0.6,0.7-0.8,1
			c-0.2,0.5-0.4,0.8-0.4,1.6v9.3c0,0.7,0.2,1.1,0.6,1.2c0.4,0.1,0.7,0.1,1.4,0.1h2.2v1c-1.1,0-2,0-3-0.1c-0.8,0-1.8,0-2.6,0
			s-1.7,0-2.4,0c-0.7,0-1.6,0-2.3,0.1V174L224.6,174L224.6,174z"
        />
        <path
          class="qt3"
          d="M243.7,164c0,0.2-0.1,0.5-0.1,0.7c0,0.3,0,0.8,0.1,1.6c0,1,0.2,1.9,0.6,2.6c0.4,0.8,0.7,1.6,1.2,2.2
			c0.6,0.7,1.1,1.1,1.9,1.4c0.7,0.4,1.4,0.6,2.2,0.6c1.1,0,2-0.2,3-0.7c0.8-0.6,1.7-1.1,2.4-1.9l0.6,0.6c-0.5,0.7-1,1.2-1.4,1.8
			c-0.4,0.6-1.1,1-1.7,1.4c-0.7,0.5-1.2,0.7-2,1c-0.7,0.2-1.4,0.4-2.3,0.4c-1.7,0-2.9-0.4-3.8-1c-1-0.7-1.8-1.4-2.3-2.4
			c-0.6-0.8-1-1.9-1.1-2.9c-0.2-1-0.4-1.8-0.4-2.4c0-2,0.4-3.5,0.8-4.8c0.6-1.3,1.3-2.3,2-3.1c0.8-0.7,1.8-1.3,2.6-1.7
			c0.8-0.4,1.8-0.5,2.4-0.5c0.7,0,1.7,0.1,2.4,0.4c0.7,0.2,1.6,0.7,2.2,1.2c0.7,0.6,1.2,1.2,1.6,2c0.5,0.7,0.7,1.8,0.7,2.7
			c0,0.1,0,0.2,0,0.4c0,0.2,0,0.2,0,0.4L255,164H243.7L243.7,164z M250.7,162.8c0.6,0,0.7-0.1,1-0.4c0.1-0.2,0.2-0.5,0.2-0.7
			c0-1-0.4-1.9-0.8-2.6c-0.6-0.7-1.4-1.1-2.5-1.1c-1.2,0-2.2,0.5-3.1,1.2c-0.8,0.7-1.3,2-1.6,3.4L250.7,162.8L250.7,162.8
			L250.7,162.8z"
        />
        <path
          class="qt3"
          d="M261.4,164c0,0.2-0.1,0.5-0.1,0.7c0,0.3,0,0.8,0.1,1.6c0,1,0.2,1.9,0.6,2.6c0.4,0.8,0.7,1.6,1.2,2.2
			c0.6,0.7,1.1,1.1,1.9,1.4c0.7,0.4,1.4,0.6,2.2,0.6c1.1,0,2-0.2,3-0.7c0.8-0.6,1.7-1.1,2.4-1.9l0.6,0.6c-0.5,0.7-1,1.2-1.4,1.8
			c-0.4,0.6-1.1,1-1.7,1.4c-0.7,0.5-1.2,0.7-2,1c-0.7,0.2-1.4,0.4-2.3,0.4c-1.7,0-2.9-0.4-3.8-1c-1-0.7-1.8-1.4-2.3-2.4
			c-0.6-0.8-1-1.9-1.1-2.9c-0.2-1-0.4-1.8-0.4-2.4c0-2,0.4-3.5,0.8-4.8c0.6-1.3,1.3-2.3,2-3.1c0.8-0.7,1.8-1.3,2.6-1.7
			c0.8-0.4,1.8-0.5,2.4-0.5c0.7,0,1.7,0.1,2.4,0.4c0.7,0.2,1.6,0.7,2.2,1.2s1.2,1.2,1.6,2c0.5,0.7,0.7,1.8,0.7,2.7
			c0,0.1,0,0.2,0,0.4c0,0.2,0,0.2,0,0.4l-0.4,0.2H261.4L261.4,164z M268.4,162.8c0.6,0,0.7-0.1,1-0.4c0.1-0.2,0.2-0.5,0.2-0.7
			c0-1-0.4-1.9-0.8-2.6c-0.6-0.7-1.4-1.1-2.5-1.1c-1.2,0-2.2,0.5-3.1,1.2c-0.8,0.7-1.3,2-1.6,3.4L268.4,162.8L268.4,162.8
			L268.4,162.8z"
        />
        <path
          class="qt3"
          d="M276.8,169.5c0.1,0.7,0.4,1.3,0.6,1.9s0.7,1.1,1,1.6c0.4,0.5,0.8,0.7,1.4,1.1c0.6,0.2,1.2,0.5,2,0.5
			c0.6,0,1.1-0.1,1.6-0.2c0.5-0.1,0.7-0.4,1-0.7c0.2-0.2,0.5-0.6,0.6-0.8c0.1-0.4,0.2-0.7,0.2-1c0-0.7-0.2-1.4-0.7-1.9
			c-0.5-0.5-1-0.8-1.7-1.3c-0.7-0.4-1.3-0.7-2-1c-0.7-0.4-1.4-0.7-2-1.1c-0.7-0.5-1.2-1-1.7-1.7c-0.5-0.7-0.7-1.6-0.7-2.6
			c0-0.6,0.1-1.1,0.4-1.8c0.2-0.6,0.6-1.1,1.1-1.7c0.6-0.6,1.1-0.8,2-1.2c0.7-0.4,1.8-0.5,2.9-0.5c0.7,0,1.3,0.1,2.2,0.4
			c0.7,0.2,1.7,0.5,2.4,0.8l0.4,4.1h-0.8c-0.2-1.3-0.7-2.2-1.4-3c-0.7-0.7-1.7-1-3-1c-0.7,0-1.6,0.2-2.2,0.7c-0.7,0.5-1,1.1-1,2
			c0,0.7,0.2,1.4,0.7,1.9c0.5,0.5,1,0.8,1.8,1.2s1.4,0.7,2.2,1c0.7,0.4,1.6,0.7,2.2,1.1c0.7,0.5,1.2,1,1.8,1.7
			c0.5,0.7,0.7,1.6,0.7,2.5c0,0.7-0.1,1.3-0.5,2c-0.2,0.7-0.7,1.2-1.2,1.8s-1.3,1-2,1.2c-0.8,0.4-1.9,0.5-3.1,0.5
			c-0.7,0-1.7-0.1-2.5-0.4c-0.8-0.2-1.7-0.6-2.3-1l-0.5-4.7L276.8,169.5z"
        />
        <path
          class="qt3"
          d="M305.6,164c0,0.2-0.1,0.5-0.1,0.7c0,0.3,0,0.8,0.1,1.6c0,1,0.2,1.9,0.6,2.6c0.4,0.8,0.7,1.6,1.2,2.2
			c0.6,0.7,1.1,1.1,1.9,1.4c0.7,0.4,1.4,0.6,2.2,0.6c1.1,0,2-0.2,3-0.7c0.8-0.6,1.7-1.1,2.4-1.9l0.6,0.6c-0.5,0.7-1,1.2-1.4,1.8
			c-0.5,0.6-1.1,1-1.7,1.4c-0.7,0.5-1.2,0.7-2,1c-0.7,0.2-1.4,0.4-2.3,0.4c-1.7,0-2.9-0.4-3.8-1c-1-0.7-1.8-1.4-2.3-2.4
			c-0.6-0.8-1-1.9-1.1-2.9c-0.2-1-0.4-1.8-0.4-2.4c0-2,0.4-3.5,0.8-4.8c0.6-1.3,1.3-2.3,2-3.1c0.8-0.7,1.8-1.3,2.6-1.7
			c0.8-0.4,1.8-0.5,2.4-0.5c0.7,0,1.7,0.1,2.4,0.4c0.7,0.3,1.6,0.7,2.2,1.2c0.7,0.6,1.2,1.2,1.6,2c0.5,0.7,0.7,1.8,0.7,2.7
			c0,0.1,0,0.2,0,0.4c0,0.2,0,0.2,0,0.4l-0.4,0.2H305.6L305.6,164z M312.6,162.8c0.6,0,0.7-0.1,1-0.4c0.1-0.2,0.2-0.5,0.2-0.7
			c0-1-0.4-1.9-0.8-2.6c-0.6-0.7-1.4-1.1-2.5-1.1c-1.2,0-2.2,0.5-3.1,1.2c-0.8,0.7-1.3,2-1.6,3.4L312.6,162.8L312.6,162.8
			L312.6,162.8z"
        />
        <path
          class="qt3"
          d="M337.2,173.5c-0.6,0.6-1.2,1-1.8,1.4c-0.7,0.5-1.3,0.7-2.2,0.7c-0.9,0-1.4-0.4-1.9-1c-0.5-0.7-0.7-1.3-0.7-2
			c-0.8,0.8-1.9,1.6-2.9,2s-2,0.8-3.3,0.8c-1.3,0-2.3-0.4-3.1-1.1c-0.7-0.7-1.2-1.7-1.2-2.7c0-0.7,0.2-1.4,0.5-2
			c0.4-0.6,0.7-1,1.6-1.4c0.8-0.4,1.8-1,3.1-1.4c1.3-0.5,3.1-1.1,5.3-1.9v-2.6c0-2.3-1.3-3.4-3.8-3.4c-0.8,0-1.6,0.2-2,0.7
			c-0.6,0.5-0.8,1.2-0.8,2c0,0.6-0.2,1-0.7,1.2c-0.6,0.2-1,0.5-1.4,0.5c-0.4,0-0.5-0.1-0.6-0.4c-0.1-0.2-0.1-0.4-0.1-0.6
			c0-0.6,0.1-1,0.4-1.4c0.2-0.5,0.6-0.7,0.8-1.1c0.5-0.4,0.8-0.7,1.4-1c0.6-0.4,1.2-0.7,1.9-1c0.4-0.2,0.7-0.4,1-0.6
			c0.4-0.1,0.6-0.4,0.7-0.5c0.2-0.1,0.6-0.2,0.8-0.4c0.4-0.1,0.7-0.1,1-0.1c0.5,0,1,0.1,1.6,0.2c0.6,0.1,1.1,0.4,1.7,0.7
			c0.6,0.4,0.8,0.7,1.2,1c0.4,0.5,0.6,0.8,0.6,1.4v11c0,0.8,0.1,1.4,0.4,1.8c0.2,0.4,0.6,0.6,1.1,0.6c0.2,0,0.6-0.1,0.7-0.2
			c0.2-0.1,0.5-0.4,0.7-0.6V173.5z M330.9,166.2c-0.6,0.2-1.2,0.5-2,0.7c-0.7,0.2-1.7,0.6-2.4,0.8c-0.7,0.4-1.4,0.7-2,1.3
			c-0.6,0.6-0.8,1.1-0.8,1.9s0.4,1.4,0.8,2c0.6,0.6,1.2,0.8,2,0.8c0.8,0,1.8-0.2,2.4-0.7c0.7-0.5,1.3-1,2-1.7V166.2z"
        />
        <path
          class="qt3"
          d="M354.9,159.6c0,0.5-0.1,0.8-0.5,1.2c-0.4,0.4-0.7,0.6-1.1,0.6s-0.7-0.2-1-0.6c-0.2-0.4-0.6-0.7-0.8-1.1
			c-0.4-0.5-0.7-0.7-1.3-1.1c-0.6-0.4-1.2-0.6-2.2-0.6c-0.8,0-1.7,0.2-2.2,0.7c-0.7,0.5-1.2,1-1.7,1.7c-0.5,0.7-0.7,1.4-1,2.2
			c-0.2,0.7-0.4,1.6-0.4,2.3c0,1,0.1,2,0.5,3c0.4,0.8,0.7,1.8,1.4,2.4c0.6,0.7,1.3,1.3,2.2,1.7c0.8,0.5,1.9,0.7,3,0.7
			c1,0,1.9-0.2,2.6-0.6c0.7-0.4,1.4-0.7,2.2-1.4l0.7,0.7c-1,1.1-2,2-3.4,3c-1.3,0.7-2.7,1.2-4.4,1.2c-1,0-2-0.2-3-0.7
			c-1-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.7-1.8-2.6c-0.5-0.9-0.7-2-0.7-3.1c0-1.4,0.2-2.7,0.7-4.1c0.5-1.3,1.2-2.4,2-3.4
			c0.8-1,1.9-1.8,3.1-2.3c1.2-0.6,2.5-0.8,3.9-0.8c0.6,0,1.1,0,1.7,0.1c0.7,0.1,1.2,0.2,1.8,0.5c0.6,0.2,1,0.5,1.4,0.8
			C354.7,158.6,354.9,159.1,354.9,159.6z"
        />
        <path
          class="qt3"
          d="M360,150.5c0-0.7,0-1.1-0.1-1.6c-0.1-0.4-0.2-0.7-0.4-0.8c-0.2-0.2-0.5-0.4-0.7-0.5c-0.2-0.1-0.6-0.2-1-0.4
			v-0.7c0.7-0.2,1.6-0.6,2.3-0.8c0.7-0.3,1.3-0.6,1.8-0.8c0.2-0.1,0.5-0.2,0.6-0.4s0.2-0.1,0.4-0.1c0.2,0,0.4,0.2,0.4,0.6v15.2
			c0.1-0.1,0.4-0.4,0.7-0.7c0.4-0.4,0.7-0.7,1.3-1.2s1.2-0.7,1.9-1.1c0.7-0.4,1.4-0.6,2.3-0.6c0.8,0,1.7,0.1,2.3,0.5
			c0.7,0.4,1.2,0.7,1.7,1.2c0.5,0.6,0.7,1.2,1,1.9c0.2,0.7,0.4,1.6,0.4,2.4v9.9c0,0.7,0.2,1.1,0.6,1.2c0.5,0.1,0.8,0.1,1.4,0.1h0.7
			v1c-0.7,0-1.4,0-2,0s-1.4,0-2.2,0s-1.4,0-2.2,0c-0.7,0-1.4,0-2.2,0.1v-1h0.7c0.5,0,0.8,0,1.1-0.1c0.3-0.1,0.5-0.2,0.6-0.6
			c0.1-0.4,0.2-0.7,0.2-1.3c0-0.6,0-1.3,0-2.3v-4.5c0-0.7,0-1.4-0.1-2.2c-0.1-0.7-0.2-1.3-0.6-1.9c-0.2-0.6-0.7-1-1.2-1.3
			c-0.6-0.4-1.2-0.6-2-0.6c-0.5,0-0.8,0.1-1.3,0.2c-0.5,0.1-0.8,0.4-1.2,0.6c-0.4,0.2-0.7,0.5-1,0.7c-0.4,0.2-0.6,0.5-0.7,0.7v11.2
			c0,0.7,0.2,1.1,0.6,1.2c0.5,0.1,0.8,0.1,1.4,0.1h0.7v1c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2.2,0s-1.4,0-2.2,0.1v-1
			h0.7c0.6,0,1,0,1.4-0.1c0.4-0.1,0.6-0.5,0.6-1.2L360,150.5L360,150.5z"
        />
        <path
          class="qt3"
          d="M390.2,157.4c0.7,0,1.6,0,2.3,0.1c0.7,0,1.6,0,2.3,0c0.7,0,1.2,0,1.8,0c0.6,0,1.2,0,1.8,0v1
			c-0.2,0-0.5,0-0.7,0.1c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.5,0.2-0.6,0.4c-0.1,0.1-0.2,0.4-0.2,0.6c0,0.2,0.1,0.6,0.2,1.1
			c0.2,0.6,0.5,1.1,0.7,1.9c0.4,0.7,0.6,1.4,0.8,2.2c0.4,0.7,0.7,1.6,1,2.3c0.4,0.7,0.6,1.4,0.7,2c0.2,0.6,0.4,1,0.5,1.3h0.1
			c0.1-0.4,0.4-0.8,0.6-1.4c0.2-0.7,0.6-1.2,0.7-2c0.4-0.7,0.6-1.4,0.8-2c0.4-0.7,0.6-1.4,0.7-2c0.2-0.7,0.5-1.2,0.6-1.7
			s0.2-0.7,0.2-1c0-0.5-0.1-0.7-0.2-1c-0.1-0.2-0.4-0.5-0.6-0.6c-0.2-0.1-0.5-0.2-0.7-0.2c-0.2,0-0.6,0-0.7,0v-1
			c0.7,0,1.2,0,1.9,0.1c0.7,0,1.2,0,1.9,0c0.6,0,1,0,1.6,0c0.5,0,1,0,1.6-0.1v1c-0.8,0-1.7,0.2-2,0.7c-0.5,0.5-0.7,1.1-1.1,1.9
			l-9,21c-0.4,0.7-0.7,1.2-1.1,1.6c-0.5,0.4-0.8,0.5-1.6,0.5c-0.7,0-1.1-0.2-1.4-0.6c-0.4-0.4-0.6-0.8-0.6-1.3s0.2-0.8,0.6-1.3
			c0.4-0.5,0.7-0.7,1.3-0.7c0.4,0,0.7,0,0.8,0.1c0.2,0.1,0.5,0.2,0.7,0.6c1-2,1.9-4.2,2.7-6.3c-0.6-1.3-1-2.5-1.4-3.5
			c-0.5-1-0.8-2-1.3-3.3c-0.5-1.2-0.8-2.3-1.3-3.4c-0.5-1.1-0.7-2-1.1-2.7c-0.4-0.7-0.6-1.2-0.6-1.4c-0.4-0.6-0.7-0.8-1-1.2
			c-0.4-0.4-0.8-0.4-1.6-0.4v-1.2C389.7,157.4,390.2,157.4,390.2,157.4z"
        />
        <path
          class="qt3"
          d="M414,164c0,0.2-0.1,0.5-0.1,0.7c0,0.3,0,0.8,0.1,1.6c0,1,0.2,1.9,0.6,2.6c0.4,0.8,0.7,1.6,1.2,2.2
			c0.6,0.7,1.1,1.1,1.9,1.4c0.7,0.4,1.4,0.6,2.2,0.6c1.1,0,2-0.2,3-0.7c0.8-0.6,1.7-1.1,2.4-1.9l0.6,0.6c-0.5,0.7-1,1.2-1.4,1.8
			c-0.5,0.6-1.1,1-1.7,1.4c-0.7,0.5-1.2,0.7-2,1c-0.7,0.2-1.4,0.4-2.3,0.4c-1.7,0-2.9-0.4-3.8-1c-1-0.7-1.8-1.4-2.3-2.4
			c-0.6-0.8-1-1.9-1.1-2.9c-0.2-1-0.4-1.8-0.4-2.4c0-2,0.4-3.5,0.8-4.8c0.6-1.3,1.3-2.3,2-3.1c0.8-0.7,1.8-1.3,2.6-1.7
			c0.8-0.4,1.8-0.5,2.4-0.5c0.7,0,1.7,0.1,2.4,0.4c0.7,0.2,1.6,0.7,2.2,1.2c0.7,0.6,1.2,1.2,1.6,2c0.5,0.7,0.7,1.8,0.7,2.7
			c0,0.1,0,0.2,0,0.4c0,0.2,0,0.2,0,0.4l-0.4,0.2H414L414,164z M421,162.8c0.6,0,0.7-0.1,1-0.4c0.1-0.2,0.2-0.5,0.2-0.7
			c0-1-0.4-1.9-0.8-2.6c-0.5-0.7-1.4-1.1-2.5-1.1c-1.2,0-2.2,0.5-3.1,1.2c-0.8,0.7-1.3,2-1.6,3.4L421,162.8L421,162.8L421,162.8z"
        />
        <path
          class="qt3"
          d="M445.5,173.5c-0.6,0.6-1.2,1-1.8,1.4c-0.7,0.5-1.3,0.7-2.2,0.7s-1.4-0.4-1.9-1c-0.5-0.7-0.7-1.3-0.7-2
			c-0.8,0.8-1.9,1.6-2.9,2s-2,0.8-3.3,0.8c-1.3,0-2.3-0.4-3.1-1.1c-0.7-0.7-1.2-1.7-1.2-2.7c0-0.7,0.2-1.4,0.5-2
			c0.4-0.6,0.7-1,1.6-1.4c0.8-0.4,1.8-1,3.1-1.4c1.3-0.5,3.1-1.1,5.3-1.9v-2.6c0-2.3-1.3-3.4-3.8-3.4c-0.8,0-1.6,0.2-2,0.7
			c-0.6,0.5-0.8,1.2-0.8,2c0,0.6-0.2,1-0.7,1.2c-0.6,0.2-1,0.5-1.4,0.5c-0.4,0-0.5-0.1-0.6-0.4c-0.1-0.2-0.1-0.4-0.1-0.6
			c0-0.6,0.1-1,0.4-1.4c0.2-0.5,0.6-0.7,0.8-1.1c0.5-0.4,0.8-0.7,1.4-1s1.2-0.7,1.9-1c0.4-0.2,0.7-0.4,1-0.6
			c0.4-0.1,0.6-0.4,0.7-0.5c0.2-0.1,0.6-0.2,0.8-0.4c0.4-0.1,0.7-0.1,1-0.1c0.5,0,1,0.1,1.6,0.2c0.6,0.1,1.1,0.4,1.7,0.7
			c0.6,0.4,0.8,0.7,1.2,1c0.4,0.5,0.6,0.8,0.6,1.4v11c0,0.8,0.1,1.4,0.4,1.8c0.2,0.4,0.6,0.6,1.1,0.6c0.2,0,0.6-0.1,0.7-0.2
			s0.5-0.4,0.7-0.6V173.5z M439.1,166.2c-0.6,0.2-1.2,0.5-2,0.7c-0.7,0.2-1.7,0.6-2.4,0.8c-0.7,0.4-1.4,0.7-2,1.3
			c-0.6,0.6-0.8,1.1-0.8,1.9s0.4,1.4,0.8,2c0.5,0.6,1.2,0.8,2,0.8c0.8,0,1.8-0.2,2.4-0.7c0.7-0.5,1.3-1,2-1.7V166.2z"
        />
        <path
          class="qt3"
          d="M447.9,174h1.1c0.6,0,1,0,1.4-0.1c0.4-0.1,0.6-0.5,0.6-1.2v-11.2c0-0.4-0.1-0.6-0.2-0.7
			c-0.1-0.2-0.4-0.4-0.6-0.5c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.5-0.1-0.6-0.2v-0.6c1.4-0.7,2.6-1.1,3.4-1.7
			c0.7-0.5,1.3-0.7,1.4-0.7c0.2,0,0.4,0.2,0.4,0.6v3.6h0.1c0.2-0.5,0.6-0.8,0.8-1.3c0.4-0.5,0.7-0.8,1.1-1.3
			c0.5-0.5,0.8-0.7,1.4-1.1s1.1-0.5,1.7-0.5c0.6,0,1,0.2,1.6,0.6c0.6,0.4,0.7,0.8,0.7,1.7c0,0.6-0.2,1-0.6,1.4
			c-0.4,0.5-0.7,0.7-1.3,0.7c-0.4,0-0.6,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.5-0.1-0.7-0.1
			c-0.1,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.7,0.4-1,0.6c-0.4,0.2-0.6,0.7-0.8,1c-0.2,0.5-0.4,0.8-0.4,1.6v9.3c0,0.7,0.2,1.1,0.6,1.2
			c0.4,0.1,0.7,0.1,1.4,0.1h2.2v1c-1.1,0-2,0-3-0.1c-0.8,0-1.8,0-2.6,0s-1.7,0-2.4,0c-0.7,0-1.6,0-2.3,0.1L447.9,174L447.9,174z"
        />
        <path
          class="qt3"
          d="M56.1,203.1c0.5-0.4,0.8-0.7,1.3-1s1-0.7,1.4-0.8c0.5-0.2,1-0.6,1.6-0.7c0.6-0.2,1.1-0.2,1.7-0.2
			c1.1,0,2.2,0.2,3.1,0.7c0.8,0.5,1.7,1.1,2.3,1.9c0.7,0.7,1.1,1.7,1.4,2.6c0.4,1,0.6,2,0.6,3.2c0,1.3-0.2,2.5-0.7,3.8
			c-0.5,1.2-1.1,2.4-2,3.3c-0.8,1-1.9,1.8-3,2.4c-1.2,0.6-2.4,0.8-3.9,0.8c-0.7,0-1.4,0-2-0.1c-0.6-0.1-1.1-0.2-1.6-0.4
			c-0.5-0.1-0.8-0.4-1.2-0.5s-0.7-0.4-0.8-0.5l-1.2,0.8l-0.7-0.6c0.2-1.2,0.4-2.4,0.5-3.5c0.1-1.2,0.1-2.3,0.1-3.5v-17.2
			c0-0.7,0-1.1-0.1-1.6c-0.1-0.4-0.2-0.7-0.4-0.8c-0.2-0.2-0.5-0.4-0.7-0.5c-0.2-0.1-0.6-0.2-1-0.4v-0.7c0.7-0.2,1.6-0.6,2.3-0.8
			c0.7-0.4,1.3-0.6,1.8-0.8c0.2-0.1,0.5-0.2,0.6-0.4c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.4,0.2,0.4,0.6v15H56.1z M56.1,213.9
			c0,0.4,0.1,0.7,0.2,1.3c0.2,0.5,0.5,0.8,0.7,1.3c0.4,0.5,0.7,0.7,1.3,1c0.6,0.4,1.1,0.5,1.8,0.5c1.1,0,2-0.2,2.7-0.7
			c0.7-0.5,1.4-1.1,1.9-1.9s0.8-1.6,1.1-2.5c0.2-0.8,0.4-1.9,0.4-2.6c0-1.1-0.2-2-0.6-3.1c-0.4-1-0.8-1.8-1.4-2.5
			c-0.6-0.7-1.3-1.3-2-1.8s-1.6-0.7-2.3-0.7s-1.4,0.2-2,0.6c-0.7,0.5-1.2,0.8-1.8,1.3L56.1,213.9C56,213.9,56.1,213.9,56.1,213.9z"
        />
        <path
          class="qt3"
          d="M71.7,200.7c0.7,0,1.6,0,2.3,0.1c0.7,0,1.6,0,2.3,0c0.7,0,1.2,0,1.8,0s1.2,0,1.8,0v1c-0.2,0-0.5,0-0.7,0.1
			c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.5,0.2-0.6,0.4c-0.1,0.1-0.2,0.4-0.2,0.6c0,0.2,0.1,0.6,0.2,1.1c0.2,0.6,0.5,1.1,0.7,1.9
			c0.4,0.7,0.6,1.4,0.8,2.2c0.4,0.7,0.7,1.6,1,2.3c0.4,0.7,0.6,1.4,0.7,2c0.2,0.6,0.4,1,0.5,1.3h0.1c0.1-0.4,0.4-0.8,0.6-1.4
			c0.2-0.7,0.6-1.2,0.7-2c0.4-0.7,0.6-1.4,0.8-2c0.4-0.7,0.6-1.4,0.7-2c0.2-0.7,0.5-1.2,0.6-1.7c0.1-0.5,0.2-0.7,0.2-1
			c0-0.5-0.1-0.7-0.2-1c-0.1-0.2-0.4-0.5-0.6-0.6c-0.2-0.1-0.5-0.2-0.7-0.2c-0.2,0-0.6,0-0.7,0v-1c0.7,0,1.2,0,1.9,0.1
			c0.7,0,1.2,0,1.9,0c0.6,0,1,0,1.6,0c0.5,0,1,0,1.6-0.1v1c-0.8,0-1.7,0.2-2,0.7c-0.5,0.5-0.7,1.1-1.1,1.9l-9,21
			c-0.4,0.7-0.7,1.2-1.1,1.6s-0.8,0.5-1.6,0.5s-1.1-0.2-1.4-0.6c-0.4-0.4-0.6-0.8-0.6-1.3s0.2-0.8,0.6-1.3c0.4-0.5,0.7-0.7,1.3-0.7
			c0.4,0,0.7,0,0.8,0.1c0.2,0.1,0.5,0.2,0.7,0.6c1-2,1.9-4.2,2.7-6.3c-0.6-1.3-1-2.5-1.4-3.5c-0.5-1-0.8-2-1.3-3.3
			c-0.5-1.2-0.8-2.3-1.3-3.4s-0.7-2-1.1-2.7c0.1-1-0.1-1.4-0.1-1.7c-0.4-0.6-0.7-0.8-1-1.2c-0.4-0.4-0.8-0.4-1.6-0.4L71.7,200.7
			L71.7,200.7z"
        />
        <path
          class="qt3"
          d="M103.7,209.7c0-1.3,0.2-2.5,0.7-3.6c0.5-1.2,1.1-2.2,1.9-3.1c0.7-0.8,1.8-1.6,2.9-2c1.1-0.6,2.3-0.7,3.6-0.7
			c1.4,0,2.6,0.2,3.8,0.7s2.2,1.1,3.1,1.9c0.8,0.7,1.6,1.8,2,3s0.7,2.4,0.7,3.8c0,1.3-0.2,2.5-0.7,3.6c-0.5,1.2-1.1,2.2-2,3.1
			c-0.8,0.8-1.8,1.6-2.9,2c-1.1,0.6-2.3,0.7-3.6,0.7s-2.5-0.2-3.7-0.7c-1.2-0.5-2.2-1.1-3.1-1.9c-0.8-0.7-1.6-1.8-2-3
			C103.9,212.4,103.7,211.1,103.7,209.7z M107.3,209.3c0,0.8,0.1,1.8,0.4,2.7c0.2,1,0.6,2,1.1,2.9c0.5,0.8,1.1,1.7,1.9,2.2
			c0.7,0.6,1.7,0.8,2.6,0.8c1,0,1.9-0.2,2.5-0.7c0.7-0.6,1.3-1.2,1.8-2c0.5-0.7,0.7-1.7,1-2.6c0.3-0.9,0.4-1.9,0.4-2.6
			c0-0.7-0.1-1.8-0.4-2.7c-0.2-1-0.6-2-1.1-2.9c-0.5-0.8-1.1-1.7-1.9-2.2c-0.7-0.6-1.7-0.8-2.7-0.8s-1.9,0.2-2.5,0.7s-1.3,1.2-1.8,2
			c-0.5,0.7-0.7,1.7-1,2.6C107.4,207.6,107.3,208.5,107.3,209.3z"
        />
        <path
          class="qt3"
          d="M142.5,216.3c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.7,0.1c0.2,0,0.6,0,0.7,0c0.4,0,0.6,0,0.7,0v0.8
			c-0.5,0-0.8,0.1-1.6,0.2c-0.6,0.1-1.2,0.2-1.8,0.4c-0.6,0.1-1.1,0.2-1.6,0.4c-0.5,0.1-0.7,0.2-1,0.2l-0.2-0.1v-3.2
			c-1.2,1-2.3,1.8-3.4,2.4c-1.1,0.7-2.3,1-3.7,1c-0.6,0-1.1-0.1-1.7-0.4c-0.6-0.2-1-0.5-1.4-0.8c-0.5-0.4-0.7-0.8-1-1.4
			c-0.2-0.6-0.4-1.3-0.4-2.2v-10.2c0-0.2,0-0.6-0.1-0.7c0-0.2-0.1-0.5-0.4-0.7c-0.1-0.2-0.5-0.4-0.7-0.6c-0.4-0.1-0.7-0.2-1.3-0.4
			V201c0.4,0,0.7,0,1.3-0.1c0.6,0,1.1-0.1,1.7-0.1c0.6,0,1,0,1.4-0.1c0.5,0,0.7,0,0.7,0c0.2,0,0.4,0.1,0.4,0.2
			c0,0.1,0.1,0.5,0.1,0.8v10.3c0,0.4,0,0.7,0.1,1.2c0,0.6,0.2,1,0.4,1.6c0.2,0.6,0.6,1,1,1.3c0.5,0.4,1.1,0.6,2,0.6
			c0.7,0,1.4-0.2,2.3-0.7c0.8-0.5,1.7-1,2.5-1.8v-10.8c0-0.2-0.1-0.4-0.4-0.6c-0.2-0.1-0.5-0.4-0.7-0.5c-0.4-0.1-0.7-0.2-1.1-0.4
			c-0.5-0.1-0.7-0.2-1.1-0.4V201c1.8-0.1,3.3-0.2,4.3-0.4c1-0.2,1.7-0.2,1.9-0.2c0.1,0,0.2,0.1,0.4,0.2c0,0.1,0,0.4,0,0.6v14.9
			L142.5,216.3L142.5,216.3z"
        />
        <path
          class="qt3"
          d="M147.2,217.4h1.1c0.6,0,1,0,1.4-0.1c0.4-0.1,0.6-0.5,0.6-1.2v-11.2c0-0.4-0.1-0.6-0.2-0.7
			c-0.1-0.2-0.4-0.4-0.6-0.5c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.5-0.1-0.6-0.2v-0.6c1.4-0.7,2.6-1.1,3.4-1.7
			c0.7-0.5,1.3-0.7,1.4-0.7c0.2,0,0.4,0.2,0.4,0.6v3.6h0.1c0.2-0.5,0.6-0.8,0.8-1.3c0.4-0.5,0.7-0.8,1.1-1.3
			c0.5-0.5,0.8-0.7,1.4-1.1c0.6-0.4,1.1-0.5,1.7-0.5c0.6,0,1,0.2,1.6,0.6c0.6,0.4,0.7,0.8,0.7,1.7c0,0.6-0.2,1-0.6,1.4
			c-0.4,0.5-0.7,0.7-1.3,0.7c-0.4,0-0.6,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.5-0.1-0.7-0.1
			c-0.1,0-0.5,0.1-0.7,0.2c-0.4,0.1-0.7,0.4-1,0.6c-0.4,0.2-0.6,0.7-0.8,1c-0.2,0.5-0.4,0.8-0.4,1.6v9.3c0,0.7,0.2,1.1,0.6,1.2
			c0.4,0.1,0.7,0.1,1.4,0.1h2.2v1c-1.1,0-2,0-3-0.1c-0.8,0-1.8,0-2.6,0c-0.8,0-1.7,0-2.4,0c-0.7,0-1.6,0-2.3,0.1v-1.2L147.2,217.4
			L147.2,217.4z"
        />
        <path
          class="qt3"
          d="M189,203c0,0.5-0.1,0.8-0.5,1.2c-0.4,0.4-0.7,0.6-1.1,0.6c-0.5,0-0.7-0.2-1-0.6c-0.2-0.4-0.6-0.7-0.8-1.1
			c-0.4-0.5-0.7-0.7-1.3-1.1c-0.6-0.4-1.2-0.6-2.2-0.6c-0.8,0-1.7,0.2-2.2,0.7c-0.7,0.5-1.2,1-1.7,1.7c-0.5,0.7-0.7,1.4-1,2.2
			c-0.2,0.7-0.4,1.6-0.4,2.3c0,1,0.1,2,0.5,3c0.4,0.8,0.7,1.8,1.4,2.4c0.6,0.7,1.3,1.3,2.2,1.7c0.8,0.5,1.9,0.7,3,0.7
			c1,0,1.9-0.2,2.6-0.6c0.7-0.4,1.4-0.7,2.2-1.4l0.7,0.7c-1,1.1-2,2-3.4,3c-1.3,0.7-2.7,1.2-4.4,1.2c-1,0-2-0.2-3-0.7
			c-1-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.7-1.8-2.6c-0.5-1-0.7-2-0.7-3.1c0-1.4,0.2-2.7,0.7-4.1c0.5-1.3,1.2-2.4,2-3.4
			s1.9-1.8,3.1-2.3s2.5-0.8,3.9-0.8c0.6,0,1.1,0,1.7,0.1c0.7,0.1,1.2,0.2,1.8,0.5c0.6,0.2,1,0.5,1.4,0.8
			C188.8,201.9,189,202.4,189,203z"
        />
        <path
          class="qt3"
          d="M192.2,209.7c0-1.3,0.2-2.5,0.7-3.6c0.5-1.2,1.1-2.2,1.9-3.1c0.7-0.8,1.8-1.6,2.9-2c1.1-0.6,2.3-0.7,3.6-0.7
			c1.4,0,2.6,0.2,3.8,0.7c1.2,0.5,2.2,1.1,3.1,1.9c0.8,0.7,1.6,1.8,2,3c0.5,1.2,0.7,2.4,0.7,3.8c0,1.3-0.2,2.5-0.7,3.6
			c-0.5,1.2-1.1,2.2-2,3.1c-0.8,0.8-1.8,1.6-2.9,2c-1.1,0.6-2.3,0.7-3.6,0.7s-2.5-0.2-3.7-0.7s-2.2-1.1-3.1-1.9
			c-0.8-0.7-1.6-1.8-2-3C192.5,212.4,192.2,211.1,192.2,209.7z M195.9,209.3c0,0.8,0.1,1.8,0.4,2.7s0.6,2,1.1,2.9
			c0.5,0.8,1.1,1.7,1.9,2.2c0.7,0.6,1.7,0.8,2.6,0.8c1,0,1.9-0.2,2.5-0.7c0.7-0.6,1.3-1.2,1.8-2c0.5-0.7,0.7-1.7,1-2.6
			c0.3-0.9,0.4-1.9,0.4-2.6c0-0.7-0.1-1.8-0.4-2.7c-0.3-0.9-0.6-2-1.1-2.9c-0.5-0.8-1.1-1.7-1.9-2.2c-0.7-0.6-1.7-0.8-2.7-0.8
			s-1.9,0.2-2.5,0.7c-0.7,0.6-1.3,1.2-1.8,2c-0.5,0.7-0.7,1.7-1,2.6C196,207.6,195.9,208.5,195.9,209.3z"
        />
        <path
          class="qt3"
          d="M216.4,205.3c0-0.5,0-0.7-0.1-1.1s-0.2-0.6-0.4-0.7c-0.1-0.2-0.4-0.4-0.7-0.6c-0.2-0.1-0.6-0.4-1-0.6v-0.7
			c0.5-0.1,1-0.4,1.4-0.5c0.5-0.1,0.8-0.4,1.3-0.5s0.7-0.2,1-0.4c0.4-0.1,0.5-0.2,0.6-0.2c0.2-0.1,0.5-0.2,0.7-0.2
			c0.2,0,0.4,0.2,0.4,0.6v2.4c0.6-0.5,1.1-0.7,1.7-1.1c0.6-0.4,1.1-0.7,1.7-1c0.6-0.4,1.1-0.6,1.8-0.7c0.6-0.1,1.2-0.2,2-0.2
			c1.1,0,2,0.2,2.6,0.7c0.7,0.6,1.2,1.3,1.6,2.3c0.6-0.4,1.1-0.7,1.6-1.1c0.6-0.4,1.1-0.7,1.7-1c0.6-0.4,1.1-0.6,1.8-0.7
			c0.6-0.2,1.2-0.4,2-0.4c0.8,0,1.7,0.1,2.2,0.5c0.6,0.4,1.1,0.7,1.4,1.2c0.4,0.6,0.7,1.1,0.7,1.8c0.2,0.7,0.2,1.4,0.2,2v10.2
			c0,0.7,0.2,1.1,0.6,1.2c0.5,0.1,0.8,0.1,1.4,0.1h0.6v1c-0.7,0-1.3,0-2,0c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2.2,0
			c-0.7,0-1.4,0-2.2,0.1v-1h0.7c0.6,0,1,0,1.4-0.1c0.4-0.1,0.6-0.5,0.6-1.2V207c0-0.7,0-1.2-0.1-1.9c-0.1-0.6-0.2-1.1-0.6-1.6
			c-0.2-0.5-0.7-0.7-1.1-1.1c-0.5-0.4-1.1-0.5-1.8-0.5c-0.5,0-0.8,0.1-1.3,0.2c-0.5,0.1-0.8,0.4-1.3,0.6s-0.8,0.5-1.2,0.7
			c-0.4,0.2-0.7,0.5-0.8,0.6v12c0,0.7,0.2,1.1,0.6,1.2c0.5,0.1,0.8,0.1,1.4,0.1h0.7v1c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2.2,0
			c-0.7,0-1.4,0-2.2,0c-0.7,0-1.4,0-2.2,0.1v-1h0.7c0.6,0,1,0,1.4-0.1s0.6-0.5,0.6-1.2v-8.7c0-0.7,0-1.2-0.1-1.9
			c-0.1-0.6-0.2-1.1-0.6-1.6c-0.2-0.5-0.7-0.7-1.1-1.1s-1.1-0.5-1.8-0.5c-0.5,0-0.8,0.1-1.3,0.2c-0.5,0.1-0.8,0.4-1.3,0.6
			c-0.5,0.2-0.8,0.5-1.2,0.7c-0.4,0.2-0.7,0.5-0.8,0.6v11.6c0,0.7,0.2,1.1,0.6,1.2c0.5,0.1,0.8,0.1,1.4,0.1h0.7v1c-0.7,0-1.4,0-2,0
			c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2.2,0s-1.4,0-2.2,0.1v-1h0.8c0.6,0,1,0,1.4-0.1c0.4-0.1,0.6-0.5,0.6-1.2V205h0V205.3z"
        />
        <path
          class="qt3"
          d="M249.5,205.3c0-0.5,0-0.7-0.1-1.1s-0.2-0.6-0.4-0.7c-0.1-0.2-0.4-0.4-0.7-0.6c-0.2-0.1-0.6-0.4-1-0.6v-0.7
			c0.5-0.1,1-0.4,1.4-0.5c0.5-0.1,0.8-0.4,1.3-0.5s0.7-0.2,1-0.4c0.4-0.1,0.5-0.2,0.6-0.2c0.2-0.1,0.5-0.2,0.7-0.2
			c0.2,0,0.4,0.2,0.4,0.6v2.4c0.6-0.5,1.1-0.7,1.7-1.1c0.6-0.4,1.1-0.7,1.7-1c0.6-0.4,1.1-0.6,1.8-0.7c0.6-0.1,1.2-0.2,2-0.2
			c1.1,0,2,0.2,2.6,0.7c0.7,0.6,1.2,1.3,1.6,2.3c0.6-0.4,1.1-0.7,1.6-1.1c0.6-0.4,1.1-0.7,1.7-1c0.6-0.4,1.1-0.6,1.8-0.7
			c0.6-0.2,1.2-0.4,2-0.4c0.8,0,1.7,0.1,2.2,0.5c0.6,0.4,1.1,0.7,1.4,1.2c0.4,0.6,0.7,1.1,0.7,1.8c0.2,0.7,0.2,1.4,0.2,2v10.2
			c0,0.7,0.2,1.1,0.6,1.2c0.5,0.1,0.8,0.1,1.4,0.1h0.6v1c-0.7,0-1.3,0-2,0s-1.4,0-2,0c-0.7,0-1.4,0-2.2,0s-1.4,0-2.2,0.1v-1h0.7
			c0.6,0,1,0,1.4-0.1c0.4-0.1,0.6-0.5,0.6-1.2V207c0-0.7,0-1.2-0.1-1.9c-0.1-0.6-0.2-1.1-0.6-1.6c-0.2-0.5-0.7-0.7-1.1-1.1
			c-0.5-0.4-1.1-0.5-1.8-0.5c-0.5,0-0.8,0.1-1.3,0.2c-0.5,0.1-0.8,0.4-1.3,0.6c-0.5,0.2-0.8,0.5-1.2,0.7c-0.4,0.2-0.7,0.5-0.8,0.6
			v12c0,0.7,0.2,1.1,0.6,1.2c0.5,0.1,0.8,0.1,1.4,0.1h0.7v1c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2.2,0c-0.7,0-1.4,0-2.2,0
			c-0.7,0-1.4,0-2.2,0.1v-1h0.7c0.6,0,1,0,1.4-0.1c0.4-0.1,0.6-0.5,0.6-1.2v-8.7c0-0.7,0-1.2-0.1-1.9c-0.1-0.6-0.2-1.1-0.6-1.6
			c-0.2-0.5-0.7-0.7-1.1-1.1c-0.5-0.4-1.1-0.5-1.8-0.5c-0.5,0-0.8,0.1-1.3,0.2c-0.5,0.1-0.8,0.4-1.3,0.6c-0.5,0.2-0.8,0.5-1.2,0.7
			c-0.4,0.2-0.7,0.5-0.8,0.6v11.6c0,0.7,0.2,1.1,0.6,1.2c0.5,0.1,0.8,0.1,1.4,0.1h0.7v1c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2,0
			c-0.7,0-1.4,0-2.2,0c-0.7,0-1.4,0-2.2,0.1v-1h0.8c0.6,0,1,0,1.4-0.1c0.4-0.1,0.6-0.5,0.6-1.2V205h0V205.3z"
        />
        <path
          class="qt3"
          d="M297.3,216.3c0,0.4,0.1,0.6,0.4,0.7c0.2,0.1,0.5,0.1,0.7,0.1c0.2,0,0.6,0,0.7,0c0.4,0,0.6,0,0.7,0v0.8
			c-0.5,0-0.8,0.1-1.6,0.2c-0.6,0.1-1.2,0.2-1.8,0.4c-0.6,0.2-1.1,0.2-1.6,0.4c-0.5,0.2-0.7,0.2-1,0.2l-0.2-0.1v-3.2
			c-1.2,1-2.3,1.8-3.4,2.4c-1.1,0.7-2.3,1-3.7,1c-0.6,0-1.1-0.1-1.7-0.4c-0.6-0.3-1-0.5-1.4-0.8c-0.5-0.4-0.7-0.8-1-1.4
			c-0.2-0.6-0.4-1.3-0.4-2.2v-10.2c0-0.2,0-0.6-0.1-0.7c0-0.2-0.1-0.5-0.4-0.7c-0.1-0.2-0.5-0.4-0.7-0.6c-0.4-0.1-0.7-0.2-1.3-0.4
			V201c0.4,0,0.7,0,1.3-0.1c0.6,0,1.1-0.1,1.7-0.1s1,0,1.4-0.1c0.5,0,0.7,0,0.7,0c0.2,0,0.4,0.1,0.4,0.2c0,0.1,0.1,0.5,0.1,0.8v10.3
			c0,0.4,0,0.7,0.1,1.2c0,0.6,0.2,1,0.4,1.6c0.2,0.6,0.6,1,1,1.3c0.5,0.4,1.1,0.6,2,0.6c0.7,0,1.4-0.2,2.3-0.7
			c0.8-0.5,1.7-1,2.5-1.8v-10.8c0-0.2-0.1-0.4-0.4-0.6c-0.2-0.1-0.5-0.4-0.7-0.5c-0.4-0.1-0.7-0.2-1.1-0.4c-0.5-0.1-0.7-0.2-1.1-0.4
			V201c1.8-0.1,3.3-0.2,4.3-0.4c1-0.2,1.7-0.2,1.9-0.2c0.1,0,0.2,0.1,0.4,0.2c0,0.1,0,0.4,0,0.6v14.9L297.3,216.3L297.3,216.3z"
        />
        <path
          class="qt3"
          d="M304.7,205.5c0-0.4,0-0.7-0.1-1c-0.1-0.2-0.2-0.5-0.4-0.7c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.7-0.4-1.2-0.6
			v-0.7c0.8-0.4,1.7-0.6,2.5-0.8c0.8-0.4,1.8-0.7,2.7-1.1c0.1,0,0.2,0.1,0.2,0.5v2.6c1.1-0.7,2.2-1.6,3.3-2c1.1-0.6,2.3-0.8,3.6-0.8
			c0.8,0,1.6,0.1,2.2,0.5c0.6,0.4,1.1,0.7,1.4,1.2c0.4,0.6,0.7,1.1,0.7,1.8c0.1,0.7,0.2,1.4,0.2,2.2v10.3c0,0.7,0.2,1.1,0.6,1.2
			c0.5,0.1,0.8,0.1,1.4,0.1h0.7v1c-0.7,0-1.4,0-2,0s-1.4,0-2,0c-0.7,0-1.4,0-2.2,0s-1.4,0-2.2,0.1v-1h0.7c0.6,0,1,0,1.4-0.1
			c0.4-0.1,0.6-0.5,0.6-1.2v-10.3c0-1.1-0.4-2-1-2.5c-0.7-0.6-1.6-0.8-2.6-0.8c-0.4,0-0.7,0.1-1.1,0.2c-0.5,0.1-0.7,0.2-1.2,0.5
			c-0.5,0.2-0.8,0.5-1.2,0.7c-0.5,0.2-0.7,0.5-1.1,0.7v11.6c0,0.7,0.2,1.1,0.6,1.2c0.5,0.1,0.8,0.1,1.4,0.1h0.7v1c-0.7,0-1.4,0-2,0
			c-0.7,0-1.4,0-2,0c-0.7,0-1.4,0-2.2,0c-0.7,0-1.4,0-2.2,0.1v-1h0.7c0.6,0,1,0,1.4-0.1c0.4-0.1,0.6-0.5,0.6-1.2v-10.6h0.1V205.5z"
        />
        <path
          class="qt3"
          d="M324.4,217.4h0.7c0.6,0,1,0,1.4-0.1c0.4-0.1,0.6-0.5,0.6-1.2v-10.8c0-0.5,0-0.7-0.1-1.1s-0.2-0.6-0.4-0.7
			c-0.1-0.2-0.4-0.4-0.7-0.6c-0.2-0.1-0.6-0.4-1-0.6v-0.7c0.5-0.1,1-0.4,1.4-0.5c0.5-0.1,0.8-0.4,1.3-0.5c0.5-0.1,0.7-0.2,1-0.4
			c0.2-0.1,0.5-0.2,0.5-0.2c0.2-0.1,0.5-0.2,0.7-0.2c0.2,0,0.4,0.2,0.4,0.6V216c0,0.7,0.2,1.1,0.6,1.2c0.5,0.1,0.8,0.1,1.4,0.1h0.7
			v1c-0.7,0-1.4,0-2,0s-1.4,0-2,0c-0.7,0-1.4,0-2.2,0s-1.4,0-2.2,0.1L324.4,217.4L324.4,217.4z M330.6,192.1c0,0.6-0.2,1-0.6,1.4
			c-0.5,0.5-0.8,0.6-1.4,0.6c-0.6,0-1-0.2-1.4-0.6c-0.5-0.4-0.6-0.8-0.6-1.4c0-0.6,0.2-1,0.6-1.3c0.4-0.4,0.8-0.6,1.3-0.6
			c0.6,0,1,0.2,1.4,0.6C330.5,191.2,330.6,191.5,330.6,192.1z"
        />
        <path
          class="qt3"
          d="M340.8,213.5c0,0.7,0.1,1.2,0.2,1.7s0.4,0.7,0.7,1c0.2,0.2,0.6,0.5,0.7,0.6c0.4,0.1,0.6,0.1,0.8,0.1
			c0.6,0,1-0.1,1.4-0.4c0.4-0.3,0.8-0.5,1.3-0.8l0.6,0.6c-0.7,0.7-1.4,1.3-2.3,1.9c-0.8,0.6-1.9,0.7-3,0.7c-1.3,0-2.2-0.4-2.9-1
			c-0.7-0.7-1-1.6-1-2.7V203h-2.4v-0.7c0.6-0.5,1-0.7,1.4-1.1c0.4-0.4,0.8-0.7,1.3-1.1c0.5-0.5,0.8-0.8,1.2-1.4
			c0.5-0.6,0.8-1.2,1.4-2h0.5v4.1h6v2.2h-6v10.5L340.8,213.5L340.8,213.5z"
        />
        <path
          class="qt3"
          d="M348.2,200.7c0.7,0,1.6,0,2.3,0.1c0.7,0,1.6,0,2.3,0c0.7,0,1.2,0,1.8,0c0.6,0,1.2,0,1.8,0v1
			c-0.2,0-0.5,0-0.7,0.1c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.5,0.2-0.6,0.4c-0.1,0.1-0.2,0.4-0.2,0.6c0,0.2,0.1,0.6,0.2,1.1
			c0.2,0.6,0.5,1.1,0.7,1.9c0.4,0.7,0.6,1.4,0.8,2.2c0.4,0.7,0.7,1.6,1,2.3c0.4,0.7,0.6,1.4,0.7,2c0.2,0.6,0.4,1,0.5,1.3h0.1
			c0.1-0.4,0.4-0.8,0.6-1.4c0.2-0.7,0.6-1.2,0.7-2c0.4-0.7,0.6-1.4,0.8-2c0.4-0.7,0.6-1.4,0.7-2c0.2-0.7,0.5-1.2,0.6-1.7
			s0.2-0.7,0.2-1c0-0.5-0.1-0.7-0.2-1c-0.1-0.2-0.4-0.5-0.6-0.6s-0.5-0.2-0.7-0.2c-0.2,0-0.6,0-0.7,0v-1c0.7,0,1.2,0,1.9,0.1
			c0.7,0,1.2,0,1.9,0c0.6,0,1,0,1.6,0c0.5,0,1,0,1.6-0.1v1c-0.8,0-1.7,0.2-2,0.7c-0.5,0.5-0.7,1.1-1.1,1.9l-9,21
			c-0.4,0.7-0.7,1.2-1.1,1.6c-0.5,0.4-0.8,0.5-1.6,0.5c-0.7,0-1.1-0.2-1.4-0.6c-0.4-0.4-0.6-0.8-0.6-1.3s0.2-0.8,0.6-1.3
			c0.4-0.5,0.7-0.7,1.3-0.7c0.4,0,0.7,0,0.8,0.1c0.2,0.1,0.5,0.2,0.7,0.6c1-2,1.9-4.2,2.7-6.3c-0.6-1.3-1-2.5-1.4-3.5
			c-0.5-1-0.8-2-1.3-3.3c-0.5-1.2-0.8-2.3-1.3-3.4c-0.5-1.1-0.7-2-1.1-2.7c-0.4-0.7-0.6-1.2-0.6-1.4c-0.4-0.6-0.7-0.8-1-1.2
			c-0.4-0.4-0.8-0.4-1.6-0.4v-1.2L348.2,200.7L348.2,200.7z"
        />
      </g>
    </g>
    <g id="ZERO_3" class="withClass" v-bind:class="{ opac: !show }">
      <path
        d="M356.3,354.8c-0.9,0-1.9,0-2.9,0c-1,0-1.9,0-2.9,0c-1,0-1.9,0-2.8,0c-0.9,0-1.7,0-2.5,0c-1.8,0-3.7,0-5.5,0
		c-1.8,0-3.7,0-5.5,0l17.6-23h-11.3c-0.8,0-1.4,0.1-1.9,0.3c-0.5,0.2-0.9,0.5-1.2,0.9c-0.3,0.4-0.6,0.7-0.7,1.2
		c-0.2,0.4-0.3,0.9-0.4,1.3h-0.8c0.1-0.4,0.2-0.8,0.3-1.3c0.1-0.5,0.2-1,0.4-1.5c0.1-0.5,0.2-1,0.3-1.5c0.1-0.5,0.1-0.9,0.1-1.3h0.9
		c0,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.3,0.1,0.5,0.1h18.8l-17.5,23h10.2c1.2,0,2.3-0.1,3.1-0.2c0.8-0.2,1.5-0.4,2-0.8
		c0.5-0.4,1-0.8,1.3-1.5s0.7-1.4,1.1-2.3l0.8,0.2L356.3,354.8z"
      />
      <path
        d="M362.7,345.2c0,0.2-0.1,0.4-0.1,0.7c0,0.3,0,0.7,0.1,1.3c0,0.8,0.2,1.6,0.5,2.4s0.6,1.4,1.1,1.9s1,1,1.6,1.3
		c0.6,0.3,1.3,0.5,2,0.5c1,0,1.8-0.2,2.6-0.7c0.8-0.5,1.5-1,2.1-1.7l0.5,0.5c-0.4,0.5-0.8,1-1.3,1.5c-0.5,0.5-1,0.9-1.5,1.3
		c-0.5,0.4-1.1,0.6-1.7,0.9c-0.6,0.2-1.3,0.3-2,0.3c-1.4,0-2.5-0.3-3.4-0.9c-0.9-0.6-1.5-1.3-2-2.1c-0.5-0.8-0.8-1.7-1-2.5
		c-0.2-0.9-0.3-1.6-0.3-2.1c0-1.7,0.3-3.1,0.8-4.3c0.5-1.1,1.1-2.1,1.9-2.7c0.7-0.7,1.5-1.2,2.3-1.5c0.8-0.3,1.5-0.4,2.1-0.4
		c0.7,0,1.4,0.1,2.1,0.3c0.7,0.2,1.3,0.6,1.9,1s1,1,1.4,1.7c0.4,0.7,0.5,1.5,0.5,2.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3l-0.3,0.2
		H362.7z M368.9,344.1c0.4,0,0.7-0.1,0.8-0.3c0.1-0.2,0.2-0.4,0.2-0.6c0-0.9-0.3-1.6-0.8-2.3c-0.5-0.6-1.3-0.9-2.3-0.9
		c-1.1,0-2,0.4-2.7,1.1s-1.2,1.7-1.4,3H368.9z"
      />
      <path
        d="M375,353.9h0.9c0.5,0,0.9,0,1.2-0.1s0.5-0.4,0.5-1v-9.8c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.5-0.4
		c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.1-0.5-0.2v-0.5c1.3-0.5,2.3-1,3-1.4c0.7-0.4,1.1-0.6,1.3-0.6c0.2,0,0.3,0.2,0.3,0.5v3.1
		h0.1c0.2-0.4,0.5-0.7,0.8-1.1s0.6-0.8,1-1.2c0.4-0.4,0.8-0.7,1.2-0.9s1-0.4,1.5-0.4c0.4,0,0.9,0.2,1.3,0.5c0.4,0.3,0.7,0.8,0.7,1.4
		c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.4-0.7,0.5-1.2,0.5c-0.3,0-0.5,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3s-0.3-0.2-0.5-0.3
		c-0.2-0.1-0.4-0.1-0.7-0.1c-0.1,0-0.4,0.1-0.6,0.2c-0.3,0.1-0.6,0.3-0.9,0.5c-0.3,0.2-0.5,0.5-0.7,0.9c-0.2,0.4-0.3,0.8-0.3,1.3
		v8.2c0,0.6,0.2,0.9,0.5,1c0.3,0.1,0.7,0.1,1.2,0.1h2v0.9c-1,0-1.9,0-2.6-0.1c-0.8,0-1.5,0-2.3,0c-0.7,0-1.4,0-2.2,0
		c-0.7,0-1.4,0-2,0.1V353.9z"
      />
      <path
        d="M389.3,347.2c0-1.1,0.2-2.2,0.6-3.2c0.4-1,1-1.9,1.7-2.7c0.7-0.8,1.6-1.4,2.5-1.8c1-0.4,2.1-0.7,3.2-0.7
		c1.2,0,2.3,0.2,3.4,0.6c1,0.4,1.9,0.9,2.7,1.6c0.8,0.7,1.4,1.6,1.8,2.6c0.4,1,0.6,2.1,0.6,3.4c0,1.1-0.2,2.2-0.6,3.2
		s-1,1.9-1.7,2.7c-0.7,0.8-1.6,1.4-2.6,1.8s-2,0.7-3.1,0.7c-1.2,0-2.3-0.2-3.3-0.6c-1-0.4-1.9-0.9-2.7-1.6s-1.4-1.6-1.8-2.6
		S389.3,348.4,389.3,347.2z M392.5,346.8c0,0.7,0.1,1.5,0.3,2.4c0.2,0.9,0.5,1.7,0.9,2.5c0.4,0.8,1,1.4,1.6,1.9s1.5,0.8,2.4,0.8
		c0.9,0,1.6-0.2,2.2-0.7c0.6-0.5,1.1-1,1.5-1.7c0.4-0.7,0.7-1.5,0.9-2.3c0.2-0.8,0.3-1.6,0.3-2.4c0-0.7-0.1-1.5-0.3-2.4
		c-0.2-0.9-0.5-1.7-0.9-2.5s-1-1.4-1.6-1.9s-1.5-0.8-2.4-0.8c-0.9,0-1.6,0.2-2.2,0.7s-1.1,1-1.5,1.7c-0.4,0.7-0.7,1.5-0.8,2.3
		C392.6,345.3,392.5,346.1,392.5,346.8z"
      />
      <path
        d="M423.3,342c0.7-0.1,1.5-0.2,2.2-0.5c0.7-0.3,1.4-0.6,2-1.1c0.6-0.5,1-1,1.4-1.7c0.4-0.7,0.5-1.4,0.5-2.2
		c0-1.3-0.4-2.3-1.1-3.1c-0.7-0.8-1.7-1.2-3-1.2c-1,0-1.8,0.2-2.4,0.7c-0.7,0.5-1.3,1-2,1.7l-0.5-0.7c0.5-0.6,1-1.1,1.5-1.5
		c0.5-0.5,1-0.9,1.5-1.2s1.1-0.6,1.7-0.8c0.6-0.2,1.3-0.3,2.1-0.3c0.7,0,1.4,0.1,2,0.4s1.2,0.6,1.6,1c0.5,0.4,0.8,1,1.1,1.6
		c0.3,0.6,0.4,1.3,0.4,2c0,0.7-0.1,1.4-0.4,2c-0.3,0.6-0.7,1.2-1.1,1.8c-0.5,0.5-1,1-1.6,1.4s-1.3,0.7-1.9,1l0.1,0.1
		c0.5,0,1.1,0,1.8,0.1c0.7,0.1,1.4,0.4,2.1,0.8c0.7,0.4,1.3,1,1.7,1.8c0.5,0.8,0.7,1.7,0.7,3c0,0.8-0.2,1.8-0.5,2.7
		s-0.9,1.9-1.7,2.7c-0.8,0.8-1.7,1.5-2.9,2.1c-1.2,0.5-2.6,0.8-4.3,0.8c-0.8,0-1.6-0.1-2.2-0.3c-0.7-0.2-1.2-0.4-1.7-0.8
		s-0.8-0.6-1.1-1c-0.3-0.3-0.4-0.6-0.4-0.9c0-0.3,0.1-0.6,0.4-0.8c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.2,0.8,0.5
		c0.2,0.3,0.5,0.6,0.8,1c0.3,0.4,0.7,0.7,1.2,1c0.5,0.3,1.2,0.5,2.1,0.5c0.8,0,1.6-0.2,2.3-0.5c0.7-0.3,1.3-0.7,1.8-1.2
		c0.5-0.5,0.8-1.1,1.1-1.9c0.3-0.7,0.4-1.5,0.4-2.3c0-1.1-0.2-2-0.5-2.8c-0.4-0.7-0.9-1.3-1.5-1.7c-0.6-0.4-1.4-0.7-2.2-0.8
		c-0.9-0.1-1.8-0.2-2.8-0.1V342z"
      />
      <circle cx="385.2" cy="288.6" r="19.8" />
    </g>
    <g id="ZERO_4" class="withClass" v-bind:class="{ opac: !show }">
      <g>
        <path
          d="M256.2,582.4c-0.9,0-1.9,0-2.9,0s-1.9,0-2.9,0c-1,0-1.9,0-2.8,0c-0.9,0-1.7,0-2.5,0c-1.8,0-3.7,0-5.5,0s-3.7,0-5.5,0
			l17.6-23h-11.3c-0.8,0-1.4,0.1-1.9,0.3c-0.5,0.2-0.9,0.5-1.2,0.9c-0.3,0.4-0.6,0.7-0.7,1.2c-0.2,0.4-0.3,0.9-0.4,1.3h-0.8
			c0.1-0.4,0.2-0.8,0.3-1.3c0.1-0.5,0.2-1,0.4-1.5c0.1-0.5,0.2-1,0.3-1.5c0.1-0.5,0.1-0.9,0.1-1.3h0.9c0,0.2,0.2,0.3,0.4,0.4
			c0.2,0.1,0.3,0.1,0.5,0.1h18.8l-17.5,23h10.2c1.2,0,2.3-0.1,3.1-0.2c0.8-0.2,1.5-0.4,2-0.8c0.5-0.4,1-0.8,1.3-1.5
			c0.4-0.6,0.7-1.4,1.1-2.3l0.8,0.2L256.2,582.4z"
        />
        <path
          d="M262.6,572.7c0,0.2-0.1,0.4-0.1,0.7c0,0.3,0,0.7,0.1,1.3c0,0.8,0.2,1.6,0.5,2.4c0.3,0.7,0.6,1.4,1.1,1.9
			c0.5,0.5,1,1,1.6,1.3c0.6,0.3,1.3,0.5,2,0.5c1,0,1.8-0.2,2.6-0.7c0.8-0.5,1.5-1,2.1-1.7l0.5,0.5c-0.4,0.5-0.8,1-1.3,1.5
			c-0.5,0.5-1,0.9-1.5,1.3c-0.5,0.4-1.1,0.6-1.7,0.9c-0.6,0.2-1.3,0.3-2,0.3c-1.4,0-2.5-0.3-3.4-0.9c-0.9-0.6-1.5-1.3-2-2.1
			c-0.5-0.8-0.8-1.7-1-2.5c-0.2-0.9-0.3-1.6-0.3-2.1c0-1.7,0.3-3.1,0.8-4.3c0.5-1.1,1.1-2.1,1.9-2.7c0.7-0.7,1.5-1.2,2.3-1.5
			c0.8-0.3,1.5-0.4,2.1-0.4c0.7,0,1.4,0.1,2.1,0.3c0.7,0.2,1.3,0.6,1.9,1s1,1,1.4,1.7s0.5,1.5,0.5,2.4c0,0.1,0,0.2,0,0.3
			c0,0.1,0,0.2,0,0.3l-0.3,0.2H262.6z M268.8,571.6c0.4,0,0.7-0.1,0.8-0.3c0.1-0.2,0.2-0.4,0.2-0.6c0-0.9-0.3-1.6-0.8-2.3
			c-0.5-0.6-1.3-0.9-2.3-0.9c-1.1,0-2,0.4-2.7,1.1c-0.7,0.7-1.2,1.7-1.4,3H268.8z"
        />
        <path
          d="M274.9,581.5h0.9c0.5,0,0.9,0,1.2-0.1c0.3-0.1,0.5-0.4,0.5-1v-9.8c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.5-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.1-0.5-0.2v-0.5c1.3-0.5,2.3-1,3-1.4c0.7-0.4,1.1-0.6,1.3-0.6c0.2,0,0.3,0.2,0.3,0.5v3.1
			h0.1c0.2-0.3,0.5-0.7,0.8-1.1s0.6-0.8,1-1.2s0.8-0.7,1.2-0.9s1-0.4,1.5-0.4c0.4,0,0.9,0.2,1.3,0.5c0.4,0.3,0.7,0.8,0.7,1.4
			c0,0.5-0.2,0.9-0.5,1.2s-0.7,0.5-1.2,0.5c-0.3,0-0.5,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.4-0.1-0.7-0.1c-0.1,0-0.4,0.1-0.6,0.2c-0.3,0.1-0.6,0.3-0.9,0.5c-0.3,0.2-0.5,0.5-0.7,0.9s-0.3,0.8-0.3,1.3v8.2
			c0,0.6,0.2,0.9,0.5,1c0.3,0.1,0.7,0.1,1.2,0.1h2v0.9c-1,0-1.9,0-2.6-0.1s-1.5,0-2.3,0c-0.7,0-1.4,0-2.2,0c-0.7,0-1.4,0-2,0.1
			V581.5z"
        />
        <path
          d="M289.2,574.7c0-1.1,0.2-2.2,0.6-3.2c0.4-1,1-1.9,1.7-2.7c0.7-0.8,1.6-1.4,2.5-1.8s2.1-0.7,3.2-0.7c1.2,0,2.3,0.2,3.4,0.6
			s1.9,0.9,2.7,1.6c0.8,0.7,1.4,1.6,1.8,2.6s0.6,2.1,0.6,3.4c0,1.1-0.2,2.2-0.6,3.2c-0.4,1-1,1.9-1.7,2.7c-0.7,0.8-1.6,1.4-2.6,1.8
			c-1,0.4-2,0.7-3.1,0.7c-1.2,0-2.3-0.2-3.3-0.6c-1-0.4-1.9-0.9-2.7-1.6s-1.4-1.6-1.8-2.6C289.4,577.1,289.2,576,289.2,574.7z
			M292.4,574.4c0,0.7,0.1,1.5,0.3,2.4c0.2,0.9,0.5,1.7,0.9,2.5s1,1.4,1.6,1.9c0.7,0.5,1.5,0.8,2.4,0.8c0.9,0,1.6-0.2,2.2-0.7
			c0.6-0.5,1.1-1,1.5-1.7c0.4-0.7,0.7-1.5,0.9-2.3c0.2-0.8,0.3-1.6,0.3-2.4c0-0.7-0.1-1.5-0.3-2.4c-0.2-0.9-0.5-1.7-0.9-2.5
			c-0.4-0.8-1-1.4-1.6-1.9c-0.7-0.5-1.5-0.8-2.4-0.8c-0.9,0-1.6,0.2-2.2,0.7s-1.1,1-1.5,1.7c-0.4,0.7-0.7,1.5-0.8,2.3
			C292.5,572.8,292.4,573.6,292.4,574.4z"
        />
        <path
          d="M317.7,573.3l12-15.6h1.5v14.7h3.4v2.3h-3.4v7.7h-3v-7.7h-10.5V573.3z M328.2,561.9L328.2,561.9l-7.9,10.5h7.9V561.9z"
        />
        <circle cx="156.6" cy="571.8" r="19.8" />
      </g>
    </g>
    <g id="ZERO_2" class="withClass" v-bind:class="{ opac: !show }">
      <g>
        <path
          d="M808.9,443.8c-0.9,0-1.9,0-2.9,0c-1,0-1.9,0-2.9,0c-1,0-1.9,0-2.8,0c-0.9,0-1.7,0-2.5,0c-1.8,0-3.7,0-5.5,0
			c-1.8,0-3.7,0-5.5,0l17.6-23h-11.3c-0.8,0-1.4,0.1-1.9,0.3c-0.5,0.2-0.9,0.5-1.2,0.9c-0.3,0.4-0.6,0.7-0.7,1.2
			c-0.2,0.4-0.3,0.9-0.4,1.3h-0.8c0.1-0.4,0.2-0.8,0.3-1.3c0.1-0.5,0.2-1,0.3-1.5c0.1-0.5,0.2-1,0.3-1.5c0.1-0.5,0.1-0.9,0.1-1.3
			h0.9c0,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.3,0.1,0.5,0.1h18.8l-17.5,23h10.2c1.2,0,2.3-0.1,3.1-0.2c0.8-0.2,1.5-0.4,2-0.8
			c0.5-0.4,1-0.8,1.3-1.5c0.4-0.6,0.7-1.4,1.1-2.3l0.8,0.2L808.9,443.8z"
        />
        <path
          d="M815.3,434.1c0,0.2-0.1,0.4-0.1,0.7c0,0.3,0,0.7,0.1,1.3c0,0.8,0.2,1.6,0.5,2.4c0.3,0.7,0.6,1.4,1.1,1.9s1,1,1.6,1.3
			c0.6,0.3,1.3,0.5,2,0.5c1,0,1.8-0.2,2.6-0.7c0.8-0.5,1.5-1,2.1-1.7l0.5,0.5c-0.4,0.5-0.8,1-1.3,1.5c-0.5,0.5-1,0.9-1.5,1.3
			c-0.5,0.4-1.1,0.6-1.7,0.9c-0.6,0.2-1.3,0.3-2,0.3c-1.4,0-2.5-0.3-3.4-0.9s-1.5-1.3-2-2.1c-0.5-0.8-0.8-1.7-1-2.5
			c-0.2-0.9-0.3-1.6-0.3-2.1c0-1.7,0.3-3.1,0.8-4.3c0.5-1.1,1.1-2.1,1.9-2.7c0.7-0.7,1.5-1.2,2.3-1.5c0.8-0.3,1.5-0.4,2.1-0.4
			c0.7,0,1.4,0.1,2.1,0.3c0.7,0.2,1.3,0.6,1.9,1c0.6,0.5,1,1,1.4,1.7c0.4,0.7,0.5,1.5,0.5,2.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3
			l-0.3,0.2H815.3z M821.5,433c0.4,0,0.7-0.1,0.8-0.3c0.1-0.2,0.2-0.4,0.2-0.6c0-0.9-0.3-1.6-0.8-2.3c-0.5-0.6-1.3-0.9-2.3-0.9
			c-1.1,0-2,0.4-2.7,1.1s-1.2,1.7-1.4,3H821.5z"
        />
        <path
          d="M827.7,442.9h0.9c0.5,0,0.9,0,1.2-0.1c0.3-0.1,0.5-0.4,0.5-1v-9.8c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.5-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.1-0.5-0.2v-0.5c1.3-0.5,2.3-1,3-1.4c0.7-0.4,1.1-0.6,1.3-0.6c0.2,0,0.3,0.2,0.3,0.5v3.1
			h0.1c0.2-0.4,0.5-0.7,0.8-1.1c0.3-0.4,0.6-0.8,1-1.2c0.4-0.4,0.8-0.7,1.2-0.9s1-0.4,1.5-0.4c0.4,0,0.9,0.2,1.3,0.5
			c0.4,0.3,0.7,0.8,0.7,1.4c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.4-0.7,0.5-1.2,0.5c-0.3,0-0.5,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.1-0.7-0.1c-0.1,0-0.4,0.1-0.6,0.2c-0.3,0.1-0.6,0.3-0.9,0.5s-0.5,0.5-0.7,0.9
			c-0.2,0.4-0.3,0.8-0.3,1.3v8.2c0,0.6,0.2,0.9,0.5,1c0.3,0.1,0.7,0.1,1.2,0.1h2v0.9c-1,0-1.9,0-2.6-0.1c-0.8,0-1.5,0-2.3,0
			c-0.7,0-1.4,0-2.2,0c-0.7,0-1.4,0-2,0.1V442.9z"
        />
        <path
          d="M841.9,436.1c0-1.1,0.2-2.2,0.6-3.2c0.4-1,1-1.9,1.7-2.7c0.7-0.8,1.6-1.4,2.5-1.8c1-0.4,2.1-0.7,3.2-0.7
			c1.2,0,2.3,0.2,3.4,0.6c1,0.4,1.9,0.9,2.7,1.6c0.8,0.7,1.4,1.6,1.8,2.6c0.4,1,0.6,2.1,0.6,3.4c0,1.1-0.2,2.2-0.6,3.2
			s-1,1.9-1.7,2.7s-1.6,1.4-2.6,1.8c-1,0.4-2,0.7-3.2,0.7c-1.2,0-2.3-0.2-3.3-0.6c-1-0.4-1.9-0.9-2.7-1.6c-0.8-0.7-1.4-1.6-1.8-2.6
			S841.9,437.4,841.9,436.1z M845.2,435.8c0,0.7,0.1,1.5,0.3,2.4c0.2,0.9,0.5,1.7,0.9,2.5c0.4,0.8,1,1.4,1.6,1.9s1.5,0.8,2.4,0.8
			c0.9,0,1.6-0.2,2.2-0.7s1.1-1,1.5-1.7c0.4-0.7,0.7-1.5,0.9-2.3c0.2-0.8,0.3-1.6,0.3-2.4c0-0.7-0.1-1.5-0.3-2.4
			c-0.2-0.9-0.5-1.7-0.9-2.5s-1-1.4-1.6-1.9s-1.5-0.8-2.4-0.8c-0.9,0-1.6,0.2-2.2,0.7s-1.1,1-1.5,1.7c-0.4,0.7-0.7,1.5-0.8,2.3
			C845.2,434.3,845.2,435,845.2,435.8z"
        />
        <path
          d="M870.5,443.1c1.7-1.5,3.2-3,4.6-4.4s2.6-2.9,3.5-4.2c1-1.4,1.7-2.7,2.3-4c0.5-1.3,0.8-2.5,0.8-3.7c0-0.7-0.1-1.4-0.2-2
			c-0.2-0.7-0.4-1.2-0.8-1.7c-0.4-0.5-0.8-0.9-1.4-1.2c-0.6-0.3-1.2-0.5-2-0.5c-0.6,0-1.1,0.1-1.6,0.3s-0.9,0.5-1.3,0.8
			s-0.8,0.7-1.1,1.1c-0.3,0.4-0.7,0.8-1,1.2l-0.7-0.7c0.4-0.6,0.8-1.2,1.3-1.8c0.5-0.6,1-1.1,1.6-1.6c0.6-0.5,1.3-0.9,2-1.2
			c0.7-0.3,1.6-0.5,2.5-0.5s1.7,0.2,2.5,0.5c0.7,0.3,1.4,0.8,1.9,1.4c0.5,0.6,0.9,1.3,1.2,2c0.3,0.8,0.4,1.6,0.4,2.5
			c0,1.5-0.4,3-1.1,4.4c-0.7,1.4-1.7,2.8-2.7,4.1c-1.1,1.3-2.2,2.5-3.5,3.7c-1.2,1.2-2.3,2.3-3.3,3.2h11.4l0.8-1.8h1l-1,4.6h-15.9
			V443.1z"
        />
        <circle cx="839.5" cy="383.5" r="19.8" />
      </g>
    </g>
    <g id="ZERO_1"  class="withClass" v-bind:class="{ opac: !show }">
      <path
        class="qt3"
        d="M1044.7,463c-0.9,0-1.9,0-2.9,0c-1,0-1.9,0-2.9,0c-1,0-1.9,0-2.8,0c-0.9,0-1.7,0-2.5,0c-1.8,0-3.7,0-5.5,0
		c-1.8,0-3.7,0-5.5,0l17.6-23h-11.3c-0.8,0-1.4,0.1-1.9,0.3c-0.5,0.2-0.9,0.5-1.2,0.9c-0.3,0.4-0.6,0.7-0.7,1.2
		c-0.2,0.4-0.3,0.9-0.4,1.3h-0.8c0.1-0.4,0.2-0.8,0.3-1.3c0.1-0.5,0.2-1,0.4-1.5c0.1-0.5,0.2-1,0.3-1.5c0.1-0.5,0.1-0.9,0.1-1.3h0.9
		c0,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.3,0.1,0.5,0.1h18.8l-17.5,23h10.2c1.2,0,2.3-0.1,3.1-0.2c0.8-0.2,1.5-0.4,2-0.8
		c0.5-0.4,1-0.8,1.3-1.5c0.4-0.6,0.7-1.4,1.1-2.3l0.8,0.2L1044.7,463z"
      />
      <path
        class="qt3"
        d="M1051.1,453.4c0,0.2-0.1,0.4-0.1,0.7c0,0.3,0,0.7,0.1,1.3c0,0.8,0.2,1.6,0.5,2.4c0.3,0.7,0.6,1.4,1.1,1.9
		s1,1,1.6,1.3c0.6,0.3,1.3,0.5,2,0.5c1,0,1.8-0.2,2.6-0.7c0.8-0.5,1.5-1,2.1-1.7l0.5,0.5c-0.4,0.5-0.8,1-1.3,1.5
		c-0.5,0.5-1,0.9-1.5,1.3c-0.5,0.4-1.1,0.6-1.7,0.9c-0.6,0.2-1.3,0.3-2,0.3c-1.4,0-2.5-0.3-3.4-0.9s-1.5-1.3-2-2.1
		c-0.5-0.8-0.8-1.7-1-2.5c-0.2-0.9-0.3-1.6-0.3-2.1c0-1.7,0.2-3.1,0.8-4.3c0.5-1.1,1.1-2.1,1.9-2.7c0.7-0.7,1.5-1.2,2.3-1.5
		c0.8-0.3,1.5-0.4,2.1-0.4c0.7,0,1.4,0.1,2.1,0.3c0.7,0.2,1.3,0.6,1.9,1s1,1,1.4,1.7c0.4,0.7,0.5,1.5,0.5,2.4c0,0.1,0,0.2,0,0.3
		c0,0.1,0,0.2,0,0.3l-0.3,0.2H1051.1z M1057.3,452.3c0.4,0,0.7-0.1,0.8-0.3c0.1-0.2,0.2-0.4,0.2-0.6c0-0.9-0.3-1.6-0.8-2.3
		c-0.5-0.6-1.3-0.9-2.3-0.9c-1.1,0-2,0.4-2.7,1.1s-1.2,1.7-1.4,3H1057.3z"
      />
      <path
        class="qt3"
        d="M1063.4,462.1h0.9c0.5,0,0.9,0,1.2-0.1s0.5-0.4,0.5-1v-9.8c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.5-0.4
		s-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.1-0.5-0.2V449c1.3-0.5,2.3-1,3-1.4c0.7-0.4,1.1-0.6,1.3-0.6c0.2,0,0.3,0.2,0.3,0.5v3.1h0.1
		c0.2-0.4,0.5-0.7,0.8-1.1s0.6-0.8,1-1.2c0.4-0.4,0.8-0.7,1.2-0.9s1-0.4,1.5-0.4c0.4,0,0.9,0.2,1.3,0.5c0.4,0.3,0.7,0.8,0.7,1.4
		c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.4-0.7,0.5-1.2,0.5c-0.3,0-0.5,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.3
		c-0.2-0.1-0.4-0.1-0.7-0.1c-0.1,0-0.4,0.1-0.6,0.2c-0.3,0.1-0.6,0.3-0.9,0.5c-0.3,0.2-0.5,0.5-0.7,0.9c-0.2,0.4-0.3,0.8-0.3,1.3
		v8.2c0,0.6,0.2,0.9,0.5,1c0.3,0.1,0.7,0.1,1.2,0.1h2v0.9c-1,0-1.9,0-2.6-0.1c-0.8,0-1.5,0-2.3,0c-0.7,0-1.4,0-2.2,0
		c-0.7,0-1.4,0-2,0.1V462.1z"
      />
      <path
        class="qt3"
        d="M1077.7,455.4c0-1.1,0.2-2.2,0.6-3.2c0.4-1,1-1.9,1.7-2.7c0.7-0.8,1.6-1.4,2.5-1.8c1-0.4,2.1-0.7,3.2-0.7
		c1.2,0,2.3,0.2,3.4,0.6c1,0.4,1.9,0.9,2.7,1.6c0.8,0.7,1.4,1.6,1.8,2.6c0.4,1,0.6,2.1,0.6,3.4c0,1.1-0.2,2.2-0.6,3.2
		s-1,1.9-1.7,2.7c-0.7,0.8-1.6,1.4-2.6,1.8s-2,0.7-3.1,0.7c-1.2,0-2.3-0.2-3.3-0.6c-1-0.4-1.9-0.9-2.7-1.6s-1.4-1.6-1.8-2.6
		S1077.7,456.6,1077.7,455.4z M1080.9,455c0,0.7,0.1,1.5,0.3,2.4c0.2,0.9,0.5,1.7,0.9,2.5c0.4,0.8,1,1.4,1.6,1.9
		c0.7,0.5,1.5,0.8,2.4,0.8c0.9,0,1.6-0.2,2.2-0.7c0.6-0.5,1.1-1,1.5-1.7c0.4-0.7,0.7-1.5,0.9-2.3c0.2-0.8,0.3-1.6,0.3-2.4
		c0-0.7-0.1-1.5-0.3-2.4c-0.2-0.9-0.5-1.7-0.9-2.5s-1-1.4-1.6-1.9c-0.7-0.5-1.5-0.8-2.4-0.8c-0.9,0-1.6,0.2-2.2,0.7
		c-0.6,0.5-1.1,1-1.5,1.7c-0.4,0.7-0.7,1.5-0.8,2.3C1081,453.5,1080.9,454.3,1080.9,455z"
      />
      <path
        class="qt3"
        d="M1117.3,460.6c0,0.6,0.2,1,0.5,1.2c0.3,0.2,0.9,0.3,1.8,0.3h1.6v0.9c-0.9,0-1.8,0-2.7,0c-0.9,0-1.8,0-2.7,0
		c-1,0-1.9,0-2.9,0c-1,0-1.9,0-2.9,0.1v-0.9h1.9c0.8,0,1.4-0.1,1.8-0.3c0.3-0.2,0.5-0.6,0.5-1.2v-19.1c-0.8,0.5-1.6,0.9-2.5,1.4
		c-0.9,0.5-1.7,0.9-2.5,1.3l-0.5-0.8c1.5-0.7,2.9-1.5,4.3-2.4c1.4-0.9,2.7-1.7,4.1-2.6l0.4,0.1V460.6z"
      />
      <circle class="qt3" cx="1074" cy="404.3" r="19.8" />
    </g>

	<g
      id="hover"
      class="st0 cursor-pointer"
      @mouseover="show = true"
      @mouseout="show = false"
    >
      <g id="_x39_9_hover">
        <rect
          id="raised_hover_3_"
          x="665.4"
          y="149.8"
          class="st8"
          width="644.6"
          height="481.5"
          style="opacity: 0"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
};
</script>
<style type="text/css">
.st0 {
  opacity: 0.6;
}
.st1 {
  fill: #d60010;
}
.st2 {
  fill: none;
  stroke: #3e6eda;
  stroke-width: 4;
  stroke-miterlimit: 10;
}
.qt3 {
  fill: #3e6eda;
}

.withClass {
  opacity: 1;
  transition: 1s ease opacity;
}

.withClass.opac {
  opacity: 0;
}
</style>